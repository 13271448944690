import $axios from "../../utils/axios-instance.js";
import {getField, updateField} from "vuex-map-fields";
import {getSecureImage, isNotEmpty} from "../../common/helperFunctions";

export default {
  namespaced: true,
  state: {
    menuData: [],
    programmes: [],
    question: null,
    evidence: null,
    comments: null,
    findings: null,
    assessmentDetails: null,
    questionStatus: "",
    evidenceStatus: "",
    commentsStatus: "",
    comment: null,
    assessmentStatus: "",
    findingsStatus: "",
    readOnly: true,
    internalNote: null,
  },
  actions: {
    getQuestion({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setQuestionStatus", "loading");
        $axios
          .get(
            `/assessments/${payload.assessmentId}/questions/${payload.questionId}`,
          )
          .then((response) => {
            commit("setQuestionStatus", "success");
            commit("setQuestion", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setQuestionStatus", "error");
            reject(err);
          });
      });
    },
    getFindings({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setFindingsStatus", "loading");
        $axios
          .get(
            `/assessments/${payload.assessmentId}/questions/${payload.questionId}/findings`,
          )
          .then((response) => {
            commit("setFindingsStatus", "success");
            commit("setFindings", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingsStatus", "error");
            reject(err);
          });
      });
    },
    getQuestionEvidence({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setEvidenceStatus", "loading");
        $axios
          .get(
            `/assessments/${payload.assessmentId}/questions/${payload.questionId}/evidence`,
          )
          .then((response) => {
            commit("setEvidenceStatus", "success");
            commit("setEvidence", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setEvidenceStatus", "error");
            reject(err);
          });
      });
    },
    async getQuestionComments({commit}, payload) {
      commit("setCommentsStatus", "loading");
      try {
        let response = await $axios.get(
          `/assessments/${payload.assessmentId}/questions/${payload.questionId}/comments`,
        );
        let comments = [];
        for (let comment of response.data) {
          if (comment.pictureUrl) {
            comment = {
              ...comment,
              pictureUrl: await getSecureImage(comment.pictureUrl),
            };
          }
          comments.push(comment);
        }
        commit("setCommentsStatus", "success");
        commit("setComments", comments);
      } catch (err) {
        commit("setCommentsStatus", "error");
      }
    },
    async getAssessmentDetails({commit}, assessmentId) {
      commit("setAssessmentStatus", "loading");
      try {
        let response = await $axios.get(`/assessments/${assessmentId}`);
        if (
          response.data &&
          response.data.submitted &&
          response.data.submitted.submittedBy &&
          isNotEmpty(response.data.submitted.submittedBy.pictureUrl)
        ) {
          response.data.submitted.submittedBy.pictureUrl = await getSecureImage(
            response.data.submitted.submittedBy.pictureUrl,
          );
        }
        commit("setAssessmentStatus", "success");
        commit("setAssessmentDetails", response.data);

        commit(
          "setReadOnly",
          response.data.statusCode === "InReview" ||
            response.data.statusCode === "Complete" ||
            response.data.statusCode === "Cancelled",
        );
      } catch (err) {
        commit("setAssessmentStatus", "error");
      }
    },
    updateSideMenuAnswerValue({commit, state, getters}, payload) {
      const keyIndex = getters.getMenuKeyIndex(payload.questionId);
      const itemIndex = getters.getMenuItemIndex(payload.questionId, keyIndex);
      let menuData = state.menuData;

      if (typeof payload.value === "string") {
        menuData[keyIndex].items[itemIndex].answer.value = payload.value;
        commit("setMenuData", menuData);
      } else {
        let updatedFindingsCount =
          (getters && getters.findings && getters.findings.length
            ? getters.findings.length
            : 0) + payload.value;

        if (updatedFindingsCount > 1) {
          return;
        } else if (updatedFindingsCount === 1) {
          menuData[keyIndex].items[itemIndex].hasFindings = true;
        } else if (updatedFindingsCount === 0) {
          menuData[keyIndex].items[itemIndex].hasFindings = false;
        }
        commit("setMenuData", menuData);
      }

      // TODO: we also need to check for findings
    },
    postComment({dispatch}, payload) {
      return new Promise((resolve, reject) => {
        $axios
          .post(
            `/assessments/${payload.assessmentId}/questions/${payload.questionId}/comments`,
            {comment: payload.comment},
          )
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMenuData({commit}, assessmentId) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/assessments/${assessmentId}/categories?includeSections=true`)
          .then((response) => {
            commit("setMenuData", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setAssessmentStatus", "error");
            reject(err);
          });
      });
    },
    getProgrammes({commit}) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/assessments/programmes`)
          .then((response) => {
            commit("setProgrammes", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setAssessmentStatus", "error");
            reject(err);
          });
      });
    },
    submitAssessment({commit}, assessmentId) {
      return new Promise((resolve, reject) => {
        commit("setAssessmentStatus", "submitting");
        $axios
          .post(`/assessments/${assessmentId}/submit`)
          .then((response) => {
            commit("setAssessmentStatus", "submitted");
            commit("setReadOnly", true);
            resolve(response);
          })
          .catch((err) => {
            commit("setAssessmentStatus", "error");
            reject(err);
          });
      });
    },
    sendAssessmentForRework({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setAssessmentStatus", "submitting");
        $axios
          .post(`/assessments/${payload.assessmentId}/reject`, {
            reasonComment: payload.comment,
          })
          .then((response) => {
            commit("setAssessmentStatus", "sent for rework");
            resolve(response);
          })
          .catch((err) => {
            commit("setAssessmentStatus", "error");
            reject(err);
          });
      });
    },
    approveAssessment({commit}, assessmentId) {
      return new Promise((resolve, reject) => {
        commit("setAssessmentStatus", "submitting");
        $axios
          .post(`/assessments/${assessmentId}/approve`)
          .then((response) => {
            commit("setAssessmentStatus", "Completed");
            resolve(response);
          })
          .catch((err) => {
            commit("setAssessmentStatus", "error");
            reject(err);
          });
      });
    },
    deleteEvidence({commit}, evidenceId) {
      return new Promise((resolve, reject) => {
        $axios
          .delete(`/assessments/evidence/${evidenceId}`)
          .then((response) => {
            commit("removeDeletedEvidence", evidenceId);
            resolve(response);
          })
          .catch((err) => {
            commit("assessmentsError");
            reject(err);
          });
      });
    },
    deleteFinding({commit}, findingId) {
      return new Promise((resolve, reject) => {
        $axios
          .delete(`/assessments/findings/${findingId}`)
          .then((response) => {
            commit("removeDeletedFinding", findingId);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateComment({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setCommentStatus", "loading");
        $axios
          .put(`/assessments/comments/${payload.commentId}`, payload)
          .then((response) => {
            commit("setCommentStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setCommentStatus", "error");
            reject(err);
          });
      });
    },
    getComment({commit}, commentId) {
      return new Promise((resolve, reject) => {
        $axios
          .get(`/assessments/comments/${commentId}`)
          .then((response) => {
            commit("setCommentData", response.data);
            commit("setCommentStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setCommentStatus", "error");
            reject(err);
          });
      });
    },
    deleteComment({commit}, commentId) {
      return new Promise((resolve, reject) => {
        $axios
          .delete(`/assessments/comments/${commentId}`)
          .then((response) => {
            commit("removeDeletedComment", commentId);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateMenuData({commit}, menuData) {
      commit("setMenuData", menuData);
    },
    updateAssessmentDetails({commit}, assessmentDetails) {
      commit("setAssessmentDetails", assessmentDetails);
    },
    updateQuestion({commit}, question) {
      commit("setQuestion", question);
    },
    updateEvidence({commit}, evidence) {
      commit("setEvidence", evidence);
    },
    updateEvidenceStatus({commit}, evidenceStatus) {
      commit("setEvidenceStatus", evidenceStatus);
    },
    updateComments({commit}, comments) {
      commit("setComments", comments);
    },
    updateFindings({commit}, findings) {
      commit("setFindings", findings);
    },
    updateInternalNote({commit}, note) {
      commit("setInternalNote", note);
    },
    async getInternalNote({commit}, payload) {
      try {
        let response = await $axios.get(
          `/assessments/${payload.assessmentId}/questions/${payload.questionId}/note`,
        );
        if (response.data) commit("setInternalNote", response.data);
        else commit("setInternalNote", null);
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  mutations: {
    updateField,
    setQuestion(state, question) {
      state.question = question;
    },
    setAssessmentDetails(state, assessmentDetails) {
      state.assessmentDetails = assessmentDetails;
    },
    setMenuData(state, menuData) {
      state.menuData = menuData;
    },
    setProgrammes(state, programmes) {
      state.programmes = programmes;
    },
    setEvidenceStatus(state, evidenceStatus) {
      state.evidenceStatus = evidenceStatus;
    },
    setEvidence(state, evidence) {
      state.evidence = evidence;
    },
    setFindingsStatus(state, findingsStatus) {
      state.findingsStatus = findingsStatus;
    },
    setQuestionStatus(state, questionStatus) {
      state.questionStatus = questionStatus;
    },
    setCommentsStatus(state, commentsStatus) {
      state.commentsStatus = commentsStatus;
    },
    setCommentStatus(state, commentStatus) {
      state.commentStatus = commentStatus;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setCommentData(state, comment) {
      state.comment = comment;
    },
    setFindings(state, findings) {
      state.findings = findings;
    },
    addFinding(state, finding) {
      state.findings.push(finding);
    },
    setAssessmentStatus(state, assessmentStatus) {
      state.assessmentStatus = assessmentStatus;
    },
    setReadOnly(state, readOnly) {
      state.readOnly = readOnly;
    },
    removeDeletedEvidence(state, evidenceId) {
      state.evidence = state.evidence.filter((x) => x.id !== evidenceId);
    },
    removeDeletedFinding(state, findingId) {
      state.findings = state.findings.filter((x) => x.id !== findingId);
    },
    removeDeletedComment(state, commentId) {
      state.comments = state.comments.filter((x) => x.id !== commentId);
    },
    setInternalNote(state, internalNote) {
      state.internalNote = internalNote;
    },
  },
  getters: {
    getField,
    findings: (state) => state.findings,
    getMenuKeyIndex: (state) => (id) => {
      let keyIndex = null;
      if (state.menuData) {
        for (let i = 0; i < state.menuData.length; i++) {
          if (state.menuData[i].items.some((x) => x.questionId === id)) {
            keyIndex = i;
            break;
          }
        }
      }
      return keyIndex;
    },
    getMenuItemIndex: (state) => (id, keyIndex) => {
      let itemIndex = null;
      if (state.menuData) {
        for (let i = 0; i < state.menuData[keyIndex].items.length; i++) {
          if (state.menuData[keyIndex].items[i].questionId === id) {
            itemIndex = i;
            break;
          }
        }
      }
      return itemIndex;
    },
    isLoading: (state) => {
      return (
        state.assessmentStatus === "loading" ||
        state.questionStatus === "loading" ||
        state.evidenceStatus === "loading" ||
        state.commentsStatus === "loading" ||
        state.findingsStatus === "loading"
      );
    },
  },
};
