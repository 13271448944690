var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dx-data-grid',{attrs:{"allow-column-resizing":true,"allow-column-reordering":true,"data-source":_vm.dataSource,"show-borders":true,"columns":_vm.colDefs,"column-min-width":150,"focused-row-index":0,"focused-row-enabled":false,"columnResizingMode":"widget"},scopedSlots:_vm._u([{key:"status-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(_vm._s(_vm.formatStatusCode(data)))])]}},{key:"custom-percentage-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(_vm._s(data.value)+"%")])]}},{key:"audit-type-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(_vm._s(_vm.formatAuditType(data)))])]}},{key:"site-visit-type-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(_vm._s(_vm.formatSiteVisitType(data)))])]}},{key:"riskRating-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getRiskRating(data.data.assessmentRiskScore))+" ")])]}},{key:"isThereReviewerCapacity-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getIsThereReviewerCapacity(data.data.isThereReviewerCapacity))+" ")])]}},{key:"followupStatus-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getFollowupStatus(data.data.followupStatus))+" ")])]}},{key:"complianceRate-template",fn:function(ref){
var data = ref.data;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getRoundedComplianceRate(data.data))+" ")])]}}])},[_c('dx-remote-operations',{attrs:{"filtering":false,"paging":false,"sorting":false,"summary":false,"grouping":false,"group-paging":false}}),_c('dx-filter-row',{attrs:{"visible":true}}),_c('dx-paging',{attrs:{"page-size":10}}),_c('dx-pager',{attrs:{"show-page-size-selector":true,"show-info":true}}),_c('dx-column-chooser',{attrs:{"enabled":true,"allowSearch":true,"mode":"dragAndDrop"}}),_c('dx-grouping',{attrs:{"context-menu-enabled":true}}),_c('dx-group-panel',{attrs:{"visible":true}}),_c('dx-scrolling',{attrs:{"column-rendering-mode":"standard"}}),_c('dx-export',{attrs:{"enabled":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }