<template>
  <div>
    <page-heading :pageTitle="translatedPageTitle" :breadCrumbs="breadCrumbs" />
    <div class="pre-answered-response red" v-if="showReadOnlyBanner">
      <h3>
        <i class="far fa-times-circle"></i>
        {{ $t("schedules.scheduleAssessment.readOnlyMessageTitle") }}
      </h3>
      <p>
        {{ $t("schedules.scheduleAssessment.readOnlyMessage") }}
      </p>
    </div>
    <form @submit.prevent="onFormSubmit($event)" style="margin-bottom: 90px">
      <div class="row">
        <div class="col-xl-8 scheduling-section">
          <div class="card-container xs-adjustment">
            <div class="blurry-bg" :class="{closed: scheduleLoaderHidden}">
              <radial-progress-bar
                :progressValue="getCompletionPercentage()"
              ></radial-progress-bar>
              <div style="text-align: center">
                <p>
                  <i class="fas fa-check mr-2 progress-success"></i>
                  {{
                    `${
                      isNotEmpty(selectedAuditType) &&
                      selectedAuditType.val === "SelfAssessment"
                        ? $t("schedules.scheduleAssessment.selfAssessment")
                        : $t("schedules.scheduleAssessment.siteVisit")
                    } ` + $t("schedules.scheduleAssessment.selected")
                  }}
                </p>
                <p>
                  <i class="fas fa-check mr-2 progress-success"></i>
                  {{ $t("schedules.scheduleAssessment.retailer") }}
                  selected
                </p>
                <p>
                  <i class="fas fa-check mr-2 progress-working"></i>
                  {{ $t("schedules.scheduleAssessment.scheduling") }}
                  {{ successfulSchedules + failedSchedules }}
                  {{ $t("schedules.scheduleAssessment.of") }}
                  {{ orgsSelected }}
                </p>
                <div class="schedule-totals">
                  <div class="schedule successful">
                    <span>{{
                      $t("schedules.scheduleAssessment.successful")
                    }}</span>
                    <p>{{ successfulSchedules }}</p>
                  </div>
                  <div class="schedule failed">
                    <span>{{ $t("schedules.scheduleAssessment.failed") }}</span>
                    <p>{{ failedSchedules }}</p>
                  </div>
                </div>
                <hr v-if="errSummaryItems.length > 0" />
                <template v-for="item in errSummaryItems">
                  <div :key="item" class="errItem">{{ item.text }}</div>
                </template>

                <dx-button
                  :text="this.$t('assessments.assessment.close')"
                  name="previous"
                  class="btn btn-large mt-2"
                  style="min-width: 130px"
                  icon="close"
                  :visible="errSummaryCloseVisible"
                  type="normal"
                  @click="closeScheduleLoader"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-8">
                <div class="schedule-audit-details">
                  <h2 style="margin-bottom: 30px">
                    <i class="fas fa-map-marked-alt"></i>
                    {{ translatedPageTitle }}
                  </h2>
                  <label
                    class="table-status-label"
                    :class="setStatusClass(assessmentStatus)"
                    >{{ formatStatusCode(assessmentStatus) }}</label
                  >
                  <div
                    style="padding-left: 0; border: 0"
                    class="page-update-label red"
                    v-if="assessmentStatus === assessmentStatuses.cancelled"
                  >
                    {{
                      $t(
                        "schedules.scheduleAssessment.assessmentHasBeenCancelled",
                      )
                    }}
                  </div>

                  <div class="container-breaker">
                    <template v-if="$route.name === 'schedule-assessment'">
                      <audit-type-switch />
                    </template>
                    <template v-else>
                      <div class="content-block">
                        <label>
                          {{ $t("schedules.scheduleAssessment.auditType") }}
                        </label>
                        <div class="indented-label">
                          {{ selectedAuditType ? selectedAuditType.name : "" }}
                        </div>
                      </div>
                    </template>
                  </div>

                  <div class="container-breaker">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="content-block">
                          <label>
                            {{ $t("schedules.scheduleAssessment.auditPeriod") }}
                          </label>

                          <template
                            v-if="$route.name === 'schedule-assessment'"
                          >
                            <audit-year-select />
                          </template>
                          <template v-else>
                            <div class="indented-label">
                              {{ auditPeriod() }}
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <template
                          v-if="
                            selectedAuditType &&
                            selectedAuditType.val === 'AuditorVisit'
                          "
                        >
                          <div class="content-block">
                            <label>
                              {{
                                $t("schedules.scheduleAssessment.siteVisitType")
                              }}
                            </label>

                            <template
                              v-if="$route.name === 'schedule-assessment'"
                            >
                              <dx-select-box
                                v-model="selectedVisitType"
                                :data-source="siteVisitTypesDataSource"
                                displayExpr="name"
                                stylingMode="outlined"
                                :placeholder="
                                  $t(
                                    'schedules.scheduleAssessment.pickASiteVisitType',
                                  )
                                "
                                @value-changed="selectedVisitTypeChanged"
                              ></dx-select-box>
                            </template>
                            <template v-else>
                              <div class="indented-label">
                                {{
                                  selectedVisitType
                                    ? selectedVisitType.name
                                    : ""
                                }}
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="content-block mb-3" v-if="showFims">
                    <label>
                      {{ $t("schedules.scheduleAssessment.fimsLabel") }}
                    </label>

                    <div class="row">
                      <div class="col-xl-6">
                        <div class="checkbox-container">
                          <DxCheckBox
                            :text="
                              $t(
                                'schedules.scheduleAssessment.fimsCheckBoxText',
                              )
                            "
                            :disabled="areAssessmentProgrammesDisabled"
                            v-model="useFimsSelected"
                            @value-changed="onFimsChanged"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="assessmentProgrammes && !useFimsSelected">
                    <div
                      class="content-block level-down-container"
                      style="padding-top: 15px"
                    >
                      <label>
                        {{ $t("schedules.scheduleAssessment.programmeType") }}
                      </label>
                      <DxScrollView
                        class="programme-type-scrollview"
                        show-scrollbar="always"
                        :use-native="false"
                      >
                        <div class="row">
                          <template v-for="item in assessmentProgrammes">
                            <div class="col-md-6" :key="item">
                              <div class="checkbox-container">
                                <dx-check-box
                                  :name="item.name"
                                  :text="item.name"
                                  :disabled="areAssessmentProgrammesDisabled"
                                  :value="setAssessmentProgrammeValue(item)"
                                  @value-changed="
                                    onAssessmentProgrammeValueChanged(
                                      $event,
                                      item,
                                    )
                                  "
                                  :isValid="areAssessmentProgrammesValid"
                                />
                              </div>
                            </div>
                          </template>
                          <dx-validator
                            :adapter="assessmentProgrammesValidationAdapter"
                          >
                            <dx-custom-rule
                              :validation-callback="
                                validateAssessmentProgrammes
                              "
                              :reevaluate="true"
                            />
                          </dx-validator>
                        </div>
                      </DxScrollView>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="grey-side-section">
                  <div
                    class="blurry-bg"
                    :class="{
                      closed:
                        (selectedAssessmentProgrammes.length ||
                          useFimsSelected) &&
                        selectedAuditType,
                    }"
                  >
                    <div class="no-records-fallback">
                      <i class="fas fa-user-lock"></i>
                      <span class="darker">{{
                        $t("schedules.scheduleAssessment.fillOutForm")
                      }}</span>
                    </div>
                  </div>

                  <div class="sectioned-part">
                    <h2 style="margin-bottom: 30px">
                      <i class="far fa-building"></i>
                      {{ $t("schedules.scheduleAssessment.retailer") }}
                    </h2>
                    <third-party-select
                      :readOnly="$route.name !== 'schedule-assessment'"
                    />

                    <div v-if="selectedThirdParty">
                      <template v-if="$route.name === 'schedule-assessment'">
                        <retailer-select
                          :selectedAuditType="selectedAuditType"
                        />
                      </template>
                      <template v-else>
                        <div class="content-block level-down-container">
                          <label>
                            {{ $t("schedules.scheduleAssessment.retailer") }}
                          </label>
                          <div class="indented-label">
                            {{ scheduledVisit ? scheduledVisit.retailer : "" }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>

                  <div class="content-block" :ref="datesContainerRef">
                    <div class="sectioned-part">
                      <h2>
                        <i class="far fa-calendar-alt"></i>
                        {{ $t("schedules.scheduleAssessment.date") }}
                      </h2>
                      <div class="row">
                        <div class="col-xl-6 start-date">
                          <label>
                            {{ $t("schedules.scheduleAssessment.startDate") }}
                          </label>
                          <dx-date-box
                            name="startDate"
                            stylingMode="outlined"
                            :acceptCustomValue="false"
                            :openOnFieldClick="true"
                            type="date"
                            :ref="startDateDropDownRef"
                            validationMessageMode="auto"
                            pickerType="calendar"
                            v-model="startDate"
                            @value-changed="onStartDateValueChanged"
                            :min.sync="startDateMinimum"
                            @opened="onStartDateDropDownOpened"
                            :placeholder="
                              $t('schedules.scheduleAssessment.pickADate')
                            "
                            :disabled="
                              !(
                                assessmentStatus ===
                                  assessmentStatuses.notCreated ||
                                assessmentStatus ===
                                  assessmentStatuses.notStarted
                              ) || !canUpdate
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t(
                                    'schedules.scheduleAssessment.startDateIsRequired',
                                  )
                                "
                              />
                            </dx-validator>
                          </dx-date-box>
                        </div>
                        <div class="col-xl-6 end-date">
                          <label>{{
                            $t("schedules.scheduleAssessment.endDate")
                          }}</label>
                          <dx-date-box
                            name="endDate"
                            stylingMode="outlined"
                            :acceptCustomValue="false"
                            :openOnFieldClick="true"
                            type="date"
                            :ref="endDateDropDownRef"
                            validationMessageMode="auto"
                            pickerType="calendar"
                            v-model="endDate"
                            @value-changed="reloadAuditors"
                            @opened="onEndDateDropDownOpened"
                            :min.sync="startDate"
                            :max.sync="endDateMax"
                            :placeholder="
                              $t('schedules.scheduleAssessment.pickADate')
                            "
                            :disabled="
                              !(
                                assessmentStatus ===
                                  assessmentStatuses.notCreated ||
                                assessmentStatus ===
                                  assessmentStatuses.notStarted
                              ) || !canUpdate
                            "
                          >
                            <dx-validator>
                              <dx-required-rule
                                :message="
                                  $t(
                                    'schedules.scheduleAssessment.endDateIsRequired',
                                  )
                                "
                              />
                            </dx-validator>
                          </dx-date-box>
                        </div>
                      </div>
                    </div>

                    <div class="content-block" style="margin-bottom: 25px">
                      <label
                        ><i class="far fa-user-circle"></i>
                        {{ $t("schedules.scheduleAssessment.auditManager") }}
                      </label>

                      <dx-select-box
                        v-model="selectedAuditManager"
                        :data-source="auditManagerDataSource"
                        :disabled="
                          !canUpdate ||
                          assessmentStatus === assessmentStatuses.complete ||
                          assessmentStatus === assessmentStatuses.cancelled
                        "
                        stylingMode="outlined"
                        :display-expr="fullName"
                        :placeholder="
                          $t('schedules.scheduleAssessment.pickAnAuditManager')
                        "
                        @value-changed="reloadAuditors"
                      >
                        <dx-validator>
                          <dx-required-rule
                            :message="
                              $t(
                                'schedules.scheduleAssessment.auditManagerIsRequired',
                              )
                            "
                          />
                        </dx-validator>
                      </dx-select-box>
                    </div>

                    <template>
                      <div class="content-block">
                        <div class="row">
                          <div class="col-xl-12">
                            <label>
                              <i class="far fa-clock"></i>
                              {{
                                $t(
                                  "schedules.scheduleAssessment.delayNotification",
                                )
                              }}

                              <DxTooltip
                                :visible.sync="notificationTooltipVisible"
                                :close-on-outside-click="false"
                                target="#notificationIcon"
                                position="bottom"
                              >
                                {{
                                  $t(
                                    "schedules.scheduleAssessment.notificationToolTip",
                                  )
                                }}
                              </DxTooltip></label
                            >

                            <div
                              class="checkbox-container"
                              id="notificationIcon"
                              @mouseenter="toggleNotificationTooltip"
                              @mouseleave="toggleNotificationTooltip"
                            >
                              <DxCheckBox
                                :text="$t('schedules.scheduleAssessment.delay')"
                                :disabled="
                                  assessmentStatus ===
                                    assessmentStatuses.complete ||
                                  assessmentStatus ===
                                    assessmentStatuses.cancelled ||
                                  assessmentStatus ===
                                    assessmentStatuses.submitted ||
                                  assessmentStatus ===
                                    assessmentStatuses.inProgress ||
                                  startDateInThePast ||
                                  !canUpdate
                                "
                                v-model="sendNotificationSelected"
                              />
                            </div>
                          </div>
                          <div class="col-xl-12">
                            <transition name="fade" mode="out-in">
                              <div
                                class="level-down-container"
                                v-if="sendNotificationSelected"
                              >
                                <label>
                                  {{
                                    $t(
                                      "schedules.scheduleAssessment.notificationDate",
                                    )
                                  }}
                                </label>
                                <dx-date-box
                                  name="endDate"
                                  stylingMode="outlined"
                                  :acceptCustomValue="false"
                                  :openOnFieldClick="true"
                                  type="date"
                                  validationMessageMode="auto"
                                  pickerType="calendar"
                                  v-model="notificationDate"
                                  :max.sync="startDate"
                                  :min.sync="todaysDate"
                                  :placeholder="
                                    $t('schedules.scheduleAssessment.pickADate')
                                  "
                                  :disabled="
                                    !canUpdate ||
                                    assessmentStatus ===
                                      assessmentStatuses.complete ||
                                    assessmentStatus ===
                                      assessmentStatuses.cancelled ||
                                    assessmentStatus ===
                                      assessmentStatuses.submitted ||
                                    assessmentStatus ===
                                      assessmentStatuses.InProgress
                                  "
                                >
                                  <dx-validator>
                                    <dx-required-rule
                                      :message="
                                        $t(
                                          'schedules.scheduleAssessment.notificationDateIsRequired',
                                        )
                                      "
                                    />
                                  </dx-validator>
                                </dx-date-box>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 auditors-section">
          <transition name="fade">
            <div
              v-if="
                selectedAuditType &&
                selectedAuditType.val === 'AuditorVisit' &&
                startDate &&
                endDate
              "
            >
              <auditor-selection-card
                :retailerId="selectedSingleRetailerId"
                :readOnly="
                  assessmentStatus === assessmentStatuses.submitted ||
                  assessmentStatus === assessmentStatuses.cancelled ||
                  assessmentStatus === assessmentStatuses.complete ||
                  !canUpdate
                "
              />
            </div>
          </transition>
        </div>
      </div>

      <div
        class="fixed-bottom-container"
        v-if="
          !(
            assessmentStatus === assessmentStatuses.cancelled ||
            assessmentStatus === assessmentStatuses.complete
          ) && canUpdate
        "
      >
        <div class="row">
          <div class="col">
            <dx-button
              @click="cancelPopupVisible = !cancelPopupVisible"
              class="btn btn-large mr-4"
              icon="close"
              :text="$t('schedules.scheduleAssessment.cancelAudit')"
              :visible="
                $route.name !== 'schedule-assessment' &&
                assessmentStatus === assessmentStatuses.notStarted &&
                canUpdate
              "
              type="normal"
            />
          </div>
          <div class="col-auto">
            <dx-button
              :use-submit-behavior="true"
              class="btn btn-large"
              type="default"
              icon="check"
              :text="
                $route.name === 'schedule-assessment'
                  ? $t('schedules.scheduleAssessment.scheduleAudit')
                  : $t('schedules.scheduleAssessment.update')
              "
              :visible="canUpdate && submissionActive"
            />
          </div>
        </div>
      </div>
    </form>
    <DxPopup
      :visible.sync="popupVisible"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="false"
      :max-width="'100%'"
      :width="'700px'"
      :title="popupTitle"
      :height="'fit-content'"
    >
      <div class="d-flex flex-column h-100 overflow-y" style="height: inherit">
        <div class="popup-header m-adj">
          <i class="far fa-calendar-check"></i><br />
          {{ $t("schedules.scheduleAssessment.assessmentHasBeen") }}
          {{
            $route.name === "schedule-assessment"
              ? $t("schedules.scheduleAssessment.created")
              : $t("schedules.scheduleAssessment.updated")
          }}
        </div>

        <div
          class="scheduling-details"
          style="overflow: auto; height: calc(100vh - 290px)"
        >
          <div class="row">
            <div class="col-sm-12">
              <p>
                <b>{{ $t("schedules.scheduleAssessment.retailer") }}</b>
                <template v-if="$route.name === 'schedule-assessment'">
                  <span
                    v-if="
                      !(
                        selectedRetailerMultiple &&
                        selectedRetailerMultiple.length > 1
                      )
                    "
                    >{{ selectedRetailerName }}</span
                  >
                  <span v-else>{{
                    $t("schedules.scheduleAssessment.multipleRetailers")
                  }}</span>
                </template>
                <template v-else>
                  <span>{{
                    scheduledVisit ? scheduledVisit.retailer : ""
                  }}</span>
                </template>
              </p>
            </div>
            <div class="col-sm-12">
              <hr />
            </div>
            <div class="col-sm-4">
              <p v-if="selectedThirdParty">
                <b>{{ $t("schedules.scheduleAssessment.thirdParty") }}</b>
                <span>
                  {{ selectedThirdPartyName }}
                </span>
              </p>
            </div>
            <div class="col-sm-4">
              <p v-if="selectedAuditManager">
                <b>{{ $t("schedules.scheduleAssessment.auditManager") }}</b>
                <span>
                  {{
                    `${selectedAuditManager.firstName} ${selectedAuditManager.lastName}`
                  }}
                </span>
              </p>
            </div>
            <div class="col-sm-4">
              <p
                v-if="
                  selectedAuditor.length > 0 &&
                  selectedAuditType &&
                  selectedAuditType.val === 'AuditorVisit'
                "
              >
                <b>{{ $t("schedules.scheduleAssessment.auditor") }}</b>
                <span
                  v-if="
                    selectedAuditType &&
                    selectedAuditType.val === 'AuditorVisit'
                  "
                >
                  {{
                    `${selectedAuditor[0].firstName} ${selectedAuditor[0].lastName}`
                  }}
                </span>
              </p>
            </div>
            <div class="col-sm-12">
              <hr />
            </div>
            <div class="col-sm-4">
              <p>
                <b>{{ $t("schedules.scheduleAssessment.auditPeriod") }}</b>
                <span v-if="selectedAuditYear !== null">{{
                  auditPeriod()
                }}</span>
              </p>
            </div>
            <div class="col-sm-4">
              <p>
                <b>{{ $t("schedules.scheduleAssessment.programmeType") }}</b>
                <span>{{ selectedAssessmentProgrammes.toString() }}</span>
              </p>
            </div>
            <div class="col-sm-12">
              <hr />
            </div>
            <div class="col-sm-4">
              <p>
                <b>{{ $t("schedules.scheduleAssessment.startDate") }}</b>
                <span>{{ localeDatetimeFormat(startDate) }}</span>
              </p>
            </div>
            <div class="col-sm-4">
              <p>
                <b>{{ $t("schedules.scheduleAssessment.endDate") }}</b>
                <span>{{ localeDatetimeFormat(endDate) }}</span>
              </p>
            </div>
            <div class="col-sm-4">
              <p>
                <b>{{ $t("schedules.scheduleAssessment.notificationDate") }}</b>
                <span v-if="notificationDate === null">{{
                  $t("schedules.scheduleAssessment.notificationDateEmpty")
                }}</span>
                <span v-else>{{ localeDatetimeFormat(notificationDate) }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="row" style="">
          <div class="col-sm-4 offset-sm-2">
            <dx-button
              :use-submit-behavior="false"
              class="fw"
              :text="$t('schedules.scheduleAssessment.backToList')"
              type="normal"
              @click="backButtonClick"
            />
          </div>

          <div class="col-sm-4">
            <dx-button
              :use-submit-behavior="false"
              class="fw"
              type="default"
              :text="$t('schedules.scheduleAssessment.new')"
              @click="newButtonClick"
            />
          </div>
        </div>

        <div class="clear-fix"></div>
      </div>
    </DxPopup>
    <DxPopup
      :visible.sync="cancelPopupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      class="popUpLargeTitle"
      height="auto"
      width="auto"
      min-width="550px"
      :title="$t('schedules.scheduleAssessment.sureCancelAudit')"
    >
      <div class="container" style="padding: 0">
        <div class="row">
          <div class="col-md-12">
            <div class="content-block mt-0">
              <label>{{
                $t("schedules.scheduleAssessment.yourComments")
              }}</label>
              <DxTextArea
                :value.sync="cancellationComment"
                stylingMode="outlined"
                max-length="2000"
                height="120px"
                :placeholder="
                  $t('schedules.scheduleAssessment.enterCancellationReason')
                "
              ></DxTextArea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <dx-button
              :use-submit-behavior="false"
              style="margin-right: 15px"
              width="150px"
              :text="$t('schedules.scheduleAssessment.yes')"
              @click="cancelAssessment()"
              type="success"
            />

            <dx-button
              :use-submit-behavior="false"
              width="150px"
              :text="$t('schedules.scheduleAssessment.no')"
              type="primary"
              @click="closeConfirmationPopUp()"
            />
          </div>
        </div>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import AuditTypeSwitch from "../components/scheduling/audit-type-switch";
import RetailerSelect from "../components/scheduling/retailer-select";
import ThirdPartySelect from "../components/scheduling/third-party-select";
import AuditorSelectionCard from "../components/scheduling/auditor-selection-card";
import pageHeading from "../components/page-heading";
import {
  DxButton,
  DxDateBox,
  DxCheckBox,
  DxSelectBox,
  DxPopup,
  DxTooltip,
  DxTextArea,
} from "devextreme-vue";
import {
  DxValidator,
  DxRequiredRule,
  DxCustomRule,
} from "devextreme-vue/validator";
import {DxScrollView} from "devextreme-vue/scroll-view";
import {createHelpers} from "vuex-map-fields";
import {isNotEmpty, toCamelCase} from "../common/helperFunctions";
import {mapActions, mapState} from "vuex";
import AuditYearSelect from "../components/organisation/audit-year-select";
import notify from "devextreme/ui/notify";
import {assessmentStatuses} from "../custom/config/workflows";
import localeDatetimeFormat from "../mixins/locale-datetime-format";
import DataSource from "devextreme/data/data_source";
import {siteVisitTypes} from "../common/constants";
import RadialProgressBar from "../components/shared/radial-progress-bar";

const {mapFields} = createHelpers({
  getterType: "schedules/getField",
  mutationType: "schedules/updateField",
});

export default {
  components: {
    AuditTypeSwitch,
    RetailerSelect,
    ThirdPartySelect,
    AuditorSelectionCard,
    pageHeading,
    DxButton,
    DxDateBox,
    DxCheckBox,
    DxSelectBox,
    DxPopup,
    DxTooltip,
    DxValidator,
    DxRequiredRule,
    DxCustomRule,
    AuditYearSelect,
    DxTextArea,
    DxScrollView,
    RadialProgressBar,
  },
  mixins: [localeDatetimeFormat],
  created() {
    this.assessmentStatuses = assessmentStatuses;
    if (this.$route.name === "edit-assessment") {
      this.getScheduledVisit(this.$route.params.id).then(() => {
        this.pageTitle = `${this.scheduledVisit.retailer}`;
        this.selectedAuditType = this.auditTypes.find(
          (x) => x.val === this.scheduledVisit.scheduleType,
        );
        this.selectedVisitType = this.siteVisitTypesDataSource.find(
          (x) => x.value === this.scheduledVisit.visitSubType,
        );
        this.getAuditYears().then(() => {
          this.updateSelectedAuditYear(
            this.scheduledVisit.assessmentTemplateId,
          );
        });

        this.selectedRetailerSingle = [this.scheduledVisit.orgId];
        this.selectedThirdParty = this.scheduledVisit.thirdParty.id;
        this.startDateMinimum =
          new Date(this.scheduledVisit.startDate) > Date.now()
            ? Date.now()
            : new Date(this.scheduledVisit.startDate);
        this.startDate = new Date(this.scheduledVisit.startDate);
        this.endDate = new Date(this.scheduledVisit.endDate);

        if (isNotEmpty(this.scheduledVisit.notificationDate)) {
          this.sendNotificationSelected = true;
          this.notificationDate = new Date(
            this.scheduledVisit.notificationDate,
          );
        }
        this.getAssessmentProgrammes(this.scheduledVisit.visitSubType).then(
          () => {
            this.selectedAssessmentProgrammes = this.scheduledVisit.auditTypes;
          },
        );
        this.getAuditManagers(this.scheduledVisit.thirdParty.id);
        this.useFimsSelected = false;
        if (!this.canUpdate) {
          this.showReadOnlyBanner = true;
        }
      });
    } else {
      this.showFims = true;
      this.getAssessmentProgrammes("AuditVisit").then((response) => {
        this.selectedVisitType = this.siteVisitTypesDataSource.find(
          (x) => x.value === "AuditVisit",
        );
      });
      this.pageTitle = "Schedule audit";
    }
  },
  methods: {
    ...mapActions("schedules", [
      "getScheduledVisit",
      "getAssessmentProgrammes",
      "getAvailableAuditors",
      "getAuditManagers",
      "updateSelectedVisitType",
      "updateVisit",
      "resetSchedulesState",
    ]),
    ...mapActions("organisations", [
      "getAuditYears",
      "updateSelectedAuditYear",
    ]),
    toggleNotificationTooltip() {
      this.notificationTooltipVisible = !this.notificationTooltipVisible;
    },
    onStartDateValueChanged() {
      if (!isNotEmpty(this.endDate) || this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
      this.reloadAuditors();
    },
    getCompletionPercentage() {
      return Math.round(
        ((this.successfulSchedules + this.failedSchedules) /
          this.orgsSelected) *
          100,
      );
    },
    closeScheduleLoader() {
      this.scheduleLoaderHidden = true;
      this.submissionActive = true;
    },
    reloadAuditors() {
      if (!this.auditorsShouldBeReloaded) {
        return;
      }
      this.getAvailableAuditors({
        retailerId: this.selectedRetailerSingle[0],
        selectedAuditorId: this.shouldActualAuditorBeExcluded
          ? this.scheduledVisit.auditorId
          : 0,
        selectedAuditManagerId: this.selectedAuditManager
          ? this.selectedAuditManager.id
          : 0,
        startDate: this.startDate,
        endDate: this.endDate,
        thirdPartyId: this.selectedThirdParty ? this.selectedThirdParty : 0,
      }).then(() => {
        if (this.scheduledVisit && this.scheduledVisit.auditorId) {
          this.selectedAuditor = this.availableAuditors.filter(
            (x) => x.id === this.scheduledVisit.auditorId,
          );
        }
      });
    },
    onStartDateDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576 ? this.datesContainer.clientWidth : 340;
      this.startDateDropDown.option("dropDownOptions.width", dropDownWidth);
    },
    onEndDateDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576 ? this.datesContainer.clientWidth : 340;
      this.endDateDropDown.option("dropDownOptions.width", dropDownWidth);
    },
    formatStatusCode(statusCode) {
      return this.$t(`assessments.assessmentList.${toCamelCase(statusCode)}`);
    },
    setStatusClass(statusCode) {
      switch (statusCode) {
        case "NotStarted":
          return "red";
        case "Cancelled":
          return "red";
        case "Submitted":
          return "yellow";
        case "InProgress":
          return "yellow";
        case "Complete":
          return "green";
        default:
          return "";
      }
    },
    fullName(item) {
      if (item && item.firstName && item.lastName) {
        return `${item.firstName} ${item.lastName}`;
      }
      return null;
    },
    setAssessmentProgrammeValue(item) {
      if (this.scheduledVisit) {
        return this.scheduledVisit.auditTypes.includes(item.uniqueCode);
      }
      return this.assessmentProgrammes.length === 1 ? true : false;
    },
    auditPeriod() {
      if (isNotEmpty(this.selectedAuditYear)) {
        this.selectedAuditPeriod =
          this.$store.state.organisations.auditYears.filter(
            (x) => x.id === this.selectedAuditYear,
          );
        return `${this.selectedAuditPeriod[0].name}`;
      } else {
        return "";
      }
    },
    endDateMaximum() {
      if (this.selectedAuditYear) {
        return this.$store.state.organisations.auditYears.find(
          (x) => x.id === this.selectedAuditYear,
        ).periodEnd;
      }
    },
    backButtonClick() {
      this.$router.replace("/assessment-list");
    },
    selectedVisitTypeChanged(e) {
      this.selectedAssessmentProgrammes = [];
      this.getAssessmentProgrammes(e.value.value);
    },
    newButtonClick() {
      this.$router.push({name: "schedule-assessment"});
      this.$router.go();
    },
    closeConfirmationPopUp() {
      return (this.cancelPopupVisible = false);
    },
    cancelAssessment() {
      if (isNotEmpty(this.cancellationComment)) {
        this.cancelPopupVisible = false;
        this.$http
          .post(`/assessments/${this.$route.params.id}/cancel`, {
            reasonComment: this.cancellationComment,
          })
          .then((response) => {})
          .catch((err) => notify(`Failed to cancel visit: ${err}`, "error"));

        this.$router.replace("/assessment-list");
      } else {
        return;
      }
    },
    onFormSubmit() {
      let topOffset = document.getElementsByClassName("scheduling-section")[0]
        .offsetTop;
      document.querySelector(
        ".with-footer > .dx-scrollable-wrapper > .dx-scrollable-container",
      ).scrollTop = 0;

      this.submissionActive = false;
      let baseRequestBody = {
        startDate: this.$moment
          .utc(this.startDate)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss"),
        endDate: this.$moment
          .utc(this.endDate)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss"),
        notificationDate:
          this.notificationDate === null
            ? null
            : this.$moment
                .utc(this.notificationDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss"),
        auditorId:
          this.selectedAuditType.val === "AuditorVisit" &&
          this.selectedAuditor.length > 0
            ? this.selectedAuditor[0].id
            : undefined,
        auditManagerId: this.selectedAuditManager.id,
      };

      if (this.scheduledVisit) {
        this.updateVisit({
          visitId: this.scheduledVisit.id,
          requestBody: baseRequestBody,
        })
          .then((response) => {
            if (this.selectedRetailerSingle.length === 1) {
              this.$store.dispatch(
                "organisations/getOrgDetails",
                this.selectedRetailerSingle[0],
              );
            }
            if (this.selectedRetailerMultiple.length === 1) {
              this.$store.dispatch(
                "organisations/getOrgDetails",
                this.selectedRetailerMultiple[0],
              );
            }
            this.popupVisible = true;
          })
          .catch((err) => {
            notify(
              `${this.$t("schedules.scheduleAssessment.scheduleFail")} ${err}`,
              "error",
            );
            this.submissionActive = true;
          });
      } else {
        let orgs =
          this.selectedAuditType.val === "AuditorVisit"
            ? this.selectedRetailerSingle
            : this.selectedRetailerMultiple;

        this.multiSchedule(orgs, baseRequestBody);
      }
    },
    async multiSchedule(orgs, baseRequestBody) {
      this.successfulSchedules = 0;
      this.failedSchedules = 0;
      this.orgsSelected = orgs.length;
      this.scheduleLoaderHidden = false;
      this.errSummaryItems = [];
      this.exists = null;
      this.errSummaryCloseVisible = false;

      for (let org in orgs) {
        let response = await this.$http
          .post(`/scheduledvisit/createscheduledvisit`, {
            ...baseRequestBody,
            scheduleType: this.selectedAuditType.val,
            auditTypes: this.selectedAssessmentProgrammes,
            orgIds: [orgs[org]],
            assessmentTemplateId: this.selectedAuditYear,
            visitSubType:
              this.selectedAuditType &&
              this.selectedAuditType.val === "SelfAssessment"
                ? "SelfAssessment"
                : this.selectedVisitType.value,
            thirdPartyId: this.selectedThirdParty,
            flagUseOrgDetailsForProgType: this.useFimsSelected,
          })
          .then((respond) => {
            if (this.selectedRetailerSingle.length === 1) {
              this.$store.dispatch(
                "organisations/getOrgDetails",
                this.selectedRetailerSingle[0],
              );
            }
            if (this.selectedRetailerMultiple.length === 1) {
              this.$store.dispatch(
                "organisations/getOrgDetails",
                this.selectedRetailerMultiple[0],
              );
            }
            this.successfulSchedules++;
            if (this.successfulSchedules == orgs.length) {
              this.popupVisible = true;
              this.scheduleLoaderHidden = true;
            } else if (
              this.successfulSchedules + this.failedSchedules ==
              orgs.length
            ) {
              this.errSummaryCloseVisible = true;
            }
          })
          .catch((error) => {
            //this.errSummaryItems(error.response.data.message);
            this.addErrItem(error.response.data.message);
            notify(
              `${this.$t("schedules.scheduleAssessment.scheduleFail")} ${
                error.response.data.message
              }`,
              "error",
            );
            if (
              this.successfulSchedules + this.failedSchedules ==
              orgs.length
            ) {
              this.submissionActive = true;
            }
            this.failedSchedules++;
          });
      }
    },
    addErrItem(errItem) {
      this.checkIfExists(errItem);
      if (!this.exists) {
        this.errSummaryItems.push({text: errItem});
      }
    },
    checkIfExists(errItem) {
      this.exists = this.errSummaryItems.some((item) => {
        return item.text === errItem;
      });
    },
    onFimsChanged() {
      this.selectedAssessmentProgrammes = [];
    },
    onAssessmentProgrammeValueChanged(e, item) {
      // If the new value is set to true
      if (e.value) {
        if (!this.selectedAssessmentProgrammes.includes(item.uniqueCode)) {
          this.selectedAssessmentProgrammes.push(item.uniqueCode);
        }
      } else {
        this.selectedAssessmentProgrammes =
          this.selectedAssessmentProgrammes.filter(
            (x) => x !== item.uniqueCode,
          );
      }

      this.revalidateAssessmentProgrammes();
    },
    revalidateAssessmentProgrammes() {
      this.assessmentProgrammesValidationAdapter.validationRequestsCallbacks.forEach(
        (func) => {
          func();
        },
      );
    },
    validateAssessmentProgrammes() {
      return (
        this.selectedAssessmentProgrammes.length > 0 || this.useFimsSelected
      );
    },
    updateSelectedAuditManager() {
      this.selectedAuditManager = this.auditManagers.find(
        (x) => x.id === this.scheduledVisit.auditManagerId,
      );
    },
  },
  beforeDestroy() {
    this.resetSchedulesState();
  },
  data() {
    return {
      areAssessmentProgrammesValid: true,
      assessmentProgrammesValidationAdapter: {
        getValue: () => {
          return this.selectedAssessmentProgrammes;
        },
        applyValidationResults: (e) => {
          this.areAssessmentProgrammesValid = e.isValid;
        },
        bypass: () => {
          this.areAssessmentProgrammesDisabled;
        },
        validationRequestsCallbacks: [],
      },
      siteVisitTypesDataSource: Object.entries(siteVisitTypes).map(([key]) => ({
        name: this.$t(`schedules.scheduleAssessment.${toCamelCase(key)}`),
        value: key,
      })),
      selectedRetailers: [],
      popupVisible: false, // TODO: set this back to default (false)
      submissionActive: true,
      sendNotificationSelected: false,
      useFimsSelected: true,
      showFims: false,
      notificationTooltipVisible: false,
      pageTitle: "",
      cancelPopupVisible: false,
      cancellationComment: null,
      startDateMinimum: Date.now(),
      startDateDropDownRef: "startDate",
      endDateDropDownRef: "endDate",
      datesContainerRef: "datesContainer",
      successfulSchedules: 0,
      failedSchedules: 0,
      orgsSelected: 0,
      scheduleLoaderHidden: true,
      isNotEmpty: isNotEmpty,
      showReadOnlyBanner: false,
      errSummaryItems: [],
      errSummaryCloseVisible: false,
      exists: null,
    };
  },
  computed: {
    ...mapState("schedules", [
      "auditManagers",
      "selectedRetailerMultiple",
      "assessmentProgrammes",
      "auditTypes",
      "availableAuditors",
    ]),
    ...mapFields([
      "scheduledVisit",
      "selectedAuditor",
      "selectedAuditManager",
      "selectedRetailerSingle",
      "selectedVisitType",
      "selectedAuditType",
      "selectedAssessmentProgrammes",
      "selectedThirdParty",
      "startDate",
      "endDate",
      "notificationDate",
      "thirdParties",
    ]),
    auditManagerDataSource() {
      return new DataSource({
        store: {
          data: this.auditManagers,
          type: "array",
          key: "id",
        },
      });
    },
    datesContainer() {
      return this.$refs[this.datesContainerRef];
    },
    startDateDropDown() {
      return this.$refs[this.startDateDropDownRef].instance;
    },
    endDateDropDown() {
      return this.$refs[this.endDateDropDownRef].instance;
    },
    translatedPageTitle() {
      if (this.pageTitle && this.pageTitle === "Schedule audit") {
        return this.$t("schedules.scheduleAssessment.titleCreate");
      } else if (this.pageTitle) {
        return this.pageTitle;
      } else {
        return null;
      }
    },
    breadCrumbs() {
      return [
        this.$t("assessments.assessment.listTitle"),
        this.translatedPageTitle,
      ];
    },
    selectedAuditYear() {
      return this.$store.state.organisations.selectedAuditYear;
    },
    todaysDate() {
      return new Date().setHours(0, 0, 0, 0);
    },
    selectedRetailerName() {
      return this.$store.state.organisations.formData.name;
    },
    assessmentStatus() {
      if (this.scheduledVisit) {
        return this.scheduledVisit.assessmentStatus;
      } else {
        return "NotCreated";
      }
    },
    popupTitle() {
      return this.$route.name === "schedule-assessment"
        ? this.$t("schedules.scheduleAssessment.assessmentCreated")
        : this.$t("schedules.scheduleAssessment.assessmentUpdated");
    },
    selectedSingleRetailerId() {
      if (
        Array.isArray(this.selectedRetailerSingle) &&
        this.selectedRetailerSingle.length
      ) {
        return this.selectedRetailerSingle[0];
      }
      return 0;
    },
    endDateMax() {
      if (this.selectedAuditYear) {
        return this.$store.state.organisations.auditYears.find(
          (x) => x.id === this.selectedAuditYear,
        ).periodEnd;
      } else {
        return Date.now();
      }
    },
    auditorsShouldBeReloaded() {
      return (
        this.selectedRetailerSingle[0] ||
        this.startDate ||
        this.endDate ||
        (this.selectedAuditType &&
          this.selectedAuditType.val !== "SelfAssessment")
      );
    },
    areAssessmentProgrammesDisabled() {
      return (
        this.assessmentStatus !== assessmentStatuses.notCreated ||
        !this.canUpdate
      );
    },
    shouldActualAuditorBeExcluded() {
      return (
        this.scheduledVisit &&
        this.scheduledVisit.auditorId &&
        new Date(this.scheduledVisit.startDate).getTime() ==
          new Date(this.startDate).getTime() &&
        new Date(this.scheduledVisit.endDate).getTime() ==
          new Date(this.endDate).getTime()
      );
    },
    selectedThirdPartyName() {
      if (this.scheduledVisit && this.scheduledVisit.thirdParty) {
        return this.scheduledVisit.thirdParty.name;
      } else {
        const thirdParty = this.thirdParties.find(
          (x) => x.id == this.selectedThirdParty,
        );
        return isNotEmpty(thirdParty) ? thirdParty.name : "";
      }
    },
    canUpdate() {
      return this.$route.name === "schedule-assessment"
        ? true
        : isNotEmpty(this.scheduledVisit) && this.scheduledVisit.canEdit;
    },

    startDateInThePast() {
      return this.startDate
        ? false
        : this.todaysDate > new Date(this.startDate);
    },
  },
  watch: {
    auditManagers() {
      if (isNotEmpty(this.scheduledVisit)) {
        this.updateSelectedAuditManager();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../themes/generated/variables.base.scss";
.darker {
  color: $base-text-color !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.dx-popup-normal,
.dx-popup-content {
  overflow-x: auto !important;
  overflow-y: auto !important;
  height: auto !important;
  max-height: fit-content !important;
  min-height: 100% !important;
}

.scheduling-details {
  background: #f5f5f5;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px #d2d2d2;
  margin-bottom: 20px;
  border: 1px solid #eaeaea;
  overflow-y: auto;
  overflow-x: hidden;
  hr {
    border: 0;
    border-top: 1px solid #e2e2e2;
  }

  span {
    display: block;
    margin-top: 5px;
    color: black;
    text-decoration: underline;
  }
}
</style>
