<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />

    <div class="card-container">
      <div class="row">
        <div class="col-xl-5">
          <h2>
            <i class="far fa-flag"></i>
            {{ $t("findings.findingList.title") }}
          </h2>
          <div class="row">
            <div class="col-md-4 borderless-small-dd">
              <audit-year-select />
            </div>
            <div
              v-if="
                isInRole(roleTypes.admin.role) ||
                isInRole(roleTypes.reviewer.role)
              "
              class="col-md-6 borderless-small-dd"
            >
              <finding-filter />
            </div>
          </div>
        </div>
        <div class="col-xl-7">
          <ul class="custom-tabs">
            <li
              ref="allTab"
              @click="onTabChanged(event, 'All')"
              class="custom-tab-active"
            >
              {{ $t("findings.findingList.all") }}
            </li>
            <li @click="onTabChanged(event, 'Open')">
              {{ $t("findings.findingList.open") }}
            </li>
            <li @click="onTabChanged(event, 'Submitted')">
              {{ $t("findings.findingList.submitted") }}
            </li>
            <li @click="onTabChanged(event, 'Closed')">
              {{ $t("findings.findingList.closed") }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="content-block">
            <div
              class="pre-answered-response with-side-action mb-4"
              v-if="showPreFilteredBar"
            >
              <h3>
                <i class="fas fa-filter"></i>
                {{ $t("findings.findingList.preFilteredHeading") }}
              </h3>

              <div class="mt-2">
                {{ $t("findings.findingList.preFilteredText") }}
              </div>

              <div class="side-action-box" @click="clearGridFilters">
                <i class="fas fa-sync"></i>
                <p>{{ $t("findings.findingList.clearFilters") }}</p>
              </div>
            </div>
            <div v-if="selectedAuditYear && selectedFindingFilter">
              <DxDataGrid
                class="dx-card thin-column-chooser"
                :data-source="dataSource()"
                :show-borders="false"
                key-expr="id"
                :ref="dataGridRefName"
                :focused-row-index="0"
                :focused-row-enabled="false"
                :column-hiding-enabled="false"
                :selection="{mode: 'single'}"
                :hover-state-enabled="true"
                :columns="colDefs"
                @content-ready="contentReady"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-min-width="150"
                columnResizingMode="widget"
              >
                <DxHeaderFilter :visible="true" :allowSearch="true" />
                <DxColumnChooser
                  :enabled="true"
                  :allowSearch="true"
                  :height="500"
                  mode="dragAndDrop"
                />
                <DxColumnFixing :enabled="true" />
                <DxRemoteOperations
                  :filtering="false"
                  :paging="false"
                  :sorting="false"
                  :summary="false"
                  :grouping="false"
                  :group-paging="false"
                />
                <DxStateStoring
                  :enabled="true"
                  type="localStorage"
                  storage-key="storageFindingList"
                  :saving-timeout="savingTimeout"
                />

                <template #finding-template="{data}">
                  <div class="table-link flag" @click="onRowButtonClick(data)">
                    <i class="far fa-flag"></i>{{ data.data.title }}
                  </div>
                </template>

                <template #clear-filter-cell-template>
                  <div>
                    <div class="cell-button-header" @click="switchToAllTab">
                      <p>
                        <i class="fas fa-sync"></i>
                        <span>{{ $t("shared.clearFilters") }}</span>
                      </p>
                    </div>
                  </div>
                </template>

                <template #assessment-button-cell-template="{data}">
                  <div style="text-align: center">
                    <assessment-list-button
                      :text="$t('findings.findingList.view')"
                      @assessmentButtonClicked="onRowButtonClick(data)"
                    />
                  </div>
                </template>

                <template #status-cell-template="{data}">
                  <label
                    class="table-status-label"
                    :class="setStatusClass(data)"
                  >
                    {{ formatStatusCode(data) }}
                  </label>
                </template>

                <template #review-mode-template="{data}">
                  <label>
                    {{ formatReviewMode(data) }}
                  </label>
                </template>

                <template #audit-type-template="{data}">
                  <label>
                    {{ formatAuditType(data) }}
                  </label>
                </template>

                <template #businessOwners-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #reviewParticipants-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>

                <DxPaging :page-size="10" />
                <DxPager :show-page-size-selector="true" :show-info="true" />
                <DxFilterRow :visible="true" />
                <DxExport :enabled="true" />
              </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import $axios from "../utils/axios-instance";
import pageHeading from "../components/page-heading";
import roleTypes from "../config/roles";

import DxDataGrid, {
  DxFilterRow,
  DxPager,
  DxPaging,
  DxRemoteOperations,
  DxHeaderFilter,
  DxStateStoring,
  DxColumnChooser,
  DxColumnFixing,
  DxExport,
} from "devextreme-vue/data-grid";
import AssessmentListButton from "../components/assessment/assessment-list-button";
import {isNotEmpty, toCamelCase} from "../common/helperFunctions";
import listColDefs from "../custom/finding/findingListColDefs";
import AuditYearSelect from "../components/organisation/audit-year-select";
import FindingFilter from "../components/finding/finding-filter";

export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxRemoteOperations,
    AssessmentListButton,
    DxHeaderFilter,
    pageHeading,
    DxStateStoring,
    DxColumnChooser,
    DxColumnFixing,
    AuditYearSelect,
    DxExport,
    FindingFilter,
  },
  created() {
    this.getRole();
  },
  mounted() {},
  data() {
    return {
      dataGridRefName: "dataGrid",
      clearedFilter: false,
      roleTypes: roleTypes,
      savingTimeout: 0,
    };
  },
  computed: {
    pageTitle() {
      return this.$t("findings.findingList.title");
    },
    breadCrumbs() {
      return [this.$t("findings.findingList.subTitle")];
    },
    userFullName() {
      return this.user.firstName + " " + this.user.surname;
    },
    colDefs() {
      let colDefs = JSON.parse(JSON.stringify(this.colDefsTranslatedLookups));
      let chooserCols = colDefs
        .filter((x) => x.visible === false)
        .sort(function (a, b) {
          var nameA = a.dataField.toUpperCase();
          var nameB = b.dataField.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      let visibleCols = colDefs.filter((x) => x.visible === true);
      let sortedColDefs = visibleCols.concat(chooserCols);

      return sortedColDefs.map((x) =>
        x.caption
          ? {
              ...x,
              caption: this.$t(x.caption),
            }
          : x,
      );
    },
    showPreFilteredBar() {
      return this.$route.query.status && !this.clearedFilter;
    },
    colDefsTranslatedLookups() {
      let colDefs = JSON.parse(JSON.stringify(listColDefs));
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
    user() {
      return this.$store.state.auth.user;
    },
    selectedAuditYear() {
      return this.$store.state.organisations.selectedAuditYear;
    },
    selectedFindingFilter() {
      return this.$store.state.findings.findingFilter;
    },
  },
  methods: {
    dataSource() {
      return new CustomStore({
        key: "id",
        load: this.load,
      });
    },
    getUserInitials(text) {
      if (text && text.length > 0) {
        return text
          .split(/\s/)
          .map((x) => x.charAt(0))
          .join("")
          .substring(0, 2);
      }
      return null;
    },
    async load(loadOptions) {
      if (this.selectedAuditYear === null) {
        return null;
      }
      let params = "?";
      [
        "skip",
        "take",
        "sort",
        "requireTotalCount",
        "requireGroupCount",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);

      const response = await $axios.get(
        `/assessments/findings/period/${this.selectedAuditYear}/${this.selectedFindingFilter}${params}`,
      );

      const json = await JSON.parse(JSON.stringify(response));
      return {
        data: json.data.data,
        totalCount: json.data.totalCount,
        summary: json.data.summary,
        groupCount: json.data.groupCount,
      };
    },
    onRowButtonClick(data) {
      this.$router.push({
        name: "finding",
        params: {id: data.data.id},
      });
    },
    contentReady(e) {
      if (this.$route.query.status && !this.clearedFilter) {
        const dataGrid = this.$refs[this.dataGridRefName].instance;
        let startValue = new Date();
        startValue.setSeconds(0);
        startValue.setMilliseconds(0);
        dataGrid.filter(
          [
            ["leadReviewer", "=", this.userFullName],
            "or",
            ["supportReviewers", "Contains", this.userFullName],
          ],
          ["actionStatusCode", "=", this.$route.query.status],
        );
      }
    },
    clearGridFilters() {
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      this.clearedFilter = true;
      dataGrid.clearFilter();
    },
    formatStatusCode(data) {
      return this.$t(
        `findings.findingList.${toCamelCase(data.data.actionStatusCode)}`,
      );
    },
    formatAuditType(data) {
      return this.$t(
        `findings.findingList.${toCamelCase(data.data.auditType)}`,
      );
    },
    formatReviewMode(data) {
      return this.$t(
        `findings.findingList.${toCamelCase(data.data.reviewMode)}`,
      );
    },

    //TODO: this is rather quick fix for the tab, but need to look into making it more generic
    onTabChanged(e, value) {
      if (!e) e = window.event;
      var sender = e.srcElement || e.target;

      while (sender && sender.nodeName.toLowerCase() != "li")
        sender = sender.parentNode;

      document
        .getElementsByClassName("custom-tab-active")[0]
        .classList.remove("custom-tab-active");
      sender.className = "custom-tab-active";
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      if (value === "All") {
        dataGrid.clearFilter();
      } else {
        dataGrid.filter(["actionStatusCode", "=", value]);
      }
    },

    getRole() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("auth/getUser")
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setStatusClass(data) {
      var status = data.data.actionStatusCode;
      if (status == "Open") {
        return "scheduled";
      } else if (status == "Submitted") {
        return "yellow";
      } else if (status == "Closed") {
        return "green";
      } else {
        return "";
      }
    },
    getStringArrayCellData(data) {
      return data.data[data.column.dataField].map((x) => x).join(", ");
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    switchToAllTab() {
      this.$refs.allTab.click();
    },
  },
  watch: {
    selectedFindingFilter() {
      this.switchToAllTab();
    },
  },
};
</script>

<style lang="scss" scoped></style>
