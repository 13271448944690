<template>
  <dx-data-grid
    :allow-column-resizing="true"
    :allow-column-reordering="true"
    :data-source="dataSource"
    :show-borders="true"
    :columns="colDefs"
    :column-min-width="150"
    :focused-row-index="0"
    :focused-row-enabled="false"
    columnResizingMode="widget"
  >
    <dx-remote-operations
      :filtering="false"
      :paging="false"
      :sorting="false"
      :summary="false"
      :grouping="false"
      :group-paging="false"
    />
    <dx-column-chooser :enabled="true" :allowSearch="true" mode="dragAndDrop" />
    <dx-grouping :context-menu-enabled="true" />
    <dx-group-panel :visible="true" />
    <dx-scrolling column-rendering-mode="standard" />
    <dx-filter-row :visible="true" />
    <dx-paging :page-size="10" />
    <dx-pager :show-page-size-selector="true" :show-info="true" />

    <dx-export :enabled="true" />
    <template #status-template="{data}">
      <div>{{ formatStatusCode(data) }}</div>
    </template>
    <template #finding-status-template="{data}">
      <div>{{ formatFindingStatus(data) }}</div>
    </template>
    <template #custom-percentage-template="{data}">
      <div>{{ data.value }}%</div>
    </template>
    <template #retailer-audit-type-template="{data}">
      <div>{{ formatAuditType(data) }}</div>
    </template>
    <template #retailer-site-visit-type-template="{data}">
      <div>{{ formatSiteVisitType(data) }}</div>
    </template>
  </dx-data-grid>
</template>

<script>
import {mapState} from "vuex";
import columnDefinitions from "../../../custom/reporting/findingsAnalysisGridColDefs";
import {
  toCamelCase,
  firstLetterToUppercase,
} from "../../../common/helperFunctions";
import {
  DxDataGrid,
  DxScrolling,
  DxColumnChooser,
  DxGrouping,
  DxGroupPanel,
  DxExport,
  DxRemoteOperations,
  DxFilterRow,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {serializeLoadOptions} from "../../../utils/load-options-serializer";

export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxGrouping,
    DxGroupPanel,
    DxExport,
    DxRemoteOperations,
    DxFilterRow,
    DxPager,
    DxPaging,
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.load,
        }),
      }),
      loadOptions: null,
    };
  },
  methods: {
    async load(loadOptions) {
      this.loadOptions = loadOptions;
      try {
        let findingsAnalysis = await this.$http.post(
          "reporting/findingsAnalysis",
          this.filters,
          {
            params: serializeLoadOptions(loadOptions),
          },
        );

        return findingsAnalysis.data;
      } catch (error) {
        console.log("Error during finding analysis data fetch", error);
        return [];
      }
    },
    formatFindingStatus(data) {
      return this.$t(
        `findings.findingList.${toCamelCase(data.data.findingStatus)}`,
      );
    },
    formatStatusCode(data) {
      return this.$t(
        `assessments.assessmentList.${toCamelCase(data.data.assessmentStatus)}`,
      );
    },
    formatAuditType(data) {
      if (data.data.auditType === "SelfAssessment") {
        return this.$t("assessments.assessmentList.selfAssessment");
      }
      if (data.data.auditType === "AuditorVisit") {
        return this.$t("assessments.assessmentList.auditorVisit");
      }
      return "";
    },
    formatSiteVisitType(data) {
      if (data.data.siteVisitType === "AuditVisit") {
        return this.$t("landingPage.upcomingAssessment.visitTypeAuditVisit");
      }
      if (data.data.siteVisitType === "FieldVisit") {
        return this.$t("landingPage.upcomingAssessment.visitTypeFieldVisit");
      }
      return "";
    },
  },
  computed: {
    ...mapState("reportingFilters", ["filters"]),
    colDefs() {
      // TODO:
      // explicitly add the captions to the column definitions
      return this.colDefsTranslatedLookups.map((x) => ({
        ...x,
        caption: this.$t(
          `reporting.grids.findingsAnalysisCaption${firstLetterToUppercase(
            x.dataField,
          )}`,
        ),
      }));
    },
    colDefsTranslatedLookups() {
      let colDefs = JSON.parse(JSON.stringify(columnDefinitions));
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
  },
  watch: {
    filters() {
      this.load(this.loadOptions);
      this.dataSource.reload();
    },
  },
};
</script>

<style></style>
