import {AbilityBuilder} from "@casl/ability";
import roles from "./roles";
import subjects from "../config/subjects";
import {orderStatuses} from "../custom/config/workflows";

const {
  home,
  organisation,
  order,
  schedule,
  assessment,
  findingResolution,
  dashboard,
  userManagement,
  legal,
} = subjects;

export default function (user) {
  // Master role building switch.
  const {can, cannot, rules} = new AbilityBuilder();
  if (user === null || user === undefined) {
    can("read", home.subject);
    return rules;
  }

  switch (user.roles[0]) {
    // Admin
    case roles.admin.role:
      can("manage", "all");
      cannot("update", [order.subject], "updateOrder");
      break;

    // Orderer
    case roles.orderer.role:
      can("create", [
        organisation.subject,
        order.subject,
        userManagement.subject,
      ]);
      can("read", [
        home.subject,
        organisation.subject,
        order.subject,
        schedule.subject,
        assessment.subject,
        findingResolution.subject,
        userManagement.subject,
        dashboard.subject,
        legal.subject,
      ]);
      can("update", [
        organisation.subject,
        order.subject,
        userManagement.subject,
      ]);
      can("update", [schedule.subject], "contractFollowupStatus");
      cannot(
        "update",
        [schedule.subject],
        [
          "startDate",
          "isThereReviewerCapacity",
          "leadReviewer",
          "supportReviewers",
          "endDate",
          "estReportDueDate",
          "estCAPDate",
          "estDeadlineApprovalDate",
          "capStatus",
          "updateReview",
          "cancelReview",
          "abilityToComplyOptionsList",
        ],
      );
      cannot("update", [organisation.subject], "activeStatus");
      cannot("update", [order.subject], "delayNotification");
      break;

    // Reviewer
    case roles.reviewer.role:
      can("create", [
        assessment.subject,
        schedule.subject,
        userManagement.subject,
        organisation.subject,
      ]);
      can("read", [
        home.subject,
        organisation.subject,
        order.subject,
        schedule.subject,
        assessment.subject,
        findingResolution.subject,
        userManagement.subject,
        dashboard.subject,
        legal.subject,
      ]);
      can("update", [
        organisation.subject,
        schedule.subject,
        order.subject,
        userManagement.subject,
      ]);
      cannot("update", order.subject, [
        "orderer",
        "fiscalYear",
        "reviewMode",
        "updateOrder",
      ]);
      can("update", assessment.subject, {
        assessmentType: "AuditorVisit",
        reviewers: {
          $elemMatch: {$eq: user.userName},
        },
      });
      can("update", assessment.subject, "Submit", {
        assessmentType: "AuditorVisit",
        leadReviewer: user.userName,
      });
      can("update", findingResolution.subject, [
        "Approve",
        "CapDate",
        "DeadlineForApproval",
      ]);
      cannot("update", [schedule.subject], "contractFollowupStatus");
      cannot("update", [organisation.subject], "activeStatus");
      cannot("update", [order.subject], "cancelOrder");
      break;

    case roles.reviewParticipant.role:
      can("create", [userManagement.subject]);
      can("read", [
        home.subject,
        userManagement.subject,
        organisation.subject,
        order.subject,
        schedule.subject,
        assessment.subject,
        findingResolution.subject,
        dashboard.subject,
        legal.subject,
      ]);
      can("update", [userManagement.subject]);
      can("update", findingResolution.subject, [
        "RootCause",
        "ResolutionDetail",
        "ResponsiblePerson",
        "ReviewParticipantTargetDate",
      ]);
      break;

    case roles.businessOwner.role:
      can("create", [userManagement.subject]);
      can("read", [
        home.subject,
        userManagement.subject,
        order.subject,
        schedule.subject,
        assessment.subject,
        organisation.subject,
        findingResolution.subject,
        legal.subject,
      ]);
      can("update", [userManagement.subject, organisation.subject]);
      cannot("update", [organisation.subject], "activeStatus");
      break;

    // organisation
    case roles.organisation.role:
      can("read", [home.subject, findingResolution.subject]);
      can("read", assessment.subject, {assessmentType: "SelfAssessment"});
      can("update", findingResolution.subject);
      can("update", assessment.subject, {assessmentType: "SelfAssessment"});
      cannot("update", findingResolution.subject, "Approve");
      break;
    default:
      can("read", home.subject);
  }
  return rules;
}
