<template>
  <div style="margin-bottom: 85px">
    <div v-if="findingDetails">
      <div class="with-side-indent">
        <page-heading
          :pageTitle="findingDetails.title"
          :breadCrumbs="breadCrumbs"
        />
      </div>

      <div class="tabbed-details-menu small-no-margin">
        <!-- <div class="last-updated-label">
          {{ `${$t("assessments.assessment.lastUpdatedBy")} Jack Smith` }}
        </div> -->
        <div class="assessment-subnav-tabs">
          <div
            :class="{'tab-element-selected': isFindingSectionVisible}"
            @click="showFindingSection()"
          >
            {{ $t("findings.findingsResolution.details") }}
          </div>

          <div
            :class="{'tab-element-selected': isAuditLogsSectionVisible}"
            @click="showAuditLogTable()"
          >
            <i class="fas fa-history" />
          </div>
        </div>
      </div>

      <div v-if="isAuditLogsSectionVisible">
        <audit-log
          :elementId="this.$route.params.id"
          dataPath="audit/findings/"
        >
        </audit-log>
      </div>

      <div v-show="isFindingSectionVisible">
        <form id="form" ref="form" @submit.prevent="onFormSubmit($event)">
          <div class="row">
            <div class="col-xl-12">
              <div v-if="findingDetails.latestCommentOfTypeReason.comment">
                <div class="page-indicator warning">
                  <div class="indicator-icon">
                    <i class="fas fa-undo"></i>
                  </div>
                  <h3>
                    {{ $t("assessments.assessment.reworkCommentsBy") }}
                    {{ findingDetails.latestCommentOfTypeReason.fullname }}
                  </h3>
                  <p>{{ findingDetails.latestCommentOfTypeReason.comment }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 hw-below-fhd">
              <div class="card-container finding-details">
                <h2>
                  <i class="far fa-flag"></i>
                  {{ $t("findings.findingsResolution.details") }}
                </h2>

                <label
                  class="table-status-label"
                  :class="setStatusClass(findingDetails.statusCode)"
                  >{{ formatStatusCode(findingDetails.statusCode) }}</label
                >

                <p class="main-context-title">
                  <span class="form-response-text">{{
                    findingDetails.title
                  }}</span>
                </p>
                <div class="row">
                  <div class="col-sm-6 element-margin">
                    <label class="bold-label">{{
                      $t("findings.findingsResolution.supplierName")
                    }}</label>
                    <span class="form-response-text">
                      {{ findingDetails.supplierName }}
                    </span>
                  </div>
                  <div class="col-sm-6 element-margin">
                    <label class="bold-label">{{
                      $t("findings.findingsResolution.siteName")
                    }}</label>
                    <span class="form-response-text">
                      {{ findingDetails.siteName }}
                    </span>
                  </div>
                </div>

                <div class="element-margin">
                  <label style="margin-bottom: 7px" class="bold-label">{{
                    $t("findings.findingsResolution.requirement")
                  }}</label>

                  <router-link
                    style="margin-bottom: 30px"
                    :to="{
                      name: 'assessment',
                      params: {
                        id: findingDetails.assessmentId,
                        questionId: findingDetails.question.id,
                      },
                    }"
                  >
                    {{
                      `${
                        findingDetails.question.requirementNumber
                          ? `${findingDetails.question.requirementNumber} - `
                          : ""
                      }`
                    }}
                    {{ findingDetails.question.title }}
                    {{
                      `${
                        findingDetails.question.sectionTitle
                          ? `(${findingDetails.question.sectionTitle})`
                          : ""
                      }`
                    }}
                  </router-link>
                </div>
                <div class="row">
                  <div class="element-margin" :class="classes">
                    <label class="bold-label">{{
                      $t("findings.findingsResolution.dueDate")
                    }}</label>
                    <span class="form-response-text">
                      {{ localeDatetimeFormat(findingDetails.dueDate) }}
                    </span>
                  </div>
                  <!-- <div class="col-sm-4 col-xl-3 element-margin">
                    <label>{{ $t("findings.findingsResolution.brand") }}</label>
                    <span class="form-response-text">{{
                      findingDetails.brands.join(", ")
                    }}</span>
                  </div> -->
                  <div class="element-margin" :class="classes">
                    <label class="bold-label">{{
                      $t("findings.findingsResolution.owner")
                    }}</label>
                    <span class="form-response-text">{{
                      findingDetails.owner.fullName
                    }}</span>
                  </div>

                  <div
                    class="col-sm-4 col-xl-3 element-margin"
                    v-if="findingDetails.statusCode !== open"
                  >
                    <label class="bold-label">{{
                      $t("findings.findingsResolution.capDate")
                    }}</label>

                    <span class="form-response-text">
                      {{
                        localeDatetimeFormat(findingDetails.capDate) === ""
                          ? this.$t("findings.findingsResolution.notSet")
                          : localeDatetimeFormat(findingDetails.capDate)
                      }}</span
                    >
                  </div>

                  <div
                    class="col-sm-4 col-xl-3 element-margin"
                    v-if="findingDetails.statusCode !== open"
                  >
                    <label class="bold-label">{{
                      $t("findings.findingsResolution.approvalDeadline")
                    }}</label>
                    <span class="form-response-text">
                      {{
                        localeDatetimeFormat(
                          findingDetails.deadlineForApproval,
                        ) === ""
                          ? this.$t("findings.findingsResolution.notSet")
                          : localeDatetimeFormat(
                              findingDetails.deadlineForApproval,
                            )
                      }}</span
                    >
                  </div>
                </div>

                <div class="" v-if="findingDetails.statusCode === open">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6" style="margin-bottom: 20px">
                      <div style="display: flex">
                        <label class="bold-label">
                          {{ $t("findings.findingsResolution.capDate") }}
                          <DxTooltip
                            :visible.sync="capDateToolTip"
                            :close-on-outside-click="false"
                            target="#divCapDate"
                            position="top"
                          >
                            {{ $t("tooltips.findingsResolution.capDate") }}
                          </DxTooltip>
                        </label>
                        <div
                          class="tooltip"
                          id="divCapDate"
                          @mouseenter="capDateToolTip = !capDateToolTip"
                          @mouseleave="capDateToolTip = !capDateToolTip"
                        ></div>
                      </div>
                      <div>
                        <dx-date-box
                          type="date"
                          styling-mode="outlined"
                          pickerType="calendar"
                          :acceptCustomValue="false"
                          :openOnFieldClick="true"
                          v-model="findingDetails.capDate"
                          @value-changed="updateDates($event, 'capDate')"
                          style="max-width: 575px"
                          :disabled-dates="disabledDates"
                          :disabled="
                            !$can(
                              'update',
                              $subjects.findingResolution.subject,
                              'CapDate',
                            )
                          "
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6" style="margin-bottom: 20px">
                      <div style="display: flex">
                        <label class="bold-label">
                          {{
                            $t("findings.findingsResolution.approvalDeadline")
                          }}
                          <DxTooltip
                            :visible.sync="approvalDeadlineToolTip"
                            :close-on-outside-click="false"
                            target="#divApprovalDeadline"
                            position="top"
                          >
                            {{
                              $t(
                                "tooltips.findingsResolution.deadlineForApproval",
                              )
                            }}
                          </DxTooltip>
                        </label>
                        <div
                          class="tooltip"
                          id="divApprovalDeadline"
                          @mouseenter="
                            approvalDeadlineToolTip = !approvalDeadlineToolTip
                          "
                          @mouseleave="
                            approvalDeadlineToolTip = !approvalDeadlineToolTip
                          "
                        ></div>
                      </div>
                      <div>
                        <dx-date-box
                          type="date"
                          styling-mode="outlined"
                          pickerType="calendar"
                          :acceptCustomValue="false"
                          :openOnFieldClick="true"
                          v-model="findingDetails.deadlineForApproval"
                          @value-changed="updateDates($event, 'targetDate')"
                          :disabled-dates="disabledDates"
                          v-if="findingDetails.statusCode === open"
                          :disabled="
                            !$can(
                              'update',
                              $subjects.findingResolution.subject,
                              'DeadlineForApproval',
                            )
                          "
                          style="max-width: 575px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="element-margin">
                  <label class="bold-label">{{
                    $t("findings.findingsResolution.description")
                  }}</label>
                  <p
                    class="main-content-desc"
                    style="white-space: break-spaces"
                  >
                    {{ findingDetails.description }}
                  </p>
                </div>

                <attachment-card-list
                  class="partial-attachments"
                  :title="$t('findings.findingsResolution.findingAttachments')"
                  :showButton="false"
                  :attachments="attachments"
                  @delete-attachment="
                    deleteSelectedAttachment($event, 'finding')
                  "
                  @download-attachment="downloadAttachment($event)"
                  :showDeleteButton="!readOnly"
                />
              </div>
            </div>
            <div class="col-lg-6 hw-below-fhd">
              <div
                class="primary-container card-container"
                style="padding-bottom: 0"
              >
                <h2>
                  <i class="far fa-edit"></i>
                  {{ $t("findings.findingsResolution.resolutionDetails") }}
                </h2>

                <div
                  class="content no-breaklines"
                  style="padding-bottom: 10px !important"
                >
                  <div class="row">
                    <div
                      class="col-lg-12 col-xl-6 content-block"
                      style="margin-top: 0"
                    >
                      <div style="display: flex">
                        <label class="required bold-label">
                          {{
                            $t("findings.findingsResolution.responsiblePerson")
                          }}
                          <DxTooltip
                            :visible.sync="responsiblePersonToolTip"
                            :close-on-outside-click="false"
                            target="#divResponsiblePerson"
                            position="top"
                          >
                            {{
                              $t(
                                "tooltips.findingsResolution.responsiblePerson",
                              )
                            }}
                          </DxTooltip>
                        </label>
                        <div
                          class="tooltip"
                          id="divResponsiblePerson"
                          @mouseenter="
                            responsiblePersonToolTip = !responsiblePersonToolTip
                          "
                          @mouseleave="
                            responsiblePersonToolTip = !responsiblePersonToolTip
                          "
                        ></div>
                      </div>
                      <div>
                        <dx-select-box
                          :value.sync="selectedResponsiblePerson"
                          :data-source="reviewParticipants"
                          stylingMode="outlined"
                          display-expr="fullname"
                          value-expr="id"
                          :disabled="
                            !$can(
                              'update',
                              $subjects.findingResolution.subject,
                              'ResponsiblePerson',
                            )
                          "
                          :readOnly="readOnly"
                          :placeholder="
                            $t('shared.select', {
                              dataField: $t(
                                'findings.findingsResolution.responsiblePerson',
                              ),
                            })
                          "
                        >
                          <dx-validator>
                            <dx-required-rule
                              :message="
                                $t('shared.isRequired', {
                                  dataField: $t(
                                    'findings.findingsResolution.responsiblePerson',
                                  ),
                                })
                              "
                            />
                          </dx-validator>
                        </dx-select-box>
                      </div>
                    </div>
                    <div
                      class="col-lg-12 col-xl-6 content-block"
                      style="margin-top: 0"
                    >
                      <div style="display: flex">
                        <label class="required bold-label"
                          >{{
                            $t("findings.findingsResolution.implementationDate")
                          }}
                          <DxTooltip
                            :visible.sync="reviewParticipantTargetDateToolTip"
                            :close-on-outside-click="false"
                            target="#divReviewParticipantTargetDate"
                            position="top"
                          >
                            {{
                              $t(
                                "tooltips.findingsResolution.reviewParticipantTargetDate",
                              )
                            }}
                          </DxTooltip>
                        </label>
                        <div
                          class="tooltip"
                          id="divReviewParticipantTargetDate"
                          @mouseenter="
                            reviewParticipantTargetDateToolTip =
                              !reviewParticipantTargetDateToolTip
                          "
                          @mouseleave="
                            reviewParticipantTargetDateToolTip =
                              !reviewParticipantTargetDateToolTip
                          "
                        ></div>
                      </div>
                      <div>
                        <dx-date-box
                          type="date"
                          styling-mode="outlined"
                          pickerType="calendar"
                          v-model="selectedReviewParticipantTargetDate"
                          :acceptCustomValue="false"
                          :openOnFieldClick="true"
                          :disabled-dates="disabledDates"
                          :disabled="
                            !$can(
                              'update',
                              $subjects.findingResolution.subject,
                              'ReviewParticipantTargetDate',
                            )
                          "
                          :readOnly="readOnly"
                          style="max-width: 575px"
                        >
                          <dx-validator>
                            <dx-required-rule
                              :message="
                                $t('shared.isRequired', {
                                  dataField: $t(
                                    'findings.findingsResolution.reviewParticipantTargetDate',
                                  ),
                                })
                              "
                            />
                          </dx-validator>
                        </dx-date-box>
                      </div>
                    </div>
                  </div>
                  <div class="content-block" style="margin-top: 0">
                    <div style="display: flex">
                      <label class="required bold-label">
                        {{ $t("findings.findingsResolution.rootCause") }}
                        <DxTooltip
                          :visible.sync="rootCauseToolTip"
                          :close-on-outside-click="false"
                          target="#divRootCause"
                          position="top"
                        >
                          {{ $t("tooltips.findingsResolution.rootCause") }}
                        </DxTooltip>
                      </label>
                      <div
                        class="tooltip"
                        id="divRootCause"
                        @mouseenter="rootCauseToolTip = !rootCauseToolTip"
                        @mouseleave="rootCauseToolTip = !rootCauseToolTip"
                      ></div>
                    </div>
                    <div>
                      <dx-text-area
                        :value.sync="resolutionRootCause"
                        stylingMode="outlined"
                        max-length="2000"
                        height="120px"
                        :placeholder="
                          $t('shared.enter', {
                            dataField: $t(
                              'findings.findingsResolution.rootCause',
                            ),
                          })
                        "
                        :disabled="
                          !$can(
                            'update',
                            $subjects.findingResolution.subject,
                            'RootCause',
                          )
                        "
                        :readOnly="readOnly"
                      >
                        <dx-validator>
                          <dx-required-rule
                            :message="
                              $t('findings.findingsResolution.rootCause')
                            "
                          />
                        </dx-validator>
                      </dx-text-area>
                    </div>
                  </div>
                  <div class="content-block" style="margin-top: 0">
                    <div style="display: flex">
                      <label class="required bold-label">
                        {{
                          $t("findings.findingsResolution.resolutionComments")
                        }}
                        <DxTooltip
                          :visible.sync="resolutionCommentsToolTip"
                          :close-on-outside-click="false"
                          target="#divResolutionComments"
                          position="top"
                        >
                          {{
                            $t("tooltips.findingsResolution.resolutionDetails")
                          }}
                        </DxTooltip>
                      </label>
                      <div
                        class="tooltip"
                        id="divResolutionComments"
                        @mouseenter="
                          resolutionCommentsToolTip = !resolutionCommentsToolTip
                        "
                        @mouseleave="
                          resolutionCommentsToolTip = !resolutionCommentsToolTip
                        "
                      ></div>
                    </div>
                    <div>
                      <dx-text-area
                        :value.sync="resolutionComment"
                        stylingMode="outlined"
                        max-length="2000"
                        height="120px"
                        :placeholder="
                          $t('shared.enter', {
                            dataField: $t(
                              'findings.findingsResolution.resolutionCommentsPlaceholder',
                            ),
                          })
                        "
                        :disabled="
                          !$can(
                            'update',
                            $subjects.findingResolution.subject,
                            'ResolutionDetail',
                          )
                        "
                        :readOnly="readOnly"
                      >
                        <dx-validator>
                          <dx-required-rule
                            :message="
                              $t(
                                'findings.findingsResolution.resolutionCommentsRequired',
                              )
                            "
                          />
                        </dx-validator>
                      </dx-text-area>
                    </div>
                  </div>
                  <div
                    class="content-block"
                    style="margin-bottom: 0"
                    v-if="findingDetails.statusCode !== open"
                  >
                    <div
                      class="row mb-3 finding-details"
                      v-if="
                        resolutionDate || findingDetails.resolution.fullName
                      "
                    >
                      <div class="col-lg-6">
                        <label class="bold-label">{{
                          $t("findings.findingsResolution.resolutionDate")
                        }}</label>
                        <span class="form-response-text">{{
                          localeDatetimeFormat(resolutionDate)
                        }}</span>
                      </div>
                      <div class="col-lg-6">
                        <label class="bold-label">
                          {{ $t("findings.findingsResolution.resolvedBy") }}
                        </label>
                        <span class="form-response-text">{{
                          findingDetails.resolution.fullName
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="content-block"
                    style="margin-bottom: 0"
                    v-if="findingDetails.statusCode === closed"
                  >
                    <div
                      class="row mb-3 finding-details"
                      v-if="
                        findingDetails.approval.date ||
                        findingDetails.approval.fullName
                      "
                    >
                      <div class="col-lg-6">
                        <label class="bold-label">{{
                          $t("findings.findingsResolution.approvedDate")
                        }}</label>
                        <span class="form-response-text">{{
                          localeDatetimeFormat(findingDetails.approval.date)
                        }}</span>
                      </div>
                      <div class="col-lg-6">
                        <label class="bold-label">
                          {{ $t("findings.findingsResolution.approvedBy") }}
                        </label>
                        <span class="form-response-text">{{
                          findingDetails.approval.fullName
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <template
                  v-if="resolutionAttachments && resolutionAttachments.length"
                >
                  <attachment-card-list
                    class="partial-attachments"
                    style="padding-bottom: 20px"
                    :title="
                      $t('findings.findingsResolution.resolutionAttachments')
                    "
                    :showButton="false"
                    :attachments="resolutionAttachments"
                    @delete-attachment="
                      deleteSelectedAttachment($event, 'resolution')
                    "
                    @download-attachment="downloadAttachment($event)"
                    :showDeleteButton="!readOnly"
                  />
                </template>
                <template v-if="!readOnly">
                  <div class="content-block" style="margin-top: 0">
                    <div style="display: flex">
                      <label class="bold-label">
                        {{
                          $t("findings.findingsResolution.findingAttachments")
                        }}
                        <DxTooltip
                          :visible.sync="findingAttachmentsToolTip"
                          :close-on-outside-click="false"
                          target="#divFindingAttachments"
                          position="top"
                        >
                          {{ $t("tooltips.findingsResolution.attachments") }}
                        </DxTooltip>
                      </label>
                      <div
                        class="tooltip"
                        id="divFindingAttachments"
                        @mouseenter="
                          findingAttachmentsToolTip = !findingAttachmentsToolTip
                        "
                        @mouseleave="
                          findingAttachmentsToolTip = !findingAttachmentsToolTip
                        "
                      ></div>
                    </div>
                    <div>
                      <div
                        class="upload-container partial-attachment-upload"
                        style="margin-left: 0px; margin-right: 0px"
                      >
                        <attachment-upload
                          @upload-successful="onUploadSuccess"
                          @upload-started="onUploadStarted"
                          @upload-error="onUploadError"
                          uploadMode="instantly"
                          :uploadUrl="attachmentUploadUrl"
                        ></attachment-upload>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <finding-rework-popup
                :visible.sync="reworkPopupVisible"
                :findingTitle="findingDetails.description"
                :resolutionDate.sync="resolutionDate"
                :resolutionComment.sync="resolutionComment"
                :findingOwner="findingDetails.owner.fullName"
                @send-rework-click="sendForRework($event)"
                @close-click="closeReworkPopup()"
              />
            </div>
          </div>
          <div class="row">
            <!-- ********************* comments section -->
            <div class="col-xl-12">
              <comments
                :comments="comments"
                @edit-comment-click="editCommentClick($event)"
                @delete-comment-click="deleteCommentClick($event)"
                @submit-comment-click="submitComment($event)"
                @save-comment-click="editComment($event)"
                :isSelfContained="true"
                :showEditButtons="true"
                :headerDescription="
                  this.$t('findings.findingsResolution.commentsDescription')
                "
                :toolTipDescription="
                  this.$t('tooltips.findingsResolution.generalComments')
                "
                :hasToolTip="true"
                :readOnly="isInRole('Orderer')"
              />
            </div>
          </div>

          <div
            class="fixed-bottom-container"
            v-if="!readOnly || findingDetails.statusCode === submitted"
          >
            <div class="row">
              <div class="col">
                <dx-button
                  :text="$t('findings.findingsResolution.saveDraft')"
                  :visible="
                    !readOnly &&
                    (isInRole(admin) || isInRole(reviewParticipant))
                  "
                  name="saveAsDraft"
                  @click="saveAsDraftButtonClick($event)"
                  type="normal"
                  class="btn btn-large mr-4"
                  icon="edit"
                />

                <dx-button
                  :text="$t('findings.findingsResolution.sendForRework')"
                  name="sendForApproval"
                  class="btn btn-large mr-4"
                  icon="edit"
                  :visible="
                    findingDetails.statusCode === submitted &&
                    (isInRole(admin) || isInRole(reviewer))
                  "
                  type="normal"
                  @click="changeSendForReworkPopUpVisibility"
                />
              </div>
              <div class="col-auto">
                <dx-button
                  :text="$t('findings.findingsResolution.sendForApproval')"
                  :visible="
                    !readOnly &&
                    (isInRole(admin) || isInRole(reviewParticipant))
                  "
                  name="sendForApproval"
                  type="default"
                  class="btn btn-large"
                  icon="check"
                  :use-submit-behavior="true"
                />
                <dx-button
                  :text="$t('findings.findingsResolution.approve')"
                  name="Approve"
                  :visible="
                    findingDetails.statusCode === submitted &&
                    (isInRole(admin) || isInRole(reviewer))
                  "
                  style="min-width: 150px"
                  @click="approveButtonClick($event)"
                  type="default"
                  icon="check"
                  class="btn btn-large"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeading from "../components/page-heading";
import AttachmentCardList from "../components/shared/attachment-card-list";
import {DxTextArea, DxButton, DxSelectBox, DxTooltip} from "devextreme-vue";
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import notify from "devextreme/ui/notify";
import {mapActions, mapState} from "vuex";
import FindingReworkPopup from "../components/finding/finding-rework-popup";
import {confirm} from "devextreme/ui/dialog";
import AttachmentUpload from "../components/shared/attachment-upload";
import roleTypes from "../config/roles";
import {findingStatuses} from "../custom/config/workflows";
import localeDatetimeFormat from "../mixins/locale-datetime-format";
import AuditLog from "../components/audit/audit-log";
import {toCamelCase, isUnsavedComment} from "../common/helperFunctions";
import downloadAuthAttachment from "../mixins/download-auth-attachment";
import {isNotEmpty} from "../common/helperFunctions";
import DxDateBox from "devextreme-vue/date-box";
import {fromResponse} from "../utils/error-message-extractor";
import Comments from "../components/shared/comments";

const {VUE_APP_API_HOSTNAME} = process.env;

export default {
  components: {
    pageHeading,
    AttachmentCardList,
    DxTextArea,
    DxValidator,
    DxButton,
    DxRequiredRule,
    AttachmentUpload,
    FindingReworkPopup,
    AuditLog,
    DxDateBox,
    DxSelectBox,
    Comments,
    DxTooltip,
  },
  mixins: [localeDatetimeFormat, downloadAuthAttachment],
  async beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (
      !(this.$router.history.pending.name === "login-form") &&
      (await this.confirmStayInDirtyForm())
    ) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  created() {
    this.$store.dispatch("auth/setUser");
    this.getCurrentFinding(this.$route.params.id);
    this.getFindingAttachments(this.$route.params.id);
    this.getFindingResolutionAttachments(this.$route.params.id);

    // enums
    this.open = findingStatuses.open;
    this.submitted = findingStatuses.submitted;
    this.closed = findingStatuses.closed;

    this.auditManager = roleTypes.auditManager;
    this.auditor = roleTypes.auditor;
    this.retailer = roleTypes.retailer;
    this.admin = roleTypes.admin.role;
    this.reviewer = roleTypes.reviewer.role;
    this.reviewParticipant = roleTypes.reviewParticipant.role;
    this.getCommentsList({responseFindingId: this.$route.params.id});
  },
  beforeDestroy() {
    // clear the finding
    this.updateFinding(null);
  },
  data() {
    return {
      currentFindingId: null,
      resolutionComment: null,
      resolutionDate: "",
      files: [],
      isSlideOutOpen: false,
      reworkPopupVisible: false,
      isAuditLogsSectionVisible: false,
      isFindingSectionVisible: true,
      reviewParticipants: null,
      selectedResponsiblePerson: null,
      resolutionRootCause: null,
      capDateToolTip: false,
      approvalDeadlineToolTip: false,
      reviewParticipantTargetDateToolTip: false,
      responsiblePersonToolTip: false,
      resolutionCommentsToolTip: false,
      rootCauseToolTip: false,
      findingAttachmentsToolTip: false,
      selectedReviewParticipantTargetDate: null,
      isRedirectingAfterUpdateSuccess: false,
    };
  },
  methods: {
    camelCase: toCamelCase,
    ...mapActions("findingsResolution", [
      "deleteAttachment",
      "sendFindingForRework",
      "getFindingAttachments",
      "getFindingResolutionAttachments",
      "updateFinding",
      "updateResolutionAttachmentsStatus",
      "getFinding",
      "sendForApproval",
      "approveFinding",
      "removeAttachment",
      "getUsers",
      "postComment",
      "getCommentsList",
      "updateComment",
      "deleteComment",
    ]),
    updateDates(e, date) {
      let payload = {
        findingId: this.findingDetails.id,
        capDate: date === "capDate" ? e.value : null,
        targetDate: date === "targetDate" ? e.value : null,
        reviewParticipantTargetDate:
          date === "reviewParticipantTargetDate" ? e.value : null,
      };

      this.$http
        .post(`assessments/findings/${this.findingDetails.id}/dates`, payload)
        .then(() => {
          notify(
            this.$t("findings.findingsResolution.dateUpdatedSuccess"),
            "success",
          );
        })
        .catch((err) => {
          // resets findingDetails date value if it failed
          if (date === "capDate") {
            this.findingDetails.capDate = e.previousValue;
          } else if (date === "targetDate") {
            this.findingDetails.deadlineForApproval = e.previousValue;
          } else {
            this.findingDetails.reviewParticipantTargetDate = e.previousValue;
          }
          notify(
            `${this.$t(
              "findings.findingsResolution.dateUpdatedFailed",
            )}. ${fromResponse(err)}`,
            "error",
            15000,
          );
        });
    },
    updateResponsibleUser(e) {
      let payload = {
        findingId: this.findingDetails.id,
        responsibleUserId: this.selectedResponsiblePerson,
      };
      this.$http
        .post(`assessments/findings/${this.findingDetails.id}/dates`, payload)
        .then(() => {
          notify(
            this.$t(
              "findings.findingsResolution.responsiblePersonUpdatedSuccess",
            ),
            "success",
          );
        })
        .catch((err) => {
          this.findingDetails.responsibleUserId = e.previousValue; // resets findingDetails responsibleUser value if it failed
          notify(
            `${this.$t(
              "findings.findingsResolution.responsiblePersonUpdatedFailed",
            )}. ${fromResponse(err)}`,
            "error",
            15000,
          );
        });
    },
    deleteSelectedAttachment(id, type) {
      this.deleteAttachment({id: id, type: type});
    },
    toggleSlideOut() {
      this.isSlideOutOpen = !this.isSlideOutOpen;
    },
    getCurrentFinding(findingId) {
      this.currentFindingId = findingId;
      this.getFinding(this.$route.params.id).then((response) => {
        this.getReviewParticipants();
      });
    },
    onUploadSuccess() {
      this.getFindingResolutionAttachments(this.$route.params.id).then(() => {
        notify(this.$t("findings.findingsResolution.uploadSuccess"), "success");
      });
    },
    onUploadStarted() {
      this.updateResolutionAttachmentsStatus("loading");
    },
    onUploadError() {
      this.updateResolutionAttachmentsStatus("error");
      notify(this.$t("attachments.errors.fileUploadFailedMessage"), "error");
    },
    reworkButtonClick() {
      this.reworkPopupVisible = true;
    },
    formatStatusCode(statusCode) {
      return this.$t(`findings.findingList.${toCamelCase(statusCode)}`);
    },
    setStatusClass(statusCode) {
      switch (statusCode) {
        case "Open":
          return "scheduled";
        case "Submitted":
          return "yellow";
        case "Closed":
          return "green";
        default:
          return "";
      }
    },
    async changeSendForReworkPopUpVisibility() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      this.reworkPopupVisible = !this.reworkPopupVisible;
    },
    sendForRework(e) {
      this.sendFindingForRework({
        comment: e,
        assessmentId: this.$route.params.id,
      }).then(() => {
        this.reworkPopupVisible = false;
        this.getFinding(this.$route.params.id);
        notify(
          this.$t("findings.findingsResolution.sentForReworkSuccess"),
          "success",
        );
      });
    },
    closeReworkPopup() {
      this.reworkPopupVisible = false;
    },
    async approveButtonClick() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      let result = confirm(
        this.$t("findings.findingsResolution.approveFindingQuestion"),
        this.$t("findings.findingsResolution.approveFindingPopup"),
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.approveFinding(this.$route.params.id).then(() => {
            this.getFinding(this.$route.params.id);
            notify(
              this.$t("findings.findingsResolution.approveFindingSuccess"),
              "success",
            );
          });
        }
      });
    },
    downloadAttachment(e) {
      if (!isNotEmpty(e.attachment)) return;
      this.downloadAuthAttachment(e.attachment.link);
    },
    async saveAsDraftButtonClick() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      const payload = {
        findingId: this.currentFindingId,
        actionComment: this.resolutionComment,
        actionStatusCode: findingStatuses.open,
        attachments: this.files,
        rootCause: this.resolutionRootCause,
        reviewParticipantTargetDate:
          this.selectedReviewParticipantTargetDate == null
            ? null
            : this.$moment
                .utc(this.selectedReviewParticipantTargetDate)
                .local()
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss"),
        responsibleUserId: this.selectedResponsiblePerson,
      };
      this.sendForApproval(payload).then(() => {
        this.getCurrentFinding(this.$route.params.id);
        notify(
          this.$t("findings.findingsResolution.saveFindingSuccess"),
          "success",
        );
      });
    },
    async onFormSubmit() {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
          this.$t("shared.unsavedCommentsTitle"),
        );
        if (result) return;
      }
      const payload = {
        findingId: this.currentFindingId,
        actionComment: this.resolutionComment,
        actionStatusCode: findingStatuses.submitted,
        attachments: this.files,
        rootCause: this.resolutionRootCause,
        reviewParticipantTargetDate:
          this.selectedReviewParticipantTargetDate == null
            ? null
            : this.$moment
                .utc(this.selectedReviewParticipantTargetDate)
                .local()
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss"),
        responsibleUserId: this.selectedResponsiblePerson,
      };
      this.sendForApproval(payload).then(() => {
        this.getCurrentFinding(this.$route.params.id);
        notify(
          this.$t("findings.findingsResolution.resolveFindingSuccess"),
          "success",
        );
        this.isRedirectingAfterUpdateSuccess = true;
        this.$router.push({name: "findings-list"});
      });
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    showAuditLogTable() {
      this.isAuditLogsSectionVisible = true;
      this.isFindingSectionVisible = false;
    },
    showFindingSection() {
      this.isAuditLogsSectionVisible = false;
      this.isFindingSectionVisible = true;
    },
    disabledDates(args) {
      var today = new Date();
      if (args.date < today) {
        return true;
      }
      return false;
    },
    async getReviewParticipants() {
      const response = await this.getUsers("ReviewParticipant");
      let activeParticpants = response.data.filter(
        (x) =>
          x.activeState == 1 || x.id == this.findingDetails.responsibleUserId,
      );
      this.reviewParticipants = activeParticpants.sort(function (a, b) {
        return a.fullname.localeCompare(b.fullname);
      });
      if (
        this.reviewParticipants &&
        this.reviewParticipants.length > 0 &&
        this.findingDetails.resolution
      ) {
        const index = this.reviewParticipants.findIndex(
          (y) => y.id === this.findingDetails.responsibleUserId,
        );
        if (index != -1) {
          this.selectedResponsiblePerson = this.reviewParticipants[index].id;
        }
      }
    },
    async submitComment(comment) {
      try {
        await this.postComment({
          comment: comment,
          responseFindingId: this.currentFindingId,
        });

        notify(
          this.$t("findings.findingsResolution.successfullyAddedComment"),
          "success",
        );
        this.getCommentsList({responseFindingId: this.currentFindingId});
      } catch (err) {
        notify(err, "error");
      }
    },
    async editComment(e) {
      try {
        let payload = {
          commentId: e.commentsId,
          comment: e.comments,
        };
        await this.updateComment(payload);

        notify(
          this.$t("findings.findingsResolution.successfullyEditedComment"),
          "success",
        );
      } catch (error) {
        notify(error.response.data.message || error, "error");
      }
    },
    async deleteCommentClick(e) {
      let result = confirm(
        `<i>${this.$t("shared.sureDeleteComment")}</i>`,
        this.$t("shared.confirmDeletion"),
      );
      result.then(async (dialogResult) => {
        if (dialogResult) {
          try {
            await this.deleteComment(e.commentsId);

            notify(
              this.$t("findings.findingsResolution.successfullyDeletedComment"),
              "success",
            );
            this.getCommentsList({responseFindingId: this.currentFindingId});
          } catch (error) {
            notify(error.response.data.message || error, "error");
          }
        }
      });
    },

    hasValueChanged() {
      let hasValChanged = false;
      if (!this.findingDetails) {
        return (
          !this.resolutionComment &&
          !this.resolutionRootCause &&
          !this.selectedResponsiblePerson
        );
      }
      const initialState = this.getInitialState;
      const currentState = this.getCurrentState;
      hasValChanged = !(
        JSON.stringify(initialState, (k, v) => (v === undefined ? null : v)) ===
        JSON.stringify(currentState, (k, v) => (v === undefined ? null : v))
      );
      return hasValChanged;
    },
    unsavedChangesAlert(message, title) {
      return confirm(`<i>${message}</i>`, title);
    },

    async confirmStayInDirtyForm() {
      return (
        !this.isRedirectingAfterUpdateSuccess &&
        (isUnsavedComment() || this.hasValueChanged()) &&
        !(await this.unsavedChangesAlert(
          this.$t("shared.unsavedChangesConfirmation"),
          this.$t("shared.unsavedChangesTitle"),
        ))
      );
    },
  },

  computed: {
    ...mapState("findingsResolution", ["comments"]),
    attachments() {
      return this.$store.state.findingsResolution.attachments;
    },
    resolutionAttachments() {
      return this.$store.state.findingsResolution.resolutionAttachments;
    },
    attachmentUploadUrl() {
      return `${VUE_APP_API_HOSTNAME}/assessments/findings/${this.$route.params.id}/evidence`;
    },
    findingDetails() {
      return this.$store.state.findingsResolution.finding;
    },
    breadCrumbs() {
      const findingBreadCrumb = this.findingDetails.question.requirementNumber
        ? this.findingDetails.question.requirementNumber
        : this.findingDetails.question.title.substring(0, 50);

      return [this.$t("findings.findingList.title"), findingBreadCrumb];
    },
    user() {
      return this.$store.auth.user;
    },
    readOnly() {
      switch (this.$store.state.auth.user.roles[0]) {
        case roleTypes.reviewer.role:
        case roleTypes.admin.role:
        case roleTypes.reviewParticipant.role:
          return this.findingDetails.statusCode !== findingStatuses.open;
        default:
          return true;
      }
    },
    classes() {
      if (this.findingDetails.statusCode !== this.open) {
        return "col-sm-4 col-xl-3";
      } else {
        return "col-sm-12 col-md-6";
      }
    },
    getInitialState() {
      return {
        resolutionComment: this.findingDetails.resolution.comment,
        rootCause: this.findingDetails.rootCause,
        responsiblePerson: this.findingDetails.responsibleUserId,
        reviewParticipantTargetDate:
          this.findingDetails.reviewParticipantTargetDate,
      };
    },
    getCurrentState() {
      return {
        resolutionComment: this.resolutionComment
          ? this.resolutionComment
          : null,
        rootCause: this.resolutionRootCause ? this.resolutionRootCause : null,
        responsiblePerson: this.selectedResponsiblePerson,
        reviewParticipantTargetDate: this.selectedReviewParticipantTargetDate,
      };
    },
  },
  watch: {
    findingDetails() {
      if (this.findingDetails.resolution !== null) {
        if (this.findingDetails.resolution) {
          this.resolutionComment = this.findingDetails.resolution.comment
            ? this.findingDetails.resolution.comment
            : "";
          this.resolutionDate = this.findingDetails.resolution.date
            ? this.findingDetails.resolution.date
            : "";
        }
      }
      this.resolutionRootCause = this.findingDetails.rootCause
        ? this.findingDetails.rootCause
        : "";
      this.selectedReviewParticipantTargetDate =
        this.findingDetails.reviewParticipantTargetDate;
    },
  },
};
</script>

<style lang="scss" scoped></style>
