<template>
  <div class="report-filter-pane-item-filter">
    <dx-tag-box
      :defer-rendering="false"
      :data-source="capStatusList"
      display-expr="name"
      :placeholder="$t('reporting.filters.capStatusPlaceholder')"
      :show-clear-button="true"
      :show-drop-down-button="true"
      :show-selection-controls="true"
      styling-mode="outlined"
      :value.sync="capStatuses"
      value-expr="id"
      :wrapItemText="true"
      apply-value-mode="instantly"
      :max-displayed-tags="1"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapState} from "vuex";
import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: {
    DxTagBox,
  },
  computed: {
    ...mapFields("reportingFilters", ["capStatuses"], "getFilter", "setFilter"),
    ...mapState("orders", ["levels"]),
    capStatusList() {
      return [
        {id: 1, name: this.$t("orders.reviewDetails.capProcess")},
        {id: 2, name: this.$t("orders.reviewDetails.capProcessMinus90Days")},
        {id: 3, name: this.$t("orders.reviewDetails.capProcessMinus180Days")},
        {
          id: 4,
          name: this.$t("orders.reviewDetails.capProcessGreaterOrEqual180Days"),
        },
      ];
    },
  },
};
</script>

<style lang="sass" scoped></style>
