<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />
    <div class="fixed-action-container">
      <i class="fas fa-plus"></i>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <dx-button
          :visible="$can('create', $subjects.order.subject)"
          @click="newOrderButtonClick"
          type="normal"
          class="schedule-visit hidden-sm hidden-xs"
          stylingMode="contained"
          icon="plus"
          :text="this.$t('orders.orderList.newOrderButton')"
        >
        </dx-button>
      </div>
    </div>

    <div class="card-container" style="margin-bottom: 90px">
      <div class="row">
        <div class="col-xl-4">
          <h2>
            <i class="far fa-clipboard"></i>
            {{ $t("orders.orderList.title") }}
          </h2>
          <div class="row">
            <div class="col-md-4 borderless-small-dd">
              <audit-year-select />
            </div>
            <div
              v-if="
                !(
                  isInRole(roleTypes.reviewParticipant.role) ||
                  isInRole(roleTypes.businessOwner.role)
                )
              "
              class="col-md-6 borderless-small-dd"
            >
              <order-filter />
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <ul class="custom-tabs">
            <li
              ref="allTab"
              @click="onTabChanged(event, 'All')"
              class="custom-tab-active"
            >
              {{ $t("orders.orderList.all") }}
            </li>
            <li @click="onTabChanged(event, 'Ordered')">
              {{ $t("orders.orderList.ordered") }}
            </li>
            <li @click="onTabChanged(event, 'Scheduled')">
              {{ $t("orders.orderList.scheduled") }}
            </li>
            <li @click="onTabChanged(event, 'InProgress')">
              {{ $t("orders.orderList.inProgress") }}
            </li>
            <li @click="onTabChanged(event, 'Submitted')">
              {{ $t("orders.orderList.submitted") }}
            </li>
            <li @click="onTabChanged(event, 'Completed')">
              {{ $t("orders.orderList.completed") }}
            </li>
            <li @click="onTabChanged(event, 'Cancelled')">
              {{ $t("orders.orderList.cancelled") }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="content-block" style="margin-bottom: 0px">
            <div
              class="pre-answered-response with-side-action mb-4"
              v-if="showPreFilteredBar"
            >
              <h3>
                <i class="fas fa-filter"></i>
                {{ $t("orders.orderList.preFilteredHeading") }}
              </h3>

              <div class="mt-2">
                {{ $t("orders.orderList.preFilteredText") }}
              </div>

              <div class="side-action-box" @click="clearGridFilters">
                <i class="fas fa-sync"></i>
                <p>{{ $t("orders.orderList.clearFilters") }}</p>
              </div>
            </div>
            <div v-if="selectedAuditYear && selectedOrderFilter">
              <DxDataGrid
                class="dx-card thin-column-chooser"
                :data-source="dataSource()"
                :show-borders="false"
                key-expr="id"
                :ref="dataGridRefName"
                :focused-row-index="0"
                :focused-row-enabled="false"
                :column-hiding-enabled="false"
                :selection="{mode: 'single'}"
                :hover-state-enabled="true"
                :columns="colDefs"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-min-width="150"
                columnResizingMode="widget"
              >
                <DxHeaderFilter :visible="true" :allowSearch="true" />
                <DxColumnChooser
                  :enabled="true"
                  :allowSearch="true"
                  :height="500"
                  mode="dragAndDrop"
                />
                <DxColumnFixing :enabled="true" />
                <DxRemoteOperations
                  :filtering="false"
                  :paging="false"
                  :sorting="false"
                  :summary="false"
                  :grouping="false"
                  :group-paging="false"
                />
                <DxStateStoring
                  :enabled="true"
                  type="localStorage"
                  storage-key="storageOrderList"
                  :saving-timeout="savingTimeout"
                />

                <template #clear-filter-cell-template>
                  <div>
                    <div class="cell-button-header" @click="switchToAllTab">
                      <p>
                        <i class="fas fa-sync"></i>
                        <span>{{ $t("shared.clearFilters") }}</span>
                      </p>
                    </div>
                  </div>
                </template>

                <template #order-button-cell-template="{data}">
                  <div style="text-align: center">
                    <assessment-list-button
                      v-if="isAssessmentListButtonVisible(data.data)"
                      :text="setAssessmentListButtonText(data)"
                      @assessmentButtonClicked="onRowButtonClick(data)"
                    />
                    <span
                      class="table-button"
                      v-if="!isInRole(roleTypes.reviewParticipant.role)"
                      @click="editOrderClick(data)"
                      :title="setButtonText(data)"
                    >
                      <i class="far fa-edit"></i>
                    </span>
                  </div>
                </template>
                <template #siteCategories-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #scopeOfReview-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #levelsOfReview-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #tertial-template="{data}">
                  {{ formatTertial(data) }}
                </template>
                <template #supportReviewers-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #businessOwners-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #reviewParticipants-template="{data}">
                  <div>
                    {{ getStringArrayCellData(data) }}
                  </div>
                </template>
                <template #status-template="{data}">
                  <label
                    class="table-status-label"
                    :class="setStatusClass(data)"
                    >{{ formatStatus(data) }}</label
                  >
                </template>
                <template #riskRating-template="{data}">
                  <div>
                    {{ getRiskRating(data.data.riskScore) }}
                  </div>
                </template>
                <template #complianceRate-template="{data}">
                  <span>
                    {{ getRoundedComplianceRate(data.data) }}
                  </span>
                </template>
                <DxPaging :page-size="10" />
                <DxPager :show-page-size-selector="true" :show-info="true" />
                <DxFilterRow :visible="true" />
                <DxExport :enabled="true" />
              </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import $axios from "../utils/axios-instance";
import {DxButton} from "devextreme-vue";
import DxDataGrid, {
  DxFilterRow,
  DxPager,
  DxPaging,
  DxRemoteOperations,
  DxHeaderFilter,
  DxStateStoring,
  DxColumnChooser,
  DxColumnFixing,
  DxExport,
} from "devextreme-vue/data-grid";
import pageHeading from "../components/page-heading";
import AssessmentListButton from "../components/assessment/assessment-list-button";
import AuditYearSelect from "../components/organisation/audit-year-select";
import listColDefs from "../custom/order/orderListColDefs";
import {isNotEmpty, toCamelCase} from "../common/helperFunctions";
import subjects from "./../config/subjects";
import {AbilityBuilder} from "@casl/ability";
import {orderStatuses} from "../../src/custom/config/workflows";
import {reviewModes, riskRating} from "../../src/common/constants";
import roleTypes from "../config/roles";
import OrderFilter from "../components/orders/order-filter";

export default {
  components: {
    pageHeading,
    DxButton,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxExport,
    DxRemoteOperations,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnChooser,
    DxColumnFixing,
    AuditYearSelect,
    AssessmentListButton,
    OrderFilter,
  },
  data() {
    return {
      dataGridRefName: "dataGrid",
      clearedFilter: false,
      roleTypes: roleTypes,
      savingTimeout: 0,
    };
  },
  computed: {
    pageTitle() {
      return this.$t("orders.orderList.title");
    },
    breadCrumbs() {
      return [this.$t("orders.orderList.breadcrumbTitle")];
    },
    showPreFilteredBar() {
      return this.$route.query.status && !this.clearedFilter;
    },
    colDefs() {
      let colDefs = JSON.parse(JSON.stringify(this.colDefsTranslatedLookups));
      let chooserCols = colDefs
        .filter((x) => x.visible === false)
        .sort(function (a, b) {
          var nameA = a.dataField.toUpperCase();
          var nameB = b.dataField.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      let visibleCols = colDefs.filter((x) => x.visible === true);
      let sortedColDefs = visibleCols.concat(chooserCols);

      return sortedColDefs.map((x) =>
        x.caption
          ? {
              ...x,
              caption: this.$t(x.caption),
            }
          : x,
      );
    },
    colDefsTranslatedLookups() {
      let colDefs = JSON.parse(JSON.stringify(listColDefs));
      colDefs = this.isInRole(roleTypes.reviewParticipant.role)
        ? colDefs.filter((x) => x.dataField !== "riskScore")
        : colDefs;
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
    selectedAuditYear() {
      return this.$store.state.organisations.selectedAuditYear;
    },
    selectedOrderFilter() {
      return this.$store.state.orders.orderFilter;
    },
  },
  methods: {
    getRoundedComplianceRate(data) {
      return data && data.complianceRate != null
        ? `${Math.round(data.complianceRate)}%`
        : null;
    },
    isAssessmentListButtonVisible(data) {
      if (this.isInRole(roleTypes.reviewParticipant.role)) {
        return (
          ["Submitted", "Completed"].includes(data.status) ||
          (["Scheduled", "InProgress", "Submitted", "Completed"].includes(
            data.status,
          ) &&
            data.reviewMode === "Self-assessment")
        );
      } else if (this.isInRole(roleTypes.orderer.role)) {
        return (
          ["Submitted", "Completed"].includes(data.status) ||
          ["Scheduled", "InProgress"].includes(data.status)
        );
      } else {
        return ["Scheduled", "InProgress", "Submitted", "Completed"].includes(
          data.status,
        );
      }
    },
    dataSource() {
      return new CustomStore({
        key: "id",
        load: this.load,
      });
    },
    async load(loadOptions) {
      if (this.selectedAuditYear === null) {
        return null;
      } else {
        let params = "?";
        [
          "skip",
          "take",
          "sort",
          "requireTotalCount",
          "requireGroupCount",
          "filter",
          "totalSummary",
          "group",
          "groupSummary",
        ].forEach(function (i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          }
        });
        params = params.slice(0, -1);

        //TODO: Create new end point for orders
        const response = await $axios.get(
          `/orders/Period/${this.selectedAuditYear}/${this.selectedOrderFilter}${params}`,
        );
        const json = await JSON.parse(JSON.stringify(response));
        return {
          data: json.data.data,
          totalCount: json.data.totalCount,
          summary: json.data.summary,
          groupCount: json.data.groupCount,
        };
      }
    },
    newOrderButtonClick() {
      this.$router.push({name: "order"});
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    onTabChanged(e, value) {
      if (!e) e = window.event;
      let sender = e.srcElement || e.target;

      while (sender && sender.nodeName.toLowerCase() != "li")
        sender = sender.parentNode;
      document
        .getElementsByClassName("custom-tab-active")[0]
        .classList.remove("custom-tab-active");
      sender.className = "custom-tab-active";
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      if (value === "All") {
        dataGrid.clearFilter();
      } else {
        dataGrid.filter(["status", "=", value]);
      }
    },
    clearGridFilters() {
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      this.clearedFilter = true;
      dataGrid.clearFilter();
    },
    setStatusClass(data) {
      let status = data.data.status;
      if (status == orderStatuses.cancelled) {
        return "red";
      } else if (status == orderStatuses.inProgress) {
        return "yellow";
      } else if (status == orderStatuses.submitted) {
        return "submitted";
      } else if (status == orderStatuses.completed) {
        return "green";
      } else if (status == orderStatuses.scheduled) {
        return "scheduled";
      } else if (status == orderStatuses.ordered) {
        return "ordered";
      } else {
        return "";
      }
    },
    formatTertial(data) {
      return this.$t(
        `orders.orderList.tertialOptions.${data.data[data.column.dataField]}`,
      );
    },
    formatStatus(data) {
      return this.$t(`orders.orderList.${toCamelCase(data.data.status)}`);
    },
    getStringArrayCellData(data) {
      return data.data[data.column.dataField].map((x) => x).join(", ");
    },
    getRiskRating(data) {
      if (data !== null) {
        switch (true) {
          case data <= 1.0:
            return riskRating.Low;
          case data >= 1.1 && data <= 2.0:
            return riskRating.Medium;
          case data >= 2.1 && data <= 3.0:
            return riskRating.High;
          case data >= 3.1:
            return riskRating.Critical;
          default:
            return;
        }
      }
    },
    setButtonText(data) {
      // const {can} = new AbilityBuilder();

      switch (data.data.status) {
        case orderStatuses.ordered:
          return this.$can("update", subjects.schedule.subject)
            ? this.$t("orders.orderList.scheduleReview")
            : this.$can("update", subjects.order.subject)
            ? this.$t("orders.orderList.editOrder")
            : this.$t("orders.orderList.viewOrder");
        case orderStatuses.scheduled:
          return this.$can("update", subjects.schedule.subject)
            ? this.$t("orders.orderList.updateReview")
            : this.$t("orders.orderList.viewOrder");
        default:
          return this.$t("orders.orderList.viewOrder");
      }
    },
    editOrderClick(data) {
      this.$router.push({
        name: "edit-order",
        params: {
          id: data.data.orderId,
          hasSchedulingDetails: data.data.hasSchedulingDetails,
        },
      });
    },
    setAssessmentListButtonText(data) {
      return this.$t("assessments.assessmentList.openReview");
    },
    onRowButtonClick(data) {
      this.$router.push({
        name: "assessment",
        params: {id: data.data.assessmentId},
      });
    },
    switchToAllTab() {
      this.$refs.allTab.click();
    },
  },
  watch: {
    selectedOrderFilter() {
      this.switchToAllTab();
    },
  },
};
</script>

<style lang="scss" scoped></style>
