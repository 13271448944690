<template>
  <div v-if="filtersList.length">
    <dx-select-box
      :value="findingFilter"
      :data-source="filtersList"
      stylingMode="outlined"
      display-expr="name"
      value-expr="id"
      @value-changed="selectedFilterChanged"
    ></dx-select-box>
  </div>
</template>

<script>
import {DxSelectBox} from "devextreme-vue";
import {mapState} from "vuex";

export default {
  components: {
    DxSelectBox,
  },
  data() {
    return {
      filtersList: [
        {id: 1, name: "My corrective actions"},
        {id: 2, name: "All corrective actions"},
      ],
    };
  },
  methods: {
    selectedFilterChanged(e) {
      this.$store.dispatch("findings/updateFindingFilter", e.value);
    },
  },
  computed: {
    ...mapState("findings", ["findingFilter"]),
  },
  beforedestroy() {
    this.$store.dispatch("findings/updateFindingFilter", null);
  },
};
</script>

<style></style>
