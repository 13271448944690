<template>
  <div class="chart-container card-container">
    <div class="chart-container-header">
      <h1>
        {{ chartTitle }}
      </h1>
      <div class="chart-group-by-container">
        <dx-select-box
          display-expr="name"
          :items="groupByDataSource"
          styling-mode="filled"
          :value.sync="groupByValue"
          value-expr="id"
        />
      </div>
    </div>
    <dx-chart
      :ref="chartRef"
      :data-source="dataSource"
      :customize-point="customizePoint"
      @point-click="onPointClick($event)"
      @exporting="onExporting"
      @exported="onExported"
      @done="chartZoomIn"
      :resolve-label-overlapping="pointOverlappingOptions"
      :animation="{
        enabled: false,
      }"
    >
      <dx-size :height="chartHeight" :width="chartWidth" />
      <dx-loading-indicator :enabled="true" />
      <dx-scroll-bar :visible="true" />
      <dx-zoom-and-pan argument-axis="both" />
      <dx-argument-axis :visual-range="initialRange" />
      <dx-common-axis-settings :color="color.axis">
        <dx-tick :color="color.axis" />
        <dx-label
          :visible="axisOn"
          :displayMode="axisLabelPosition"
          :rotationAngle="axisRotationAngle"
        >
          <dx-font :color="color.text" />
        </dx-label>
      </dx-common-axis-settings>
      <dx-value-axis position="left" :allow-decimals="false">
        <dx-grid :color="color.grid" />
        <dx-label>
          <dx-format type="percent" />
        </dx-label>
      </dx-value-axis>
      <dx-common-series-settings
        argument-field="location"
        value-field="value"
        type="stackedbar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <dx-label
          :visible="pointOn"
          :position="pointLabelPosition"
          :customize-text="customizeText"
        >
          <dx-connector :visible="connectorOn" />
        </dx-label>
      </dx-common-series-settings>
      <!-- update name-field to "series" for whenever we need multiple audit years displayed as well as adjust customizePoint and customizeSeries -->
      <dx-series-template
        :customize-series="customizeSeries"
        name-field="stack"
      />
      <dx-tooltip :enabled="true" content-template="tooltipTemplate" />
      <dx-legend
        orientation="horizontal"
        horizontal-alignment="center"
        vertical-alignment="bottom"
        item-text-position="bottom"
        :visible="legendOn"
      >
        <dx-font :color="color.text" />
      </dx-legend>
      <dx-export :enabled="true" background-color="#ffffff" />
      <template #tooltipTemplate="{data}">
        <span
          >{{ data.point.data.stack }}:
          {{ (data.point.data.value * 100).toFixed(2) }}%</span
        >
      </template>
    </dx-chart>
  </div>
</template>

<script>
import {
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxExport,
  DxFont,
  DxFormat,
  DxGrid,
  DxLabel,
  DxLegend,
  DxLoadingIndicator,
  DxSeriesTemplate,
  DxArgumentAxis,
  DxTick,
  DxTooltip,
  DxValueAxis,
  DxConnector,
  DxSize,
  DxScrollBar,
  DxZoomAndPan,
} from "devextreme-vue/chart";
import DxSelectBox from "devextreme-vue/select-box";
import {CancelToken} from "axios";
import {mapFields} from "vuex-map-fields";
import {generateColors, registerPalette} from "devextreme/viz/palette";
import {palettes} from "../../common/palettes";
import {locationAndStructureGroupBy} from "../../common/constants";
import complianceScoreChartDefinitions from "../../custom/reporting/complianceScoreChartDefinitions";
import chartParameters from "../../mixins/chart-parameters";

export default {
  mixins: [chartParameters],
  props: {...chartParameters.props},
  components: {
    DxChart,
    DxCommonAxisSettings,
    DxCommonSeriesSettings,
    DxExport,
    DxFont,
    DxFormat,
    DxGrid,
    DxLabel,
    DxLegend,
    DxLoadingIndicator,
    DxSelectBox,
    DxSeriesTemplate,
    DxTick,
    DxTooltip,
    DxValueAxis,
    DxArgumentAxis,
    DxConnector,
    DxSize,
    DxScrollBar,
    DxZoomAndPan,
  },
  computed: {
    ...mapFields("reportingFilters", ["filters"], "getFilter", "setFilter"),
    chart() {
      return this.$refs[this.chartRef].instance;
    },
    pageTitle() {
      return this.$t("assessments.assessment.title");
    },
    chartTitle() {
      return this.$t("reporting.charts.complianceScoreChartTitle", {
        groupByText: this.groupByText,
      });
    },
    colors() {
      const items = this.dataSource;
      // Ensure distinct values
      const series = [...new Set(items.map((x) => x.stack))];
      const stacks = [...new Set(items.map((x) => x.stack))];

      return Object.fromEntries(
        series.map((s, i) => {
          const palette = generateColors(
            palettes[i % palettes.length].name,
            stacks.length,
          );

          return [
            s,
            {
              seriesColor: palette[0],
              ...Object.fromEntries(
                stacks.map((stack, stackIndex) => [
                  stack,
                  palette[stackIndex % palette.length],
                ]),
              ),
            },
          ];
        }),
      );
    },
    groupByText() {
      return this.groupByDataSource.find(
        (x) => x.id === this.groupByValue,
        this,
      ).name;
    },
  },
  created() {
    palettes.forEach((x) => registerPalette(x.name, x.palette));
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      dataSource: [],
      loadCancelToken: null,
      initialRange: {startValue: 0, length: 30},
      chartRef: "complianceScoreChart",
      groupByDataSource: Object.entries(locationAndStructureGroupBy)
        .filter((x) => x[0] !== "LeadReviewer")
        .map(
          ([key, value]) => ({
            name: this.$t(`reporting.charts.groupBy${key}Title`),
            id: value,
          }),
          this,
        ),
      groupByValue: locationAndStructureGroupBy.Site,
      seriesDefinitions: complianceScoreChartDefinitions.map(
        (x) => ({...x, caption: this.$t(x.caption)}),
        this,
      ),
      color: {
        text: "black",
        axis: "#C4C4C4",
        grid: "#e8e8e8",
      },
    };
  },
  methods: {
    onPointClick(e) {
      // TODO:
      // Map location
      // if (!this.locations.includes(e.target.data.location)) {
      //   this.locations.push(e.target.data.location);
      // }
    },
    chartZoomIn() {
      this.chart.option("argumentAxis.visualRange", this.initialRange);
    },
    onExporting(e) {
      this.chart.option("title.text", this.chartTitle);
      this.chart.option("title.font.color", "black");
    },
    onExported(e) {
      this.chart.option("title.text", "");
    },
    customizePoint(e) {
      return {
        color: this.colors[e.data.stack][e.data.stack],
      };
    },
    customizeText(pointInfo) {
      if (this.pointLabelTextOn) {
        return (
          `${pointInfo.point.data.stack}: ` +
          (pointInfo.value * 100).toFixed(2) +
          `%`
        );
      } else {
        return (pointInfo.value * 100).toFixed(2) + `%`;
      }
    },
    customizeSeries(seriesName) {
      return {
        color: this.colors[seriesName][seriesName],
      };
    },
    countScore(accumulator, group) {
      return Object.entries(group || {}).reduce(
        (groupScoreCount, [statusName, statusScoreCount]) => {
          return this.seriesDefinitions.find((x) => x.dataField === statusName)
            ? groupScoreCount + statusScoreCount
            : groupScoreCount;
        },
        accumulator,
      );
    },
    async load() {
      this.chart.showLoadingIndicator();
      try {
        if (this.loadCancelToken) {
          this.loadCancelToken.cancel();
        }

        this.loadCancelToken = CancelToken.source();
        let response = await this.$http.post(
          "/reporting/compliancescorebylocation",
          {
            grouping: this.groupByValue,
            filter: this.filters,
          },
          {
            cancelToken: this.loadCancelToken.token,
          },
        );
        this.loadCancelToken = null;

        if (this.groupByValue == 22 && response.data.columns) {
          response.data.columns.map((x) => {
            if (x.label == null) {
              x.label = '""';
            }
          });
        }

        if (this.groupByValue == 23 && response.data.columns) {
          response.data.columns.map((x) => {
            if (x.label === "1") {
              x.label = this.$t("orders.reviewDetails.supplierPhasedOut");
            } else if (x.label === "2") {
              x.label = this.$t(
                "orders.reviewDetails.otherBizConsequenceApplied",
              );
            } else if (x.label === "3") {
              x.label = this.$t("orders.reviewDetails.noBizConsequenceApplied");
            } else {
              x.label = '""';
            }
          });
        }

        if (this.groupByValue == 24 && response.data.columns) {
          response.data.columns.map((x) => {
            if (x.label === "1") {
              x.label = this.$t("orders.reviewDetails.capProcess");
            } else if (x.label === "2") {
              x.label = this.$t("orders.reviewDetails.capProcessMinus90Days");
            } else if (x.label === "3") {
              x.label = this.$t("orders.reviewDetails.capProcessMinus180Days");
            } else if (x.label === "4") {
              x.label = this.$t(
                "orders.reviewDetails.capProcessGreaterOrEqual180Days",
              );
            } else {
              x.label = '""';
            }
          });
        }

        this.dataSource = response.data.columns
          .map((column) => {
            const subColumns = column.subColumns.map((x) => ({
              ...x,
              finalScorePercentage:
                x.finalScorePercentage - x.auditScorePercentage > 0
                  ? x.finalScorePercentage - x.auditScorePercentage
                  : 0,
              complianceScorePercentage: x.auditScorePercentage,
            }));
            return {
              ...column,
              subColumns: subColumns,
            };
          })
          .sort((a, b) => {
            return (
              b.subColumns.reduce(this.countScore, 0) -
              a.subColumns.reduce(this.countScore, 0)
            );
          })
          .reduce((acc, column) => {
            column.subColumns.forEach((subColumn) => {
              Object.entries(subColumn)
                .map(([key, value]) => {
                  let series = this.seriesDefinitions.find(
                    (x) => x.dataField === key,
                  );
                  return {
                    series: series,
                    value: value,
                  };
                })
                .filter((x) => x.series)
                .sort((a, b) => a.series.order - b.series.order)
                .forEach((x) => {
                  acc.push({
                    series: subColumn.label,
                    location: column.label,
                    stack: x.series.caption,
                    value: x.value / 100,
                  });
                });
            }, this);
            return acc;
          }, []);
        this.chart.hideLoadingIndicator();
        this.dataLoaded();
      } catch (err) {
        this.dataSource = [];
      }
    },
  },
  watch: {
    filters() {
      this.load();
    },
    groupByValue() {
      this.load();
    },
  },
};
</script>

<style lang="sass" scoped></style>
