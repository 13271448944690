<template>
  <div class="card-container">
    <div class="row">
      <div class="col-xl-3 action-left-container individual-box">
        <h2>
          <i class="fas fa-paperclip"></i>
          {{ title }}
          <span
            v-if="attachments && attachments.length"
            class="regular-indication"
            >{{ attachments.length }}</span
          >
        </h2>
        <p class="mb-4">{{ headerDescription }}</p>

        <label>
          <DxTooltip
            :visible.sync="isToolTipVisible"
            :close-on-outside-click="false"
            :target="toolTip"
            position="top"
          >
            {{ toolTipDescription }}
          </DxTooltip>
        </label>
        <template v-if="showButton">
          <div
            :id="toolTipId"
            @mouseenter="attachmentsToolTip = !attachmentsToolTip"
            @mouseleave="attachmentsToolTip = !attachmentsToolTip"
          >
            <dx-button
              :text="$t('assessments.assessment.addAttachment')"
              name="addAttachment"
              type="normal"
              class="btn btn-medium"
              stylingMode="container"
              icon="plus"
              @click="onAddAttachmentClick"
            />
          </div>
        </template>
      </div>

      <div class="col-xl-9">
        <p class="gdpr-disc" v-if="showGdprDisclaimer">
          <i class="fas fa-exclamation-circle mr-2"></i>
          {{ $t("legalNotices.gdprDisclaimer") }}
        </p>
        <div
          v-if="!attachments || (attachments && !attachments.length)"
          style="text-align: center"
        >
          <div class="no-records-fallback">
            <i class="fas fa-paperclip"></i>
            <span class="darker"
              >{{ $t("assessments.assessment.currentlyNoAttachments") }}.</span
            >
          </div>
        </div>
        <div style="margin-bottom: -15px">
          <div
            class="attachment-container"
            v-for="item in attachments"
            :key="item"
          >
            <template
              v-if="item.type !== 'Question' && item.typeDetail !== undefined"
            >
              <div class="attachment-header">
                <div class="title">
                  {{
                    `${$t(`assessments.assessment.${camelCase(item.type)}`)}: ${
                      item.typeDetail.title
                    }`
                  }}
                </div>
              </div>
            </template>
            <template v-if="item.type === 'Question'">
              <div class="attachment-header">
                <div class="title">
                  {{ $t("assessments.assessment.question") }}
                </div>
              </div>
            </template>
            <template v-if="item.isImage">
              <template>
                <div @click="openModal(item)">
                  <attachment-preview :url="item.preview.link" />
                </div>
              </template>

              <div class="attachment-info">
                <div class="row">
                  <div class="col-12 attachment-name">
                    <div class="file-name" @click="downloadAttachment(item)">
                      {{ item.attachment.filename }}
                    </div>
                    <template v-if="showDeleteButton">
                      <span
                        class="delete-icon"
                        @click="deleteAttachment(item.id)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <template>
                <div @click="downloadAttachment(item)" class="thumbnail"></div>
              </template>
              <div class="attachment-info">
                <div class="row">
                  <div class="col-12 attachment-name">
                    <div class="file-name" @click="downloadAttachment(item)">
                      {{ item.attachment.filename }}
                    </div>
                    <template v-if="showDeleteButton">
                      <span
                        class="delete-icon"
                        @click="deleteAttachment(item.id)"
                      >
                        <i class="far fa-trash-alt"></i>
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <DxPopup
        v-if="modalVisible"
        id="attachment-popup"
        @onHidden="modalVisible = false"
        height="auto"
        maxHeight="80vh"
        :fullScreen="false"
        position="center"
        :visible.sync="modalVisible"
        :showCloseButton="true"
      >
        <DxScrollView>
          <big-attachment-preview
            :modalData.sync="modalData"
            :url.sync="modalData.attachment.link"
            @downloadAttachment="downloadAttachment"
          />
        </DxScrollView>
      </DxPopup>
    </div>
  </div>
</template>

<script>
import {DxButton, DxTooltip, DxPopup, DxScrollView} from "devextreme-vue";
import {toCamelCase} from "../../common/helperFunctions";
import {confirm} from "devextreme/ui/dialog";

const {VUE_APP_API_HOSTNAME} = process.env;
import AttachmentPreview from "../../components/shared/attachment-preview";
import BigAttachmentPreview from "../../components/shared/big-attachment-preview";

export default {
  props: {
    attachments: {
      Type: Array,
    },
    showButton: {
      Type: Boolean,
      default: true,
      required: false,
    },
    showDeleteButton: {
      Type: Boolean,
      default: false,
      required: false,
    },
    title: {
      Type: String,
    },
    headerDescription: {
      Type: String,
      required: false,
      default: function () {
        return this.$t("shared.attachmentsDefaultDescription");
      },
    },
    toolTipDescription: {
      Type: String,
      required: false,
    },
    hasToolTip: {
      Type: Boolean,
      required: false,
      default: false,
    },
    showGdprDisclaimer: {
      Type: Boolean,
      default: true,
    },
    toolTipId: {
      Type: String,
      required: false,
      default: "divAttachments",
    },
  },
  components: {
    DxButton,
    DxTooltip,
    DxPopup,
    DxScrollView,
    BigAttachmentPreview,
    AttachmentPreview,
  },
  data() {
    return {
      attachmentsToolTip: false,
      modalData: null,
      modalVisible: false,
    };
  },
  methods: {
    openModal(data) {
      this.modalData = data;
      this.modalVisible = true;
    },
    camelCase: toCamelCase,
    deleteAttachment(id) {
      let result = confirm(
        `<i>${this.$t("assessments.assessment.deleteAttachmentQuestion")}</i>`,
        this.$t("assessments.assessment.deleteAttachmentPopup"),
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.$emit("delete-attachment", id);
        }
      });
    },

    downloadAttachment(item) {
      this.$emit("download-attachment", item);
    },
    onAddAttachmentClick() {
      this.$emit("add-attachment");
    },
  },
  computed: {
    baseUrl() {
      return VUE_APP_API_HOSTNAME;
    },
    isToolTipVisible() {
      return this.attachmentsToolTip && this.hasToolTip;
    },
    toolTip() {
      return "#" + this.toolTipId;
    },
  },
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base.scss";

.darker {
  color: #1e1e1e !important;
}
</style>
