<template>
  <div>
    <page-heading :pageTitle="pageTitle" :breadCrumbs="breadCrumbs" />
    <div
      class="fixed-action-container"
      @click="newOrganisationClick"
      v-if="$can('create', $subjects.organisation.subject)"
    >
      <i class="fas fa-plus"></i>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <dx-button
          :visible="$can('create', $subjects.organisation.subject)"
          @click="newOrganisationClick"
          type="normal"
          class="schedule-visit hidden-sm hidden-xs"
          stylingMode="contained"
          icon="plus"
          :text="
            this.$t('organisations.organisationsList.createNewOrganisation')
          "
        />
      </div>
    </div>
    <div class="card-container">
      <div class="row" style="margin-bottom: 20px">
        <div class="col-xl-3">
          <h2>
            <i class="fas fa-globe-europe"></i>
            {{ pageTitle }}
          </h2>
          <div v-if="isInRole(roleTypes.admin.role)">
            <dx-button
              @click="toggleSitesActiveState"
              type="normal"
              class="toggle-user-state hidden-sm hidden-xs"
              stylingMode="outlined"
              icon="globe"
              :text="formatText"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="content-block">
            <dx-data-grid
              class="dx-card thin-column-chooser"
              :data-source="dataSource"
              :show-borders="false"
              key-expr="id"
              :ref="dataGridRefName"
              :focused-row-index="0"
              :focused-row-enabled="false"
              :column-hiding-enabled="false"
              :selection="{mode: 'single'}"
              :hover-state-enabled="true"
              :columns="colDefs"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-min-width="150"
              columnResizingMode="widget"
            >
              <dx-header-filter :visible="true" :allowSearch="true" />
              <dx-column-chooser
                :enabled="true"
                :allowSearch="true"
                :height="500"
                mode="dragAndDrop"
              />
              <dx-column-fixing :enabled="true" />
              <dx-remote-operations
                :filtering="false"
                :paging="false"
                :sorting="false"
                :summary="false"
                :grouping="false"
                :group-paging="false"
              />
              <dx-state-storing
                :enabled="true"
                type="localStorage"
                storage-key="storageOrderList"
                :saving-timeout="savingTimeout"
              />

              <template #clear-filter-cell-template>
                <div>
                  <div class="cell-button-header" @click="switchToAllTab">
                    <p>
                      <i class="fas fa-sync"></i>
                      <span>{{ $t("shared.clearFilters") }}</span>
                    </p>
                  </div>
                </div>
              </template>

              <template #action-buttons-cell-template="{data}">
                <div style="text-align: center">
                  <span
                    class="table-button"
                    v-show="$can('read', $subjects.organisation.subject)"
                    @click="
                      $router.push({
                        name: 'supplier-details',
                        params: {id: data.data.id},
                      })
                    "
                    :title="
                      $t('organisations.organisationsList.editOrganisation')
                    "
                  >
                    <i class="far fa-edit"></i>
                  </span>
                </div>
              </template>

              <dx-paging :page-size="10" />
              <dx-pager :show-page-size-selector="true" :show-info="true" />
              <dx-filter-row :visible="true" />
              <dx-export :enabled="true" />
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import listColDefs from "../custom/organisation/organisationsListColDefs";
import {mapState} from "vuex";
import pageHeading from "../components/page-heading";
import {DxButton} from "devextreme-vue/button";
import {isNotEmpty} from "../common/helperFunctions";
import $axios from "../utils/axios-instance";
import roleTypes from "../config/roles";
import {statusOptions} from "../common/constants";

import DxDataGrid, {
  DxFilterRow,
  DxPager,
  DxPaging,
  DxRemoteOperations,
  DxHeaderFilter,
  DxStateStoring,
  DxColumnChooser,
  DxColumnFixing,
  DxExport,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxHeaderFilter,
    DxStateStoring,
    DxColumnChooser,
    DxColumnFixing,
    pageHeading,
    DxButton,
    DxExport,
    DxRemoteOperations,
  },
  computed: {
    ...mapState("auth", ["user"]),
    pageTitle() {
      return this.$t("organisations.organisationsList.title");
    },
    breadCrumbs() {
      return [this.$t("organisations.organisationsList.subTitle")];
    },
    colDefs() {
      let colDefs = JSON.parse(JSON.stringify(this.colDefsTranslatedLookups));
      let chooserCols = colDefs
        .filter((x) => x.visible === false)
        .sort(function (a, b) {
          var nameA = a.dataField.toUpperCase();
          var nameB = b.dataField.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      let visibleCols = colDefs.filter((x) => x.visible === true);
      let sortedColDefs = visibleCols.concat(chooserCols);

      return sortedColDefs.map((x) =>
        x.caption
          ? {
              ...x,
              caption: this.$t(x.caption),
            }
          : x,
      );
    },
    colDefsTranslatedLookups() {
      let colDefs = JSON.parse(JSON.stringify(listColDefs));
      colDefs = this.isInRole(roleTypes.reviewParticipant.role)
        ? colDefs.filter((x) => x.dataField !== "risk")
        : colDefs;
      colDefs.forEach((element) => {
        if (element.lookup) {
          element.lookup.dataSource.store.data.forEach((x) => {
            if (x.name) {
              x.name = this.$t(x.name);
            }
          });
        }
      });
      return colDefs;
    },
    formatText() {
      let buttonText =
        this.organisationsStatus === statusOptions.Active
          ? this.$t("organisations.organisationsList.showArchivedSites")
          : this.$t("organisations.organisationsList.showActiveSites");

      return buttonText;
    },
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.load,
        }),
        pageSize: 25,
        sort: {mode: "multiple"},
      }),
      clearedFilter: false,
      dataGridRefName: "dataGrid",
      organisationsStatus: statusOptions.Active,
      roleTypes: roleTypes,
      savingTimeout: 0,
    };
  },
  methods: {
    async load(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "sort",
        "requireTotalCount",
        "requireGroupCount",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });

      params = params.slice(0, -1);
      const response = await $axios.get(
        `/organisations/orgsList/${this.organisationsStatus}/${params}`,
      );
      const json = await JSON.parse(JSON.stringify(response));
      return {
        data: json.data.data,
        totalCount: json.data.totalCount,
        summary: json.data.summary,
        groupCount: json.data.groupCount,
      };
    },
    newOrganisationClick() {
      this.$router.push({
        name: "supplier-details",
      });
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    toggleSitesActiveState() {
      this.organisationsStatus =
        this.organisationsStatus === statusOptions.Active
          ? statusOptions.Archived
          : statusOptions.Active;

      this.dataSource.reload();
    },
    switchToAllTab() {
      const dataGrid = this.$refs[this.dataGridRefName].instance;
      dataGrid.clearFilter();
    },
  },
};
</script>
