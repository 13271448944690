import AssessmentList from "./views/assessment-list";
import FindingsList from "./views/findings-list";
import FindingResolution from "./views/finding-resolution";
import Reporting from "./views/reporting";
import PerformanceManagementDashboard from "./views/dashboards/performance-management-dashboard";
import FindingsDashboard from "./views/dashboards/findings-dashboard";
import RetailerDashboard from "./views/dashboards/retailer-dashboard";
import ScheduleAssessment from "./views/schedule-assessment";
import Assessment from "./views/assessment";
import OrganisationDetail from "./views/organisation-details";
import OrganisationList from "./views/organisation-list";
import ProfileSettings from "./views/profile-settings";
import Vue from "vue";
import Router from "vue-router";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import loginLayout from "./layouts/login";
import {isNotEmpty} from "./common/helperFunctions";
import prototypeLayout from "./layouts/prototype";
import UsersList from "./views/users-list";
import UserDetails from "./views/user-details";
import LegalNotices from "./views/legal-notices/legal-notices-landing";
import Cookies from "./views/legal-notices/cookies";

import TermsOfUse from "./views/legal-notices/terms-of-use";
import PasswordResetForm from "./views/password-reset-form";
import WsfedCallback from "./views/wsfed-callback";
import LandingPageView from "./components/landingPage/landing-page-view";
import subjects from "./config/subjects";
import Orders from "./components/orders/orders";
import OrdersList from "./views/orders-list";

const {
  home,
  organisation,
  order,
  userManagement,
  schedule,
  assessment,
  findingResolution,
  dashboard,
  legal,
} = subjects;

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/prototype",
      name: "prototype",
      meta: {requiresAuth: false},
      components: {
        layout: prototypeLayout,
        content: LandingPageView,
      },
    },
    {
      path: "/home",
      name: "home",
      meta: {requiresAuth: true, subject: home.subject},
      components: {
        layout: defaultLayout,
        content: LandingPageView,
      },
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {requiresAuth: false},
      components: {
        layout: loginLayout,
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        content: () =>
          import(/* webpackChunkName: "login" */ "./views/login-form"),
      },
    },
    {
      path: "/password-reset-form",
      name: "password-reset-form",
      meta: {requiresAuth: false},
      components: {
        layout: loginLayout,
        content: PasswordResetForm,
      },
    },
    {
      path: "/passwordreset",
      redirect: (to) => ({
        name: "password-reset-form",
        query: {token: to.query.token, username: to.query.username},
      }),
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "*",
      redirect: "/home",
    },
    {
      path: "/assessment-list/schedule-assessment",
      name: "schedule-assessment",
      meta: {requiresAuth: true, subject: schedule.subject},
      components: {
        layout: defaultLayout,
        content: ScheduleAssessment,
      },
    },
    {
      path: "/assessment-list/edit-assessment/:id",
      name: "edit-assessment",
      meta: {requiresAuth: true, subject: schedule.subject},
      components: {
        layout: defaultLayout,
        content: ScheduleAssessment,
      },
    },
    {
      path: "/assessment-list",
      name: "assessment-list",
      meta: {requiresAuth: true, subject: assessment.subject},
      components: {
        layout: defaultLayout,
        content: AssessmentList,
      },
    },
    {
      path: "/assessments/:id",
      name: "assessment",
      meta: {requiresAuth: true, subject: assessment.subject},
      components: {
        layout: defaultLayout,
        content: Assessment,
      },
    },
    {
      path: "/users-list",
      name: "users-list",
      meta: {requiresAuth: true, subject: userManagement.subject},
      components: {
        layout: defaultLayout,
        content: UsersList,
      },
    },
    {
      path: "/user-details/:id?",
      name: "user-details",
      meta: {requiresAuth: true, subject: userManagement.subject},
      components: {
        layout: defaultLayout,
        content: UserDetails,
      },
    },
    {
      path: "/corrective-actions-list",
      name: "findings-list",
      meta: {requiresAuth: true, subject: findingResolution.subject},
      components: {
        layout: defaultLayout,
        content: FindingsList,
      },
    },
    {
      path: "/supplier-list",
      name: "supplier-list",
      meta: {requiresAuth: true, subject: organisation.subject},
      components: {
        layout: defaultLayout,
        content: OrganisationList,
      },
    },
    {
      path: "/supplier-details/:id?",
      name: "supplier-details",
      meta: {requiresAuth: true, subject: organisation.subject},
      components: {
        layout: defaultLayout,
        content: OrganisationDetail,
      },
    },
    {
      path: "/corrective-action-resolution/:id",
      name: "finding",
      meta: {requiresAuth: true, subject: findingResolution.subject},
      components: {
        layout: defaultLayout,
        content: FindingResolution,
      },
    },
    {
      path: "/reporting",
      name: "reporting",
      meta: {requiresAuth: true, subject: dashboard.subject},
      components: {
        layout: defaultLayout,
        content: Reporting,
      },
    },
    {
      path: "/reporting/performance-management-dashboard",
      name: "performance-management-dashboard",
      meta: {requiresAuth: true, subject: dashboard.subject},
      components: {
        layout: defaultLayout,
        content: PerformanceManagementDashboard,
      },
    },
    {
      path: "/reporting/cases-dashboard",
      name: "findings-dashboard",
      meta: {requiresAuth: true, subject: dashboard.subject},
      components: {
        layout: defaultLayout,
        content: FindingsDashboard,
      },
    },
    {
      path: "/reporting/retailer-dashboard",
      name: "retailer-dashboard",
      meta: {requiresAuth: true, subject: dashboard.subject},
      components: {
        layout: defaultLayout,
        content: RetailerDashboard,
      },
    },
    {
      path: "/profile-settings",
      name: "profile-settings",
      meta: {requiresAuth: true, subject: home.subject},
      components: {
        layout: defaultLayout,
        content: ProfileSettings,
      },
    },
    {
      path: "/legal-notices",
      name: "legal-notices",
      meta: {requiresAuth: true, subject: legal.subject},
      components: {
        layout: defaultLayout,
        content: LegalNotices,
      },
    },
    {
      path: "/cookies",
      name: "cookies",
      meta: {requiresAuth: false},
      components: {
        layout: defaultLayout,
        content: Cookies,
      },
    },
    {
      path: "/terms-of-use",
      name: "terms-of-use",
      meta: {requiresAuth: false},
      components: {
        layout: defaultLayout,
        content: TermsOfUse,
      },
    },
    {
      path: "/wsfed-callback",
      name: "wsfed-callback",
      meta: {requiresAuth: false},
      components: {
        layout: prototypeLayout,
        content: WsfedCallback,
      },
    },
    {
      path: "/orders-list/order",
      name: "order",
      meta: {requiresAuth: true, subject: order.subject},
      components: {
        layout: defaultLayout,
        content: Orders,
      },
    },
    {
      path: "/orders-list/edit-order/:id",
      name: "edit-order",
      meta: {requiresAuth: true, subject: order.subject},
      components: {
        layout: defaultLayout,
        content: Orders,
      },
    },
    {
      path: "/orders-list",
      name: "orders-list",
      meta: {requiresAuth: true, subject: order.subject},
      components: {
        layout: defaultLayout,
        content: OrdersList,
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isNotEmpty(localStorage.getItem("token"))) {
      if (localStorage.getItem("agreedTerms") === "true") {
        next();
      } else {
        next({
          name: "terms-of-use",
        });
      }
    } else {
      next({
        name: "login-form",
        query: {redirect: to.fullPath},
      });
    }
  } else {
    // no auth required. We can safely navigate to the page.
    next();
  }
});

export default router;
