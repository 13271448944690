<template>
  <div class="row">
    <div class="col" ref="mainContent" style="padding-bottom: 100px">
      <div class="with-side-indent">
        <page-heading :key="breadCrumbs.length" :breadCrumbs="breadCrumbs" />
      </div>
      <div class="tabbed-details-menu">
        <div
          class="assessment-subnav-tabs"
          style="margin-bottom: 5px; margin-left: 5px"
        >
          <div
            :class="{'tab-element-selected': isAssessmentSectionVisible}"
            @click="showAssessmentSection()"
          >
            {{ $t("assessments.assessment.detailsMenuAssessmentLabel") }}
          </div>

          <div
            :class="{'tab-element-selected': isAuditLogsSectionVisible}"
            v-if="$can('read', $subjects.assessment.subject)"
            @click="showAuditLogTable()"
          >
            <i class="fas fa-history" />
          </div>
        </div>
      </div>
      <div class="main-assessment-section" v-if="isAuditLogsSectionVisible">
        <div class="card-container">
          <h2>
            <i class="fas fa-history" />
            {{ $t("assessments.assessment.historyOfChanges") }}
          </h2>
          <audit-log
            :elementId="this.$route.params.id"
            dataPath="audit/assessments/"
          ></audit-log>
        </div>
      </div>

      <div class="main-assessment-section" v-show="!isAuditLogsSectionVisible">
        <div v-if="assessmentDetails">
          <div class="assessment-status-container">
            <label
              class="table-status-label"
              :class="statusClass"
              style="padding: 6px 15px"
              >{{ formatStatusCode(assessmentDetails.statusCode) }}
            </label>
          </div>

          <div v-if="assessmentDetails.latestCommentOfTypeReason.comment">
            <div class="page-indicator warning">
              <div class="indicator-icon">
                <i class="fas fa-undo"></i>
              </div>
              <h3>
                {{ $t("assessments.assessment.reworkCommentsBy") }}
                {{ assessmentDetails.latestCommentOfTypeReason.fullname }}
              </h3>
              <p>{{ assessmentDetails.latestCommentOfTypeReason.comment }}</p>
            </div>
          </div>

          <div
            class="pre-answered-response red"
            v-if="
              (assessmentDetails.statusCode === 'InProgress' ||
                assessmentDetails.statusCode === 'NotStarted') &&
              readOnly
            "
          >
            <h3>
              <i class="far fa-times-circle"></i>
              {{ $t("assessments.assessment.readOnlyTitle") }}
            </h3>

            <!-- <div
              v-if="
                assessmentDetails.reviewMode === reviewModes.ComplianceReview
              "
              class="ml-2 mt-2 mb-2"
            >
              -
              {{ $t("assessments.assessment.readOnlyReasonComplianceReview") }}
            </div>
            <div v-else class="ml-2">
              - {{ $t("assessments.assessment.readOnlyReasonSelfAssessment") }}
            </div>
            <div class="ml-2">
              - {{ $t("assessments.assessment.readOnlyReasonNotStarted") }}
            </div> -->
          </div>

          <div v-if="assessmentDetails.submitted">
            <div class="page-indicator success">
              <div
                class="c-score"
                style="right: 160px"
                v-if="assessmentDetails.complianceScore"
              >
                <label>{{
                  $t("assessments.assessment.complianceScore")
                }}</label>
                <span>
                  {{ `${Math.round(assessmentDetails.complianceScore)}%` }}
                </span>
              </div>
              <div
                class="c-score"
                v-if="
                  !isInRole(roleTypes.reviewParticipant.role) &&
                  assessmentDetails.riskRating
                "
              >
                <label>{{ $t("assessments.assessment.riskRating") }}</label>
                <span>
                  {{ assessmentDetails.riskRating }}
                </span>
              </div>
              <div class="indicator-icon">
                <i class="fas fa-check"></i>
              </div>

              <h3>
                {{ $t("assessments.assessment.questionnaireHasBeenSubmitted") }}
              </h3>

              <div class="who">
                <div
                  v-if="
                    isNotEmpty(
                      assessmentDetails.submitted.submittedBy.pictureUrl,
                    )
                  "
                  class="profile-picture"
                  :style="{
                    'background-image':
                      'url(' +
                      assessmentDetails.submitted.submittedBy.pictureUrl +
                      ')',
                  }"
                ></div>
                <div
                  v-else
                  class="user-initials"
                  style="padding-top: 8px; margin-right: 8px"
                >
                  {{ getUserInitials(assessmentDetails.submitted.submittedBy) }}
                </div>

                <span class="hidden-md hidden-lg hidden-xl">
                  {{
                    $t(
                      `assessments.assessmentList.${camelCase(
                        assessmentDetails.statusCode,
                      )}`,
                    )
                  }}
                  -
                </span>

                {{ assessmentDetails.submitted.submittedBy }}

                <span class="date">
                  {{ $t("assessments.assessment.on") }}
                  {{
                    localeDatetimeFormat(
                      new Date(assessmentDetails.submitted.submittedOn),
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      },
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <template v-if="isNotEmpty(question)">
          <div class="card-container">
            <div class="row">
              <div class="col-xl action-left-container-no-border">
                <assessment-question-header
                  :question="question"
                  :questionCount="assessmentDetails.questionCount"
                />

                <div class="question">
                  <div>
                    <div style="margin-bottom: 15px">
                      <div
                        v-if="question.number"
                        class="question-marker hidden-xs hidden-sm"
                      >
                        {{ `${question.number}` }}
                      </div>
                      <span :class="{required: question.isRequired}">
                        {{ question.title }}
                      </span>
                    </div>
                    <template v-if="answer !== null">
                      <template v-if="question.isPrePopulated">
                        <div class="pre-answered-response">
                          <h3>
                            <i class="far fa-check-circle"></i>
                            {{
                              $t("assessments.assessment.preAssessedResponse")
                            }}
                          </h3>
                          <p>
                            {{ $t("assessments.assessment.answerPreAssessed") }}
                          </p>
                        </div>
                      </template>
                      <assessment-answer-container
                        :key="answerComponentKey"
                        :question="question"
                        :answerOptions="answerOptions"
                        :questionId="currentQuestionId"
                        :preselectedValue="answerValue"
                        @set-answer="setSelectedAnswer"
                        :disabled="
                          readOnly ||
                          !(
                            assessmentDetails.statusCode === 'InProgress' ||
                            assessmentDetails.statusCode === 'NotStarted'
                          )
                        "
                      />
                    </template>
                  </div>
                </div>
              </div>

              <template
                v-if="
                  !(currentUser.roles[0] === roles.reviewParticipant.role) &&
                  (question.referenceLinks || question.helpNote)
                "
              >
                <div class="col-xl-3 help-holder">
                  <assessment-additional-information
                    :refLinks="question.referenceLinks"
                    :helpNote="question.helpNote"
                  />
                </div>
              </template>
            </div>
          </div>

          <div v-if="!isInRole(roleTypes.reviewParticipant.role)">
            <internal-notes
              :assessmentId="this.$route.params.id"
              :questionId="this.currentQuestionId"
              :readOnly="
                readOnly ||
                !(
                  assessmentDetails.statusCode === 'InProgress' ||
                  assessmentDetails.statusCode === 'NotStarted'
                )
              "
            />
          </div>

          <div v-if="showFindings" class="card-container">
            <findings-list
              :findings="findings"
              @add-finding-click="addFindingClick($event)"
              @edit-finding-click="editFindingClick($event)"
              @delete-finding-click="deleteFindingClick($event)"
              :showButton="
                !readOnly &&
                (assessmentDetails.statusCode === 'InProgress' ||
                  assessmentDetails.statusCode === 'NotStarted')
              "
              :readOnly="
                readOnly ||
                !(
                  assessmentDetails.statusCode === 'InProgress' ||
                  assessmentDetails.statusCode === 'NotStarted'
                )
              "
            />
          </div>

          <div v-if="showComments">
            <comments
              :comments="comments"
              @add-comment-click="toggleSlideOut($event, 'comments')"
              @edit-comment-click="editCommentClick($event)"
              @delete-comment-click="deleteCommentClick($event)"
              @save-comment-click="editComment($event)"
              :isSelfContained="false"
              :showButton="
                isInRole(roleTypes.admin.role) ||
                (!readOnly &&
                  (assessmentDetails.statusCode === 'InProgress' ||
                    assessmentDetails.statusCode === 'NotStarted'))
              "
              :showEditButtons="
                isInRole(roleTypes.admin.role) ||
                (!readOnly &&
                  (assessmentDetails.statusCode === 'InProgress' ||
                    assessmentDetails.statusCode === 'NotStarted'))
              "
              :headerDescription="
                this.$t('assessments.assessment.addCommentParagraph')
              "
              :toolTipDescription="this.$t('tooltips.assessment.comments')"
              :hasToolTip="true"
              :readOnly="
                isInRole(roleTypes.reviewParticipant.role) &&
                !(
                  assessmentDetails.reviewMode === reviewModes.SelfAssessment &&
                  (assessmentDetails.statusCode === 'InProgress' ||
                    assessmentDetails.statusCode === 'NotStarted')
                )
              "
            />
          </div>
          <div v-if="showAttachments">
            <attachment-card-list
              :attachments="evidence"
              @delete-attachment="deleteEvidence($event)"
              @add-attachment="toggleSlideOut($event, 'attachments')"
              @download-attachment="downloadAttachment($event)"
              :showButton="
                !readOnly &&
                (assessmentDetails.statusCode === 'InProgress' ||
                  assessmentDetails.statusCode === 'NotStarted') &&
                question.isAttachmentAllowed
              "
              :showDeleteButton="
                !readOnly &&
                (assessmentDetails.statusCode === 'InProgress' ||
                  assessmentDetails.statusCode === 'NotStarted')
              "
              :headerDescription="
                this.$t('assessments.assessment.attachmentsDescription')
              "
              :title="this.$t('assessments.assessment.attachments')"
              :toolTipDescription="this.$t('tooltips.assessment.attachments')"
              :hasToolTip="true"
            />
          </div>

          <div class="fixed-bottom-container" :style="matchingWidthStyles">
            <div class="row">
              <div class="col">
                <dx-button
                  :text="this.$t('assessments.assessment.previous')"
                  name="previous"
                  class="hidden-xs hidden-sm btn btn-large mr-4"
                  style="min-width: 130px"
                  icon="chevronleft"
                  :visible="isNotEmpty(question.prevQuestionId)"
                  @click="prevNextButtonClick($event, 'previous')"
                  type="normal"
                />
                <dx-button
                  :text="this.$t('assessments.assessment.next')"
                  :visible="isNotEmpty(question.nextQuestionId)"
                  name="next"
                  style="min-width: 130px"
                  class="hidden-xs hidden-sm btn btn-large icon-right"
                  icon="chevronright"
                  @click="prevNextButtonClick($event, 'next')"
                  type="default"
                />
                <div class="mobile-action-buttons">
                  <div
                    class="prev-container"
                    v-if="isNotEmpty(question.prevQuestionId)"
                    @click="prevNextButtonClick($event, 'previous')"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </div>
                  <div
                    class="next-container"
                    :visible="isNotEmpty(question.nextQuestionId)"
                    @click="prevNextButtonClick($event, 'next')"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>

              <div
                class="col-auto"
                @mouseenter="toggleSubmitTooltipVisibility"
                @mouseleave="toggleSubmitTooltipVisibility"
              >
                <dx-button
                  id="submitButton"
                  :text="this.$t('assessments.assessment.submit')"
                  name="submit"
                  type="default"
                  style="min-width: 120px"
                  class="btn btn-large hidden-xs hidden-sm"
                  icon="check"
                  @click="submitButtonClick"
                  :disabled="
                    (!this.allFindingsAdded() || !this.assessmentComplete) &&
                    !canSubmit
                  "
                  :visible="
                    !readOnly &&
                    !(
                      assessmentDetails.statusCode === 'Complete' ||
                      assessmentDetails.statusCode === 'Submitted'
                    )
                  "
                />
                <div
                  class="mobile-action-buttons right"
                  v-if="
                    !readOnly &&
                    !(
                      this.assessmentDetails.statusCode === 'Complete' ||
                      this.assessmentDetails.statusCode === 'Submitted'
                    )
                  "
                >
                  <div class="submit-container" @click="submitButtonClick">
                    <i class="fas fa-check"></i>
                  </div>
                </div>

                <dx-tooltip
                  v-if="!this.allFindingsAdded() || !this.assessmentComplete"
                  :visible.sync="submitTooltipVisible"
                  :close-on-outside-click="false"
                  target="#submitButton"
                  position="top"
                >
                  {{ $t("assessments.assessment.submitToolTip") }}
                </dx-tooltip>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col side-panel">
      <div v-if="menuData && assessmentDetails && currentQuestionId">
        <assessment-navigation
          :data="menuData"
          :assessmentDetails="assessmentDetails"
          :currentQuestionId="currentQuestionId"
          @selection-changed="assessmentNavigationSelectionChanged($event)"
        />
      </div>
    </div>

    <dx-popup
      :visible.sync="submitPopupVisible"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      class="popUpLargeTitle"
      :max-width="'100%'"
      :width="'700px'"
      :height="'fit-content'"
      :title="this.$t('assessments.assessment.confirmSubmitAssessment')"
    >
      <div style="max-height: 300px; overflow-y: scroll">
        <div>
          <p style="font-weight: bold; margin-top: 0px">
            {{ $t("assessments.assessment.assessmentSubmissionMessage") }}
          </p>
        </div>
        <div v-show="questionsAnsweredNotOkWithoutCorrectiveAction.length > 0">
          <p>
            <i class="fas fa-exclamation-circle mr-2"></i
            >{{
              $t(
                "assessments.assessment.questionResponsesNotOkWithoutCorrectiveActions",
              )
            }}
          </p>
          <ul>
            <li
              v-for="question in questionsAnsweredNotOkWithoutCorrectiveAction"
              :key="question"
            >
              {{ question }}
            </li>
          </ul>
        </div>
      </div>
      <p>
        <dx-button
          @click="submitConfirmClick"
          width="150px"
          :text="this.$t('assessments.assessment.submit')"
          type="success"
          :visible="canSubmit"
        ></dx-button
        >&nbsp;
        <dx-button
          @click="submitButtonClose"
          width="150px"
          :text="this.$t('assessments.assessment.close')"
          type="primary"
        ></dx-button>
      </p>
    </dx-popup>

    <div class="slide-out" ref="slideout-ref" :class="{open: isSlideOutOpen}">
      <div class="close" @click="closeSlideOut()">
        <i class="fas fa-times"></i>
      </div>
      <template v-if="slideOutType === 'attachments'">
        <div class="content-block">
          <h2 class="content-title">
            <i class="fas fa-link"></i>
            {{ $t("assessments.assessment.attachments") }}
          </h2>
        </div>
        <attachment-upload
          @upload-successful="onUploadSuccess"
          @upload-started="onUploadStarted"
          @upload-error="onUploadError"
          uploadMode="instantly"
          :uploadUrl="attachmentUploadUrl"
        ></attachment-upload>
      </template>
      <template v-else-if="slideOutType === 'comments'">
        <comments-add
          @submit-comment-click="submitComment($event)"
        ></comments-add>
      </template>
      <template v-else-if="slideOutType === 'commentsEdit'">
        <comments-edit
          :comment="comment"
          @save-comment-click="editComment($event)"
        ></comments-edit>
      </template>
      <template v-else-if="slideOutType === 'assessmentFindings'">
        <findings-add
          @cancel-finding-click="closeSlideOut()"
          :organisationId="assessmentDetails.organisation.organisationId"
          :assessmentDetails="this.assessmentDetails"
          :assessmentId="this.$route.params.id"
          :questionId="currentQuestionId"
          @on-add-finding-success="onAddFindingsSuccess($event)"
          @on-update-finding-success="onUpdateFindingsSuccess($event)"
          :readOnly="readOnly || !!assessmentDetails.submitted"
        ></findings-add>
      </template>
    </div>
  </div>
</template>

<script>
import pageHeading from "../components/page-heading";
import {DxButton, DxPopup, DxTooltip} from "devextreme-vue";
import AssessmentQuestionHeader from "../components/assessment/assessment-question-header";
import AssessmentAnswerContainer from "../components/assessment/assessment-answer-container.vue";
import AssessmentAdditionalInformation from "../components/assessment/assessment-additional-information";
import AttachmentCardList from "../components/shared/attachment-card-list";
import FindingsList from "../components/shared/findings-list";
import Comments from "../components/shared/comments";
import roles from "../config/roles";
import {
  isNotEmpty,
  toCamelCase,
  isUnsavedComment,
} from "../common/helperFunctions";
import {
  questionType,
  reviewModes,
  answersAllowedForComments,
  answersAllowedForFindings,
} from "../common/constants";
import AssessmentNavigation from "../components/assessment/assessment-navigation";
import AttachmentUpload from "../components/shared/attachment-upload";
import CommentsAdd from "../components/shared/comments-add";
import CommentsEdit from "../components/shared/comments-edit";
import FindingsAdd from "../components/shared/findings-add";
import AuditLog from "../components/audit/audit-log";
import notify from "devextreme/ui/notify";
import {mapActions, mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import {confirm} from "devextreme/ui/dialog";
import localeDatetimeFormat from "../mixins/locale-datetime-format";
import downloadAuthAttachment from "../mixins/download-auth-attachment";
import internalNotes from "../components/shared/internal-notes.vue";
import roleTypes from "../config/roles";

const {VUE_APP_API_HOSTNAME} = process.env;

export default {
  components: {
    pageHeading,
    DxButton,
    AssessmentQuestionHeader,
    AssessmentAdditionalInformation,
    AttachmentCardList,
    Comments,
    AssessmentNavigation,
    AttachmentUpload,
    CommentsAdd,
    CommentsEdit,
    FindingsAdd,
    DxPopup,
    DxTooltip,
    FindingsList,
    AuditLog,
    AssessmentAnswerContainer,
    internalNotes,
  },
  mixins: [localeDatetimeFormat, downloadAuthAttachment],
  created() {
    this.$store.dispatch("auth/setUser");

    if (!this.assessmentDetails) {
      this.$store.dispatch("assessments/getMenuData", this.$route.params.id);
      this.getAssessmentDetailsInitial();
    }

    window.addEventListener("resize", this.matchContentWidth);
  },

  beforeDestroy() {
    // clear the assessment
    this.updateMenuData([]);
    this.updateAssessmentDetails(null);
    this.updateQuestion(null);
    this.updateEvidence(null);
    this.updateComments(null);
    this.updateFindings(null);
  },
  data() {
    return {
      roles: roles,
      answerComponentKey: 1,
      slideOutType: "",
      matchingWidthStyles: {},
      isSlideOutOpen: false,
      submitPopupVisible: false,
      submitVisible: true,
      currentQuestionId: null,
      answerValue: null,
      nonCompliant: false,
      isAuditLogsSectionVisible: false,
      isAssessmentSectionVisible: true,
      submitTooltipVisible: false,
      reviewModes: reviewModes,
      roleTypes: roleTypes,
      questionsAnsweredNotOkWithoutCorrectiveAction: [],
      hasComments: false,
      hasFindings: false,
    };
  },
  methods: {
    camelCase: toCamelCase,
    ...mapActions("assessments", [
      "updateMenuData",
      "updateQuestion",
      "updateAssessmentDetails",
      "updateEvidence",
      "updateEvidenceStatus",
      "updateComments",
      "updateFindings",
      "updateSideMenuAnswerValue",
      "submitAssessment",
      "getMenuData",
      "getAssessmentDetails",
      "getQuestionEvidence",
      "getQuestion",
      "getQuestionComments",
      "getFindings",
      "postComment",
      "deleteEvidence",
      "submitAssessment",
      "sendAssessmentForRework",
      "approveAssessment",
      "deleteFinding",
      "getComment",
      "updateComment",
      "deleteComment",
      "getInternalNote",
    ]),
    async getAssessmentDetailsInitial() {
      await this.$store.dispatch(
        "assessments/getAssessmentDetails",
        this.$route.params.id,
      );
      const questionId = this.$route.params.questionId
        ? this.$route.params.questionId
        : this.assessmentDetails.firstQuestionId;
      this.loadQuestion(questionId);
    },
    getUserInitials(text) {
      if (text && text.length > 0) {
        return text
          .split(/\s/)
          .map((x) => x.charAt(0))
          .join("")
          .substring(0, 2);
      }
      return null;
    },
    addFindingClick(e) {
      if (this.selectedFinding) {
        notify(
          this.$t("assessments.assessment.pleaseCloseUpdateFindingForm"),
          "warning",
        );
      } else {
        this.toggleSlideOut(e, "assessmentFindings");
      }
    },
    formatStatusCode(statusCode) {
      return this.$t(`assessments.assessmentList.${toCamelCase(statusCode)}`);
    },
    setStatusClass(statusCode) {
      let status = statusCode;
      if (status == "NotStarted" || status == "Cancelled") {
        return "red";
      } else if (status == "InProgress" || status == "Submitted") {
        return "yellow";
      } else if (status == "Complete") {
        return "green";
      } else {
        return "";
      }
    },
    editFindingClick(e) {
      if (this.selectedFinding) {
        notify(
          this.$t("assessments.assessment.pleaseCloseUpdateFindingForm"),
          "warning",
        );
      } else {
        this.$store
          .dispatch("findings/getSelectedFinding", e.data.id)
          .then((resp) => {
            this.toggleSlideOut(e, "assessmentFindings");
          });
      }
    },
    // TODO:
    // this seems weird
    isNotEmpty(value) {
      return isNotEmpty(value);
    },
    submitConfirmClick() {
      this.submitAssessment(this.$route.params.id)
        .then((response) => {
          this.submitPopupVisible = false;
          this.submitVisible = false;
          notify(
            this.$t("assessments.assessment.successfullySubmittedAssessment"),
            "success",
          );
          // TODO:
          // This is declared twice. tidy it up!
          this.getMenuData(this.$route.params.id);
          this.getAssessmentDetails(this.$route.params.id).then((response) => {
            this.loadQuestion(response.data.firstQuestionId);
          });
        })
        .catch((err) => {
          notify(err, "error");
          this.submitPopupVisible = false;
        });
    },
    onUploadSuccess() {
      this.closeSlideOut();
      this.getQuestionEvidence({
        assessmentId: this.$route.params.id,
        questionId: this.currentQuestionId,
      });
    },
    onUploadError() {
      this.updateEvidenceStatus("error");
      notify(this.$t("attachments.errors.fileUploadFailedMessage"), "error");
    },
    onUploadStarted() {
      this.updateEvidenceStatus("loading");
    },
    assessmentNavigationSelectionChanged(e) {
      if (e.addedItems[0].questionId !== this.currentQuestionId) {
        this.loadQuestion(e.addedItems[0].questionId);
      }
    },
    async prevNextButtonClick(e, buttonClicked) {
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
        );
        if (result) return;
      }
      // next question
      const questionId =
        buttonClicked === "next"
          ? this.question.nextQuestionId
          : this.question.prevQuestionId;

      if (isNotEmpty(questionId)) {
        this.loadQuestion(questionId);
      }
      document
        .querySelector(".with-footer .dx-scrollable-container")
        .scrollTo(0, 0);
    },
    async loadQuestion(questionId) {
      this.closeSlideOut();
      this.currentQuestionId = questionId;
      this.getAssessmentDetails(this.$route.params.id);
      this.getMenuData(this.$route.params.id);
      this.hasComments = false;
      this.hasFindings = false;

      const baseRequestBody = {
        assessmentId: this.$route.params.id,
        questionId: questionId,
      };
      await this.getQuestion(baseRequestBody);

      this.getQuestionEvidence(baseRequestBody);

      if (this.showComments) {
        this.getQuestionComments(baseRequestBody);
      }

      if (this.showFindings) {
        this.getFindings(baseRequestBody);
      }

      await this.getInternalNote(baseRequestBody);
    },
    submitComment(comment) {
      this.postComment({
        comment: comment,
        assessmentId: this.$route.params.id,
        questionId: this.currentQuestionId,
      }).then(() => {
        this.closeSlideOut();
        notify("Successfully added comment.", "success");
        this.getQuestionComments({
          assessmentId: this.$route.params.id,
          questionId: this.currentQuestionId,
        });
      });
    },
    // closeReworkPopup() {
    //   this.reworkPopupVisible = false;
    // },
    downloadAttachment(e) {
      if (!isNotEmpty(e.attachment)) return;
      this.downloadAuthAttachment(e.attachment.link);
    },
    setSelectedAnswer(e) {
      if (this.answerValue !== e.value) {
        if (
          this.findings &&
          this.findings.length > 0 &&
          answersAllowedForFindings.includes(
            this.answerOptions
              .find((x) => x.val === this.answerValue)
              .name.toLowerCase(),
          )
        ) {
          // TODO: remove hack. changing the reference key of the component triggers a rerender
          this.answerComponentKey++;
          notify(
            this.$t("assessments.assessment.removeFindings"),
            "error",
            5000,
          );
          return;
        }

        this.$http
          .put(
            `/assessments/${this.$route.params.id}/questions/${this.currentQuestionId}/answer`,
            {answer: e.value.toString()},
          )
          .then(() => {
            this.$store.dispatch(
              "assessments/getAssessmentDetails",
              this.$route.params.id,
            );
            this.answerValue = e.value;
            this.nonCompliant = false;
            this.updateSideMenuAnswerValue({
              questionId: this.currentQuestionId,
              value: e.value, // this needs changing
            });
          });
      }
    },
    onAddFindingsSuccess(e) {
      this.updateSideMenuAnswerValue({
        questionId: this.currentQuestionId,
        value: 1,
      });
      this.closeSlideOut();
      this.getFindings({
        assessmentId: this.$route.params.id,
        questionId: this.currentQuestionId,
      });
      this.getQuestionEvidence({
        assessmentId: this.$route.params.id,
        questionId: this.currentQuestionId,
      });
    },
    // TODO: debt
    // we should be updating state with the object here
    onUpdateFindingsSuccess(e) {
      this.closeSlideOut();
      this.getFindings({
        assessmentId: this.$route.params.id,
        questionId: this.currentQuestionId,
      });
      this.getQuestionEvidence({
        assessmentId: this.$route.params.id,
        questionId: this.currentQuestionId,
      });
    },
    matchContentWidth() {
      var contentWidth = this.$refs.mainContent.clientWidth + "px";
      // TODO: fix
      // eslint-disable-next-line no-undef
      Vue.set(this.matchingWidthStyles, "width", contentWidth);
    },
    async submitButtonClick() {
      // TODO:
      // send submission request to back end
      if (!this.canSubmit) return;
      if (isUnsavedComment()) {
        let result = await this.unsavedChangesAlert(
          this.$t("shared.unsavedComment"),
        );
        if (result) return;
      }
      this.$http
        .get(`/assessments/${this.$route.params.id}/questionslist`)
        .then((response) => {
          this.questionsAnsweredNotOkWithoutCorrectiveAction =
            response.data.questions;
          this.submitPopupVisible = true;
        });
    },
    submitButtonClose() {
      this.submitPopupVisible = false;
    },
    // reworkClose() {
    //   this.reworkPopupVisible = false;
    // },
    allFindingsAdded() {
      // return document.getElementsByClassName("question-alert").length === 0;
      return true;
    },
    deleteFindingClick(e) {
      this.updateSideMenuAnswerValue({
        questionId: this.currentQuestionId,
        value: -1,
      });
      this.deleteFinding(e.data.id).then(() => {
        notify(
          this.$t("assessments.assessment.successfullyDeletedFinding"),
          "success",
        );
        this.getQuestionEvidence({
          assessmentId: this.$route.params.id,
          questionId: this.currentQuestionId,
        });
      });
    },
    editComment(comment) {
      let payload = {
        commentId: this.$store.state.assessments.comment.commentId,
        comment: comment,
      };
      this.updateComment(payload)
        .then(() => {
          this.closeSlideOut();
          notify("Successfully editted comment.", "success");
          this.getQuestionComments({
            assessmentId: this.$route.params.id,
            questionId: this.currentQuestionId,
          });
        })
        .catch((error) => {
          notify(error.response.data.message || error, "error");
        });
    },
    editCommentClick(e) {
      this.$store.dispatch("assessments/getComment", e.commentsId).then(() => {
        this.toggleSlideOut(e, "commentsEdit");
      });
    },
    deleteCommentClick(e) {
      let result = confirm(
        `<i>${this.$t("shared.sureDeleteComment")}</i>`,
        this.$t("shared.confirmDeletion"),
      );
      result.then(async (dialogResult) => {
        if (dialogResult) {
          this.deleteComment(e.commentsId)
            .then(() => {
              notify("Successfully deleted comment.", "success");
              this.getQuestionComments({
                assessmentId: this.$route.params.id,
                questionId: this.currentQuestionId,
              });
            })
            .catch((error) => {
              notify(error.response.data.message || error, "error");
            });
        }
      });
    },
    // sendForRework(e) {
    //   this.sendAssessmentForRework({
    //     comment: e,
    //     assessmentId: this.$route.params.id,
    //   }).then(() => {
    //     notify(
    //       this.$t("assessments.assessment.successfullySentForRework"),
    //       "success",
    //     );
    //     this.reworkPopupVisible = false;
    //     this.getAssessmentDetails(this.$route.params.id);
    //   });
    // },
    toggleSlideOut(e, type) {
      if (type === this.slideOutType && !this.isSlideOutOpen) {
        this.isSlideOutOpen = !this.isSlideOutOpen;
      } else if (!this.isSlideOutOpen) {
        this.isSlideOutOpen = true;
      }
      this.slideOutType = type;
    },
    closeSlideOut() {
      this.isSlideOutOpen = false;
      this.slideOutType = "";
    },
    showAuditLogTable() {
      this.isAuditLogsSectionVisible = true;
      this.isAssessmentSectionVisible = false;
    },
    showAssessmentSection() {
      this.isAuditLogsSectionVisible = false;
      this.isAssessmentSectionVisible = true;
    },
    toggleSubmitTooltipVisibility() {
      this.submitTooltipVisible = !this.submitTooltipVisible;
    },
    isInRole(role) {
      return this.$store.state.auth.user.roles.includes(role);
    },
    unsavedChangesAlert(message) {
      return confirm(
        `<i>${message}</i>`,
        this.$t("shared.unsavedCommentsTitle"),
      );
    },
  },
  computed: {
    ...mapState("assessments", [
      "question",
      "assessmentDetails",
      "findings",
      "evidence",
      "comments",
      "menuData",
      "comment",
    ]),
    ...mapState("auth", {currentUser: "user"}),
    ...mapState("findings", ["selectedFinding"]),
    ...mapFields("auth", ["user"]),
    answerOptions() {
      return this.question.questionOptions.map((x) => ({
        val: x.id,
        name: x.option,
      }));
    },
    answer() {
      return this.$store.state.assessments.question.answer;
    },
    statusClass() {
      return this.setStatusClass(this.assessmentDetails.statusCode);
    },
    pageTitle() {
      return this.$t("assessments.assessment.title");
    },
    breadCrumbs() {
      if (this.assessmentDetails) {
        let siteName = this.assessmentDetails.organisation.name;
        return [this.$t("assessments.assessment.listTitle"), siteName];
      } else return [this.$t("assessments.assessment.listTitle")];
    },
    attachmentUploadUrl() {
      return `${VUE_APP_API_HOSTNAME}/assessments/${this.$route.params.id}/questions/${this.currentQuestionId}/attachments`;
    },
    user() {
      return this.$store.state.auth.user;
    },
    assessmentComplete() {
      return this.assessmentDetails.percentageComplete === 100;
    },
    readOnly() {
      return isNotEmpty(this.$store.state.assessments.assessmentDetails)
        ? !this.canEdit
        : true;
    },
    canEdit() {
      return (
        this.assessmentDetails.isAssignedResource ||
        this.isInRole(roleTypes.admin.role)
      );
    },
    canReview() {
      return isNotEmpty(this.$store.state.assessments.assessmentDetails)
        ? this.$store.state.assessments.assessmentDetails.canReview
        : false;
    },
    canSubmit() {
      return (
        !this.readOnly &&
        this.allFindingsAdded &&
        this.assessmentComplete &&
        !(this.assessmentDetails.statusCode === "Submitted")
      );
    },
    showComments() {
      return this.hasComments;
    },
    showFindings() {
      return this.hasFindings;
    },
    showAttachments() {
      return (
        this.question.isAttachmentAllowed ||
        (this.evidence &&
          this.evidence.length > 0 &&
          this.evidence.every((x) => x.type === "Finding"))
      );
    },
  },
  updated() {
    this.matchContentWidth();
  },
  mounted() {
    //TODO: fix the DOM manipulation and create the separate layout specifically for the assessment
    this.$refs["slideout-ref"].parentNode.removeChild(
      this.$refs["slideout-ref"],
    );
    document
      .getElementsByClassName("layout-body")[0]
      .appendChild(this.$refs["slideout-ref"]);
  },
  destroyed() {
    window.removeEventListener("resize", this.matchContentWidth);
    document.getElementsByClassName("slide-out")[0].remove();
  },
  watch: {
    question() {
      if (this.question) {
        this.updateComments(null);
        this.updateFindings(null);
        if (this.question.answer) {
          this.answerValue =
            this.question.typeFlag === questionType.SingleSelect
              ? parseInt(this.question.answer.value)
              : this.question.answer.value;
          this.nonCompliant = false;
        } else {
          this.answerValue = null;
          this.nonCompliant = false;
        }
      }
    },
    answerValue() {
      if (this.question && this.answerValue) {
        if (this.question.typeFlag === questionType.SingleSelect) {
          let answerText = this.question.questionOptions.find(
            (x) => x.id === parseInt(this.answerValue),
          ).option;

          this.hasComments =
            this.question.category != "Review Info" &&
            answersAllowedForComments.includes(answerText.toLowerCase());

          this.hasFindings =
            this.question.category != "Review Info" &&
            answersAllowedForFindings.includes(answerText.toLowerCase());
        }
      }
    },
  },
};
</script>

<style lang="scss">
.audit-log-tab {
  padding: 15px 0;
}
</style>
