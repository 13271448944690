<template>
  <div class="content-block">
    <h2 style="margin-bottom: 30px">
      <i class="fas fa-bell"></i>
      {{ $t("orders.orderDetails.notification") }}
    </h2>
    <div class="row">
      <div class="col-xl-12">
        <div style="display: flex">
          <label class="required bold-label">
            {{ $t("orders.notification.announcedOrUnannounced") }}
            <DxTooltip
              :visible.sync="announcedOrUnannouncedToolTip"
              :close-on-outside-click="false"
              target="#divAnnouncedOrUnannounced"
              position="top"
            >
              {{ $t("tooltips.order.announcedOrUnannounced") }}
            </DxTooltip>
          </label>
          <div
            class="tooltip"
            id="divAnnouncedOrUnannounced"
            @mouseenter="
              announcedOrUnannouncedToolTip = !announcedOrUnannouncedToolTip
            "
            @mouseleave="
              announcedOrUnannouncedToolTip = !announcedOrUnannouncedToolTip
            "
          ></div>
        </div>
        <dx-select-box
          :value.sync="notification"
          :data-source="notifications"
          stylingMode="outlined"
          display-expr="name"
          value-expr="id"
          :placeholder="
            $t('shared.select', {
              dataField: $t('orders.notification.announcedOrUnannounced'),
            })
          "
          @value-changed="onSelectedNotificationChanged($event)"
          :disabled="
            !$can('update', $subjects.order.subject) ||
            orderStatus === 'Submitted' ||
            isDisabled
          "
        >
          <dx-validator>
            <dx-required-rule
              :message="
                $t('shared.isRequired', {
                  dataField: $t('orders.notification.announcedOrUnannounced'),
                })
              "
            />
          </dx-validator>
        </dx-select-box>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12" v-if="showDelayNotification">
        <div style="display: flex">
          <label class="bold-label">
            {{ $t("orders.notification.delayNotification") }}
            <DxTooltip
              :visible.sync="delayNotificationToolTip"
              :close-on-outside-click="false"
              target="#divDelayNotification"
              position="top"
            >
              {{ $t("tooltips.order.delayNotification") }}
            </DxTooltip>
          </label>
          <div
            class="tooltip"
            id="divDelayNotification"
            @mouseenter="delayNotificationToolTip = !delayNotificationToolTip"
            @mouseleave="delayNotificationToolTip = !delayNotificationToolTip"
          ></div>
        </div>
        <div class="checkbox-container">
          <dx-check-box
            :text="$t('orders.notification.delay')"
            v-model="delayNotification"
            @value-changed="onDelayNotificationChange"
            :disabled="
              !$can('update', $subjects.order.subject) ||
              orderStatus === 'Submitted' ||
              !$can('update', $subjects.order.subject, 'delayNotification')
            "
          />
        </div>
      </div>
    </div>
    <div class="row" :ref="delayNotificationDateContainerRef">
      <div class="col-xl-12" v-if="delayNotification">
        <transition name="fade" mode="out-in">
          <div class="level-down-container">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.notification.delayDate") }}
                <DxTooltip
                  :visible.sync="delayDateToolTip"
                  :close-on-outside-click="false"
                  target="#divDelayDate"
                  position="top"
                >
                  {{ $t("tooltips.order.delayDate") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divDelayDate"
                @mouseenter="delayDateToolTip = !delayDateToolTip"
                @mouseleave="delayDateToolTip = !delayDateToolTip"
              ></div>
            </div>
            <dx-date-box
              name="delayNotificationDate"
              stylingMode="outlined"
              :acceptCustomValue="false"
              :openOnFieldClick="true"
              type="date"
              validationMessageMode="auto"
              pickerType="calendar"
              :min.sync="todaysDate"
              v-model="notificationDelayDate"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.notification.delayDate'),
                })
              "
              :ref="delayNotificationDateDropDownRef"
              @opened="onDelayNotificationDateDropDownOpened"
              :disabled="
                !$can('update', $subjects.order.subject) ||
                orderStatus === 'Submitted' ||
                !$can('update', $subjects.order.subject, 'delayNotification')
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.notification.delayDate'),
                    })
                  "
                />
                <dx-range-rule :max="maxDate" />
              </dx-validator>
            </dx-date-box>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxValidator,
  DxRequiredRule,
  DxRangeRule,
} from "devextreme-vue/validator";
import {DxCheckBox, DxSelectBox, DxDateBox, DxTooltip} from "devextreme-vue";
import {mapActions, mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import {notificationStatus} from "../../common/constants";

export default {
  props: {
    isDisabled: {
      Type: Boolean,
      Required: false,
      Default: false,
    },
  },
  components: {
    DxCheckBox,
    DxSelectBox,
    DxDateBox,
    DxValidator,
    DxRequiredRule,
    DxRangeRule,
    DxTooltip,
  },
  methods: {
    onSelectedNotificationChanged(e) {
      this.showDelayNotification = e.value === notificationStatus.Announced;
      if (e.value === notificationStatus.Unannounced) {
        this.delayNotification = false;
        this.notificationDelayDate = null;
      }
    },
    onDelayNotificationChange() {
      if (!this.delayNotification) {
        this.notificationDelayDate = null;
      }
    },
    onDelayNotificationDateDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576
          ? this.delayNotificationDateContainer.clientWidth - 46
          : 340;
      this.delayNotificationDateDropDown.option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
  },
  data() {
    return {
      delayNotificationDateContainerRef: "delayNotificationDateContainer",
      delayNotificationDateDropDownRef: "delayNotificationDate ",
      notifications: [
        {
          id: 1,
          name: "Announced",
        },
        {
          id: 2,
          name: "Unannounced",
        },
      ],
      showDelayNotification: false,
      delayDateToolTip: false,
      delayNotificationToolTip: false,
      announcedOrUnannouncedToolTip: false,
    };
  },
  computed: {
    ...mapFields("orders", [
      "notification",
      "delayNotification",
      "notificationDelayDate",
      "orderStatus",
      "reviewStartDate",
    ]),
    todaysDate() {
      return new Date().setHours(0, 0, 0, 0);
    },
    delayNotificationDateContainer() {
      return this.$refs[this.delayNotificationDateContainerRef];
    },
    delayNotificationDateDropDown() {
      return this.$refs[this.delayNotificationDateDropDownRef].instance;
    },
    maxDate() {
      if (this.reviewStartDate) {
        const d = new Date(this.reviewStartDate);
        return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
      } else {
        const d = new Date();
        return new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());
      }
    },
  },
};
</script>

<style></style>
