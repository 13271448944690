<template>
  <form id="form" ref="form" @submit.prevent="onFormSubmit($event)">
    <div class="content-block">
      <h2 class="content-title">
        <i class="far fa-flag"></i> {{ contentTitle }}
      </h2>
    </div>
    <div class="content-block">
      <label class="required bold-label">{{
        $t("findings.addFindings.description")
      }}</label>
      <DxTextArea
        :height="150"
        v-model="description"
        stylingMode="outlined"
        max-length="2000"
        :readOnly="readOnly"
      >
        <dx-validator>
          <dx-required-rule
            :message="$t('findings.addFindings.descriptionRequired')"
          />
        </dx-validator>
      </DxTextArea>
    </div>
    <div class="content-block">
      <div style="display: flex">
        <label class="required bold-label">
          {{ $t("findings.addFindings.capDate") }}
          <DxTooltip
            :visible.sync="capDateToolTip"
            :close-on-outside-click="false"
            target="#divCapDate"
            position="top"
          >
            {{ $t("tooltips.findings.capDate") }}
          </DxTooltip>
        </label>
        <div
          class="tooltip"
          id="divCapDate"
          @mouseenter="capDateToolTip = !capDateToolTip"
          @mouseleave="capDateToolTip = !capDateToolTip"
        ></div>
      </div>
      <dx-date-box
        name="addFindingsCapDate"
        stylingMode="outlined"
        :acceptCustomValue="false"
        :openOnFieldClick="true"
        type="date"
        validationMessageMode="auto"
        pickerType="calendar"
        v-model="addFindingsCapDate"
        :readOnly="readOnly"
      >
        <dx-validator>
          <dx-required-rule :message="$t('findings.addFindings.capDate')" />
        </dx-validator>
      </dx-date-box>
    </div>
    <div class="content-block">
      <div style="display: flex">
        <label class="required bold-label">
          {{ $t("findings.addFindings.approvalDeadline") }}
          <DxTooltip
            :visible.sync="approvalDeadlineToolTip"
            :close-on-outside-click="false"
            target="#divApprovalDeadline"
            position="top"
          >
            {{ $t("tooltips.findings.deadlineForApproval") }}
          </DxTooltip>
        </label>
        <div
          class="tooltip"
          id="divApprovalDeadline"
          @mouseenter="approvalDeadlineToolTip = !approvalDeadlineToolTip"
          @mouseleave="approvalDeadlineToolTip = !approvalDeadlineToolTip"
        ></div>
      </div>
      <dx-date-box
        name="addFindingsApprovalDeadline"
        stylingMode="outlined"
        :acceptCustomValue="false"
        :openOnFieldClick="true"
        type="date"
        validationMessageMode="auto"
        pickerType="calendar"
        v-model="addFindingsApprovalDeadline"
        :readOnly="readOnly"
      >
        <dx-validator>
          <dx-required-rule
            :message="$t('findings.addFindings.approvalDeadline')"
          />
        </dx-validator>
      </dx-date-box>
    </div>
    <div class="content-block">
      <div class="checkbox-container">
        <dx-check-box
          :text="$t('findings.addFindings.showToReviewParticipant')"
          v-model="showToReviewParticipant"
          @value-changed="onShowToReviewParticipantValueChanged($event)"
          :readOnly="readOnly"
        />
        <label>{{
          $t("findings.addFindings.showToReviewParticipantHelperText")
        }}</label>
      </div>
    </div>
    <div class="content-block" v-if="!readOnly">
      <div style="display: flex">
        <label class="bold-label">
          {{ $t("findings.addFindings.attachments") }}
          <DxTooltip
            :visible.sync="attachmentsToolTip"
            :close-on-outside-click="false"
            target="#divFindingsAttachments"
            position="top"
          >
            {{ $t("tooltips.findings.attachments") }}
          </DxTooltip>
        </label>
        <div
          class="tooltip"
          id="divFindingsAttachments"
          @mouseenter="attachmentsToolTip = !attachmentsToolTip"
          @mouseleave="attachmentsToolTip = !attachmentsToolTip"
        ></div>
      </div>

      <div class="upload-container">
        <i class="fas fa-upload"></i>
        <dx-file-uploader
          :multiple="true"
          name="file"
          ref="fileUploader"
          upload-mode="useForm"
          @value-changed="attachmentsValueChanged($event)"
          :style="attachmentsBorderStyle"
          :allowed-file-extensions="fileExtensionWhitelist"
          :max-file-size="maxFileUploadSize"
          :invalid-file-extension-message="
            $t('attachments.errors.invalidFileExtensionMessage')
          "
          :invalid-max-file-size-message="
            $t('attachments.errors.invalidMaxFileSizeMessage')
          "
        />
        <!-- <dx-validator :adapter="attachmentsAdapterConfig">
          <dx-custom-rule
            :validation-callback="validateAttachments"
            :message="$t('findings.addFindings.attachmentRequired')"
          />
        </dx-validator> -->
      </div>
    </div>
    <dx-button
      :text="formButtonText"
      name="addFinding"
      type="default"
      :visible="!readOnly"
      style="margin-right: 10px"
      class="btn btn-large"
      icon="check"
      :use-submit-behavior="true"
    />
    <dx-button
      :text="$t('findings.addFindings.cancel')"
      name="cancel"
      type="primary"
      class="btn btn-large"
      icon="undo"
      @click="onCancelButtonClick($event)"
    />
  </form>
</template>

<script>
import {DxTextArea, DxDateBox, DxCheckBox, DxTooltip} from "devextreme-vue";
import DxButton from "devextreme-vue/button";
import {mapActions, mapState} from "vuex";
import {createHelpers} from "vuex-map-fields";
import {DxFileUploader} from "devextreme-vue/file-uploader";
import {fileUploadValidation} from "../../common/constants";
import {confirm} from "devextreme/ui/dialog";
import {
  DxValidator,
  DxRequiredRule,
  // DxCustomRule,
} from "devextreme-vue/validator";

const {mapFields} = createHelpers({
  getterType: "findings/getField",
  mutationType: "findings/updateField",
});

export default {
  props: {
    assessmentId: {
      Type: Number,
      required: true,
    },
    questionId: {
      Type: Number,
      required: true,
    },
    readOnly: {
      Type: Boolean,
    },
    assessmentDetails: {
      Type: Object,
    },
  },
  components: {
    DxButton,
    DxCheckBox,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxFileUploader,
    DxDateBox,
    DxTooltip,
  },
  data() {
    return {
      files: [],
      brandsBorderStyle: {},
      attachmentsBorderStyle: {},
      fileExtensionWhitelist: fileUploadValidation.fileExtensionWhitelist,
      maxFileUploadSize: fileUploadValidation.maxFileUploadSize,
      brandsAdapterConfig: {
        getValue: () => {
          return this.selectedBrands;
        },
        applyValidationResults: (e) => {
          this.brandsBorderStyle = e.isValid ? {} : {border: "1px solid red"};
        },
        validationRequestsCallbacks: [],
      },
      attachmentsAdapterConfig: {
        getValue: () => {
          return this.files;
        },
        applyValidationResults: (e) => {
          this.attachmentsBorderStyle = e.isValid
            ? {}
            : {border: "1px solid red"};
        },
        validationRequestsCallbacks: [],
      },
      addFindingsCapDate: null,
      addFindingsApprovalDeadline: null,
      showToReviewParticipant: false,
      capDateToolTip: false,
      approvalDeadlineToolTip: false,
      attachmentsToolTip: false,
      ignoreShowToReviewParticipantConfirmation: false,
    };
  },

  beforeDestroy() {
    this.title = "";
    this.description = "";
    this.selectedBrands = [];
    this.selectedRootCause = null;
    this.addFindingsCapDate = null;
    this.addFindingsApprovalDeadline = null;
    this.showToReviewParticipant = null;
    this.updateSelectedFinding(null);
  },
  mounted() {
    this.addFindingsCapDate = this.assessmentDetails.estCapDate;
    this.addFindingsApprovalDeadline = this.assessmentDetails.estDeadlineApprovalDate;
    // set single selected value in vuex
    if (this.selectedFinding) {
      // If we're editing, add the fields
      this.description = this.selectedFinding.description;
      this.addFindingsCapDate = this.selectedFinding.capDate;
      this.addFindingsApprovalDeadline = this.selectedFinding.dueDate;
      this.showToReviewParticipant = this.selectedFinding.showToReviewParticipant;
      this.ignoreShowToReviewParticipantConfirmation = this.selectedFinding.showToReviewParticipant;
    }
  },
  methods: {
    ...mapActions("findings", [
      "addFinding",
      "updateFinding",
      "updateSelectedFinding",
    ]),

    onFormSubmit() {
      let payload = {
        assessmentId: this.assessmentId,
        questionId: this.questionId,
        finding: {
          description: this.description,
          capDate: this.$moment.utc(this.addFindingsCapDate).local().format(),
          deadlineForApproval: this.$moment
            .utc(this.addFindingsApprovalDeadline)
            .local()
            .format(),
          showToReviewParticipant: this.showToReviewParticipant,
        },
        attachments: this.files,
      };
      // call vuex post
      if (this.selectedFinding) {
        payload = {
          ...payload,
          findingId: this.selectedFinding.id,
        };

        this.updateFinding(payload).then((response) =>
          this.$emit("on-update-finding-success", response),
        );
      } else {
        this.addFinding(payload).then((response) =>
          this.$emit("on-add-finding-success", response),
        );
      }
    },
    onCancelButtonClick() {
      this.$emit("cancel-finding-click");
    },
    attachmentsValueChanged(e) {
      this.files = e.value;

      for (let index = 0; index < e.value.length; index++) {
        let fileName = e.value[index].name;

        if (fileUploadValidation.validCharacters.test(fileName)) continue;
        if (!e.component._files[index].isValid()) {
          e.component._files[index].$statusMessage.append(
            this.$t("attachments.errors.invalidFileNameMessage"),
          );
        } else {
          e.component._files[index].$statusMessage.text(
            this.$t("attachments.errors.invalidFileNameMessage"),
          );
          e.component._files[index].$statusMessage.addClass("error-text");
          e.component._files[index].isValid = () => false;
        }
      }

      this.revalidateAttachments();
    },
    revalidateAttachments() {
      this.attachmentsAdapterConfig.validationRequestsCallbacks.forEach(
        (func) => {
          func();
        },
      );
    },
    validateAttachments() {
      return this.files.length > 0
        ? this.$refs.fileUploader.instance._files.every((x) => x.isValid())
        : true;
    },
    onShowToReviewParticipantValueChanged(e) {
      if (e.value === true && !this.ignoreShowToReviewParticipantConfirmation) {
        let result = confirm(
          `<i>${this.$t(
            "findings.addFindings.showToReviewParticipantConfirmationMessage",
          )}</i>`,
          this.$t("findings.addFindings.confirm"),
        );
        result.then((dialogResult) => {
          this.showToReviewParticipant = dialogResult ? dialogResult : false;
        });
      }
      this.ignoreShowToReviewParticipantConfirmation = false;
    },
  },
  computed: {
    ...mapState("findings", ["brandTypes", "rootCauses", "selectedFinding"]),
    ...mapFields([
      "title",
      "description",
      "selectedRootCause",
      "selectedBrands",
    ]),
    formButtonText() {
      return this.selectedFinding
        ? this.$t("findings.addFindings.updateFinding")
        : this.$t("findings.addFindings.addFinding");
    },
    contentTitle() {
      return this.selectedFinding
        ? this.$t("findings.addFindings.updateFinding")
        : this.$t("findings.addFindings.addNewFinding");
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-upload {
  position: absolute;
  top: 45px;
  left: calc(50% - 17px);
  font-size: 34px;
  color: #5b778f;
}
.upload-container::v-deep .dx-fileuploader-input-wrapper {
  background-color: rgba(183, 183, 183, 0.1);
}
</style>
