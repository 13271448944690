<template>
  <div v-if="filtersList.length">
    <dx-select-box
      :value="orderFilter"
      :data-source="filtersList"
      stylingMode="outlined"
      display-expr="name"
      value-expr="id"
      @value-changed="selectedFilterChanged"
    ></dx-select-box>
  </div>
</template>

<script>
import {DxSelectBox} from "devextreme-vue";
import {mapState} from "vuex";

export default {
  components: {
    DxSelectBox,
  },
  data() {
    return {
      filtersList: [
        {id: 1, name: "My reviews"},
        {id: 2, name: "All initial assessments"},
        {id: 3, name: "All reviews"},
        {id: 4, name: "All"},
      ],
    };
  },
  methods: {
    selectedFilterChanged(e) {
      this.$store.dispatch("orders/updateOrderFilter", e.value);
    },
  },
  computed: {
    ...mapState("orders", ["orderFilter"]),
  },
  beforedestroy() {
    this.$store.dispatch("orders/updateOrderFilter", null);
  },
};
</script>

<style></style>
