<template>
  <div class="create-order-details">
    <h2 style="margin-bottom: 30px">
      <i class="far fa-clipboard"></i>
      {{ $t("orders.orderDetails.title") }}
    </h2>
    <div class="container-breaker">
      <div class="content-block">
        <div class="row">
          <div class="col-lg-6">
            <label class="required bold-label">
              {{ $t("orders.orderDetails.orderer") }}
            </label>
            <dx-select-box
              :value.sync="selectedOrderer"
              :data-source="orderers"
              stylingMode="outlined"
              display-expr="fullname"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.orderer'),
                })
              "
              :disabled="
                !$can('update', $subjects.order.subject, 'orderer') ||
                isDisabled
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.orderer'),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="col-lg-6" :ref="siteOrStoreContainerRef">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.siteName") }}
                <DxTooltip
                  :visible.sync="siteNameToolTip"
                  :close-on-outside-click="false"
                  target="#divSiteName"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divSiteName"
                @mouseenter="siteNameToolTip = !siteNameToolTip"
                @mouseleave="siteNameToolTip = !siteNameToolTip"
              ></div>
            </div>
            <DxDropDownBox
              :value.sync="selectedOrganisation"
              :defer-rendering="false"
              :show-clear-button="true"
              resize-enabled="false"
              :data-source="siteOrStoreDataSource"
              display-expr="siteName"
              stylingMode="outlined"
              value-expr="id"
              @value-changed="onSelectedOrganisationChanged($event)"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.siteName'),
                })
              "
              :ref="siteOrStoreDropDownRef"
              @opened="onsiteOrStoreDropDownOpened"
              :disabled="
                !$can('update', $subjects.order.subject, 'site') ||
                (orderStatus && orderStatus !== 'Ordered') ||
                isDisabled
              "
            >
              <template #content>
                <DxDataGrid
                  :data-source="siteOrStoreDataSource"
                  :columns="siteOrStoreColumns"
                  :hover-state-enabled="true"
                  :column-auto-width="true"
                  :selected-row-keys.sync="selectedOrganisation"
                  height="100%"
                >
                  <DxSelection mode="single" />
                  <DxPaging :enabled="true" :page-size="10" />
                  <DxFilterRow :visible="true" />
                  <DxScrolling mode="infinite" />
                </DxDataGrid>
              </template>
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.siteName'),
                    })
                  "
                />
              </dx-validator>
            </DxDropDownBox>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.fiscalYear") }}
                <DxTooltip
                  :visible.sync="fiscalYearToolTip"
                  :close-on-outside-click="false"
                  target="#divFiscalYear"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divFiscalYear"
                @mouseenter="fiscalYearToolTip = !fiscalYearToolTip"
                @mouseleave="fiscalYearToolTip = !fiscalYearToolTip"
              ></div>
            </div>
            <dx-select-box
              :value="selectedAuditYear"
              :data-source="auditYears"
              stylingMode="outlined"
              display-expr="name"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.fiscalYear'),
                })
              "
              @value-changed="onSelectedAuditYearChanged($event)"
              :disabled="
                !$can('update', $subjects.order.subject, 'fiscalYear') ||
                (orderStatus && orderStatus !== 'Ordered') ||
                isDisabled
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.fiscalYear'),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>

          <div class="col-lg-6">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.tertial") }}
                <DxTooltip
                  :visible.sync="tertialToolTip"
                  :close-on-outside-click="false"
                  target="#divTertial"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divTertial"
                @mouseenter="tertialToolTip = !tertialToolTip"
                @mouseleave="tertialToolTip = !tertialToolTip"
              ></div>
            </div>
            <dx-select-box
              :value.sync="selectedTertial"
              :data-source="tertials"
              stylingMode="outlined"
              display-expr="name"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.tertial'),
                })
              "
              :disabled="
                !$can('update', $subjects.order.subject, 'tertial') ||
                isDisabled
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.tertial'),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.reviewMode") }}
                <DxTooltip
                  :visible.sync="reviewModeToolTip"
                  :close-on-outside-click="false"
                  target="#divReviewMode"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divReviewMode"
                @mouseenter="reviewModeToolTip = !reviewModeToolTip"
                @mouseleave="reviewModeToolTip = !reviewModeToolTip"
              ></div>
            </div>
            <dx-select-box
              :value.sync="selectedReviewMode"
              :data-source="reviewModes"
              stylingMode="outlined"
              display-expr="name"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.reviewMode'),
                })
              "
              @value-changed="onSelectedReviewModeChanged($event)"
              :disabled="
                !$can('update', $subjects.order.subject, 'reviewMode') ||
                (orderStatus && orderStatus !== 'Ordered') ||
                isDisabled
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.reviewMode'),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="col-lg-6">
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.reviewType") }}
                <DxTooltip
                  :visible.sync="reviewTypeToolTip"
                  :close-on-outside-click="false"
                  target="#divReviewType"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divReviewType"
                @mouseenter="reviewTypeToolTip = !reviewTypeToolTip"
                @mouseleave="reviewTypeToolTip = !reviewTypeToolTip"
              ></div>
            </div>
            <dx-select-box
              :value.sync="selectedReviewType"
              :data-source="reviewTypes"
              stylingMode="outlined"
              display-expr="name"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.reviewType'),
                })
              "
              @value-changed="onSelectedReviewTypeChanged($event)"
              :disabled="
                !$can('update', $subjects.order.subject, 'reviewType') ||
                (orderStatus && orderStatus !== 'Ordered') ||
                isDisabled
              "
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.reviewType'),
                    })
                  "
                />
              </dx-validator>
            </dx-select-box>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6"
            v-if="selectedReviewType"
            @click="tagBoxClick(scopeOfReviewRefKey)"
          >
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.scopeOfReview") }}
                <DxTooltip
                  :visible.sync="scopeOfReviewToolTip"
                  :close-on-outside-click="false"
                  target="#divScopeOfReview"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divScopeOfReview"
                @mouseenter="scopeOfReviewToolTip = !scopeOfReviewToolTip"
                @mouseleave="scopeOfReviewToolTip = !scopeOfReviewToolTip"
              ></div>
            </div>
            <dx-tag-box
              :defer-rendering="false"
              :data-source="reviewScope"
              display-expr="name"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.scopeOfReview'),
                })
              "
              :show-clear-button="true"
              :show-drop-down-button="true"
              :ref="scopeOfReviewRefKey"
              :show-selection-controls="true"
              styling-mode="outlined"
              :value.sync="selectedScopeOfReview"
              value-expr="id"
              :wrapItemText="true"
              :max-displayed-tags="1"
              apply-value-mode="instantly"
              @value-changed="onSelectedScopeOfReviewChanged($event)"
              :disabled="isScopeOfReviewDisabled"
              :dropDownOptions="scopeOfReviewDropdownOptions"
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.scopeOfReview'),
                    })
                  "
                />
              </dx-validator>
            </dx-tag-box>
          </div>
          <div
            v-if="levelsOfReviewData.length > 0"
            class="col-lg-6"
            :ref="levelOfReviewContainerRef"
            @click="tagBoxClick(levelsOfReviewRefKey)"
          >
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.levelOfReview") }}
                <DxTooltip
                  :visible.sync="levelOfReviewToolTip"
                  :close-on-outside-click="false"
                  target="#divLevelOfReview"
                  position="top"
                >
                  {{ $t("tooltips.order.general") }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divLevelOfReview"
                @mouseenter="levelOfReviewToolTip = !levelOfReviewToolTip"
                @mouseleave="levelOfReviewToolTip = !levelOfReviewToolTip"
              ></div>
            </div>
            <dx-tag-box
              :defer-rendering="false"
              :data-source="levelsOfReviewData"
              display-expr="name"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.levelOfReview'),
                })
              "
              :ref="levelsOfReviewRefKey"
              :show-clear-button="true"
              :show-drop-down-button="true"
              :show-selection-controls="true"
              styling-mode="outlined"
              :value.sync="selectedLevelOfReviewMultiple"
              value-expr="id"
              :wrapItemText="true"
              :max-displayed-tags="2"
              apply-value-mode="instantly"
              :disabled="isLevelsOfReviewDisabled"
              :dropDownOptions="levelsOfReviewDropdownOptions"
            >
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.levelOfReview'),
                    })
                  "
                />
              </dx-validator>
            </dx-tag-box>
          </div>
          <div
            class="col-lg-6"
            v-if="!selectedReviewType"
            :ref="siteCategoryContainerRef"
          >
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.siteCategory") }}
                <DxTooltip
                  :visible.sync="siteCategoryToolTip"
                  :close-on-outside-click="false"
                  target="#divSiteCategory"
                  position="top"
                >
                  {{ `${$t("tooltips.order.general")}` }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divSiteCategory"
                @mouseenter="siteCategoryToolTip = !siteCategoryToolTip"
                @mouseleave="siteCategoryToolTip = !siteCategoryToolTip"
              ></div>
            </div>
            <DxDropDownBox
              :value.sync="selectedSiteCategoryMultiple"
              :defer-rendering="false"
              :show-clear-button="true"
              resize-enabled="false"
              :data-source="siteCategoryDataSource"
              stylingMode="outlined"
              display-expr="subCategory"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.siteCategory'),
                })
              "
              :ref="siteCategoryDropDownRef"
              @opened="onsiteCategoryDropDownOpened"
              :disabled="
                !$can('update', $subjects.order.subject, 'siteCategory') ||
                isDisabled
              "
            >
              <template #content>
                <DxDataGrid
                  :data-source="siteCategoryDataSource"
                  :columns="siteCategoryColumns"
                  :hover-state-enabled="true"
                  :word-wrap-enabled="true"
                  :column-auto-width="true"
                  :selected-row-keys.sync="selectedSiteCategoryMultiple"
                  height="100%"
                >
                  <DxSelection
                    mode="multiple"
                    :show-check-boxes-mode="checkBoxMode"
                  />
                  <DxPaging :enabled="true" :page-size="10" />
                  <DxFilterRow :visible="true" />
                  <DxScrolling mode="infinite" />
                </DxDataGrid>
              </template>
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.siteCategory'),
                    })
                  "
                />
              </dx-validator>
            </DxDropDownBox>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-12"
            v-if="selectedReviewType"
            :ref="siteCategoryContainerRef"
          >
            <div style="display: flex">
              <label class="required bold-label">
                {{ $t("orders.orderDetails.siteCategory") }}
                <DxTooltip
                  :visible.sync="siteCategoryToolTip"
                  :close-on-outside-click="false"
                  target="#divSiteCategory"
                  position="top"
                >
                  {{
                    `${$t("tooltips.order.general")}. ${$t(
                      "tooltips.order.siteCategory",
                    )}`
                  }}
                </DxTooltip>
              </label>
              <div
                class="tooltip"
                id="divSiteCategory"
                @mouseenter="siteCategoryToolTip = !siteCategoryToolTip"
                @mouseleave="siteCategoryToolTip = !siteCategoryToolTip"
              ></div>
            </div>
            <DxDropDownBox
              :value.sync="selectedSiteCategoryMultiple"
              :defer-rendering="false"
              :show-clear-button="true"
              resize-enabled="false"
              :data-source="siteCategoryDataSource"
              stylingMode="outlined"
              display-expr="subCategory"
              value-expr="id"
              :placeholder="
                $t('shared.select', {
                  dataField: $t('orders.orderDetails.siteCategory'),
                })
              "
              :ref="siteCategoryDropDownRef"
              @opened="onsiteCategoryDropDownOpened"
              :disabled="
                !$can('update', $subjects.order.subject, 'siteCategory') ||
                isDisabled
              "
            >
              <template #content>
                <DxDataGrid
                  :data-source="siteCategoryDataSource"
                  :columns="siteCategoryColumns"
                  :hover-state-enabled="true"
                  :word-wrap-enabled="true"
                  :column-auto-width="true"
                  :selected-row-keys.sync="selectedSiteCategoryMultiple"
                  height="100%"
                >
                  <DxSelection
                    mode="multiple"
                    :show-check-boxes-mode="checkBoxMode"
                  />
                  <DxPaging :enabled="true" :page-size="10" />
                  <DxFilterRow :visible="true" />
                  <DxScrolling mode="infinite" />
                </DxDataGrid>
              </template>
              <dx-validator>
                <dx-required-rule
                  :message="
                    $t('shared.isRequired', {
                      dataField: $t('orders.orderDetails.siteCategory'),
                    })
                  "
                />
              </dx-validator>
            </DxDropDownBox>
          </div>
        </div>
        <div v-if="$route.name === 'order'" class="row">
          <div class="col-lg-12">
            <label class="bold-label">{{
              $t("orders.orderDetails.initialComments")
            }}</label>
            <dx-text-area
              styling-mode="outlined"
              v-model="initialComments"
              :height="150"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-12"
            v-if="selectedOrganisation && selectedOrganisation.length"
          >
            <label class="bold-label">{{
              $t("orders.orderDetails.usersAssigned")
            }}</label>
            <DxDataGrid
              :data-source="reviewParticipantsAndBusinessOwnersData"
              :columns="siteUserscolDefs"
              :hover-state-enabled="true"
              :word-wrap-enabled="true"
              :column-auto-width="true"
              :noDataText="this.$t('orders.orderDetails.noUsersData')"
              height="100%"
              width="100%"
            >
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DxValidator, DxRequiredRule} from "devextreme-vue/validator";
import {DxSelectBox, DxDropDownBox, DxTagBox, DxTooltip} from "devextreme-vue";
import {
  DxDataGrid,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
} from "devextreme-vue/data-grid";
import {mapActions, mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import $axios from "../../utils/axios-instance";
import {
  isNotEmpty,
  isIdenticalArray,
  toCamelCase,
} from "../../common/helperFunctions";
import {reviewModes} from "../../common/constants";
import roleTypes from "../../config/roles";
import DxTextArea from "devextreme-vue/text-area";

export default {
  props: {
    isDisabled: {
      Type: Boolean,
      Required: false,
      Default: false,
    },
  },
  components: {
    DxValidator,
    DxRequiredRule,
    DxSelectBox,
    DxDropDownBox,
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxTagBox,
    DxTooltip,
    DxTextArea,
  },
  data() {
    return {
      orderers: [],
      siteOrStoreDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadSitesOrStores,
          loadMode: "raw",
        }),
      }),
      siteOrStoreContainerRef: "container",
      siteOrStoreDropDownRef: "site/store",
      levelOfReviewContainerRef: "levelOfReviewContainer",
      levelOfReviewDropDownRef: "levels",
      checkBoxMode: "always",
      siteOrStoreColumns: [
        {
          dataField: "supplierName",
          caption: "Supplier name",
          width: "auto",
          sortOrder: "asc",
        },
        {
          dataField: "siteName",
          caption: "Site/Store",
          width: "auto",
        },
        {
          dataField: "country",
          caption: "Country",
          width: "auto",
        },
      ],
      siteCategoryDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: this.loadSiteCategory,
          loadMode: "raw",
        }),
      }),
      siteCategoryColumns: [
        {
          dataField: "categoryArea",
          caption: "Category Area (L1)",
          width: "auto",
        },
        {
          dataField: "category",
          caption: "Category (L2)",
          width: "auto",
        },
        {
          dataField: "subCategory",
          caption: "Sub-Category (L3)",
          width: "auto",
        },
        {
          dataField: "description",
          caption: "Description of L3",
          width: "auto",
        },
      ],
      siteCategoryContainerRef: "siteCategoryContainer",
      siteCategoryDropDownRef: "siteCategory",
      siteNameToolTip: false,
      fiscalYearToolTip: false,
      tertialToolTip: false,
      reviewModeToolTip: false,
      reviewTypeToolTip: false,
      scopeOfReviewToolTip: false,
      scopeOfReviewRefKey: "scopeOfReviewRef",
      levelOfReviewToolTip: false,
      levelsOfReviewRefKey: "levelsOfReviewRef",
      siteCategoryToolTip: false,
      reviewParticipantsAndBusinessOwnersData: [],
      reviewParticipantBusinessOwnersColumns: [
        {
          dataField: "firstName",
          caption: "orders.orderDetails.firstName",
          width: "auto",
        },
        {
          dataField: "lastName",
          caption: "orders.orderDetails.lastName",
          width: "auto",
        },
        {
          dataField: "email",
          caption: "orders.orderDetails.email",
          width: "auto",
        },
        {
          dataField: "role",
          caption: "orders.orderDetails.role",
          width: "auto",
        },
        {
          dataField: "jobTitle",
          caption: "orders.orderDetails.jobTitle",
          width: "auto",
        },
      ],
    };
  },
  created() {
    this.getOrderDetails();
  },
  methods: {
    ...mapActions("orders", [
      "getOrder",
      "getUsers",
      "getReviewTypes",
      "getScopeOfReview",
      "updateReviewEndDate",
      "resetOrderState",
      "updateOrderStatus",
    ]),
    ...mapActions("organisations", [
      "getAuditYears",
      "updateSelectedAuditYear",
    ]),
    tagBoxClick: function (refKey) {
      this.hideSelectAllOptionFromTagbox(refKey);
      this.$refs[refKey].instance.open();
    },
    onSelectedAuditYearChanged(e) {
      this.updateSelectedAuditYear(e.value);
    },
    onSelectedReviewModeChanged(e) {
      if (this.reviewModes[e.value - 1].id === reviewModes.ComplianceReview)
        this.$emit("show-in-reviewer-capacity", true);
      else this.$emit("show-in-reviewer-capacity", false);
    },
    onSelectedReviewTypeChanged(e) {
      this.getScopeOfReview(e.value).then(() => {
        if (this.orderDetails && this.orderDetails.reviewType === e.value)
          this.selectedScopeOfReview = this.orderDetails.scopeOfReview;
        else this.selectedScopeOfReview = this.preselectedScopeOfReview;
      });
      //TODO: find a better way to update the date
      if (this.reviewEndDate !== null) {
        const date = new Date(this.reviewEndDate);
        date.setSeconds(date.getSeconds() + 1);
        this.updateReviewEndDate(date);
      }
    },
    onSelectedScopeOfReviewChanged(e) {
      if (
        this.orderDetails &&
        this.selectedReviewType === this.orderDetails.reviewType &&
        isIdenticalArray(
          [...this.selectedScopeOfReview],
          [...this.orderDetails.scopeOfReview],
        )
      ) {
        this.selectedLevelOfReviewMultiple = this.orderDetails.levelsOfReview;
      } else if (this.orderDetails) {
        this.selectedLevelOfReviewMultiple = [];
      } else {
        this.selectedLevelOfReviewMultiple = this.levelsOfReviewData
          .filter((x) => x.name === "Must" || x.name === "Basic")
          .map((y) => y.id);
      }
    },
    async loadSitesOrStores(loadOptions) {
      let params = "?";
      [
        "skip",
        "take",
        "sort",
        "requireTotalCount",
        "requireGroupCount",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      params = params.slice(0, -1);
      const response = await $axios.get(
        `/Organisations/GetOrganisationsByType/site?${params}`,
      );
      const json = await JSON.parse(JSON.stringify(response));
      return json.data.data;
    },
    onsiteOrStoreDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576
          ? this.componentContainer(this.siteOrStoreContainerRef).clientWidth
          : 700;
      this.dropdown(this.siteOrStoreDropDownRef).option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
    onlevelOfReviewDropDownOpened() {
      let dropDownWidth =
        this.componentContainer(this.levelOfReviewContainerRef).clientWidth -
        46;
      this.dropdown(this.levelOfReviewDropDownRef).option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
    componentContainer(containerRef) {
      return this.$refs[containerRef];
    },
    dropdown(dropdownRef) {
      return this.$refs[dropdownRef].instance;
    },
    async loadSiteCategory() {
      const response = await $axios.get(`/orders/sitecategories`);
      const json = await JSON.parse(JSON.stringify(response));
      let siteCategories = json.data.filter((x) => x.category !== "N/A");
      siteCategories.unshift(...json.data.filter((x) => x.category === "N/A"));
      return siteCategories;
    },
    onsiteCategoryDropDownOpened() {
      let screenWidth = window.innerWidth;
      let dropDownWidth =
        screenWidth < 576
          ? this.componentContainer(this.siteCategoryContainerRef).clientWidth
          : 700;
      this.dropdown(this.siteCategoryDropDownRef).option(
        "dropDownOptions.width",
        dropDownWidth,
      );
    },
    async getOrderDetails() {
      if (this.$route.name === "edit-order") {
        await Promise.all([
          await this.getOrder(this.$route.params.id),
          await this.getReviewTypes(),
          await this.getAuditYears(),
          await this.getScopeOfReview(this.orderDetails.reviewType),
        ]);

        this.selectedReviewType = this.orderDetails.reviewType;

        let response = await this.getUsers("Orderer");
        let users = response.data;

        users.sort(function (a, b) {
          return a.fullname.localeCompare(b.fullname);
        });

        const index = users.findIndex(
          (y) => y.id === this.orderDetails.ordererId,
        );
        this.selectedOrderer = users[index].id;

        this.orderers = users.filter((x) => {
          return this.selectedOrderer == x.id || x.activeState == 1;
        });

        this.selectedOrganisation = [this.orderDetails.organisationId];

        this.updateSelectedAuditYear(this.orderDetails.fiscalYear);

        this.selectedTertial = this.orderDetails.tertial;
        this.selectedReviewMode = this.orderDetails.reviewMode;

        this.selectedScopeOfReview = this.orderDetails.scopeOfReview;

        this.selectedLevelOfReviewMultiple = this.orderDetails.levelsOfReview;
        this.selectedSiteCategoryMultiple = this.orderDetails.siteCategories;
        this.notification = this.orderDetails.notification;

        if (this.orderDetails.notificationDate !== null) {
          this.delayNotification = true;
          this.notificationDelayDate = new Date(
            this.orderDetails.notificationDate,
          );
        }

        this.updateOrderStatus(this.orderDetails.statusCode);
      } else {
        let response = await this.getUsers("Orderer");
        let orderers = response.data;

        orderers.sort(function (a, b) {
          return a.fullname.localeCompare(b.fullname);
        });

        this.orderers = orderers.filter((x) => {
          return x.activeState == 1;
        });
        const index = orderers.findIndex(
          (x) => x.username === this.user.username,
        );

        if (orderers.length > 0 && index !== -1) {
          this.selectedOrderer = orderers[index].id;
        } else {
          this.selectedOrderer = null;
        }

        this.getAuditYears(true);
        this.getReviewTypes();
        this.getScopeOfReview(1);
      }
      this.isLoading = false;
    },
    async getReviewParticipantsAndBusinessOwners(organisationId) {
      this.reviewParticipantsAndBusinessOwnersData = [];
      const response = await $axios.get(
        `/Organisations/${organisationId}/users/${"ReviewParticipant,BusinessOwner"}`,
      );
      const json = await JSON.parse(JSON.stringify(response));

      this.reviewParticipantsAndBusinessOwnersData = json.data
        .filter((x) => x.activeState == 1)
        .map((x) => {
          x.role = this.formatRole(x.role);
          return x;
        });
    },
    async onSelectedOrganisationChanged(e) {
      await this.getReviewParticipantsAndBusinessOwners(e.value[0]);
    },
    formatRole(role) {
      return this.$t(`users.roles.${toCamelCase(role)}`);
    },
    hideSelectAllOptionFromTagbox(refKey) {
      let ele = null;
      switch (refKey) {
        case "scopeOfReviewRef":
          if (this.isScopeOfReviewDisabled) ele = "divScopeOfReview";
          break;
        case "levelsOfReviewRef":
          if (this.isLevelsOfReviewDisabled) ele = "divLevelOfReview";
          break;
      }
      if (ele) {
        var element = document
          .getElementById(ele)
          .offsetParent.childNodes[1].getElementsByClassName(
            "dx-list-select-all",
          )[0];
        if (element !== undefined) element.style.display = "none";
      }
    },
  },
  computed: {
    ...mapFields("orders", [
      "isLoading",
      "orderDetails",
      "selectedOrganisation",
      "selectedTertial",
      "selectedReviewMode",
      "selectedReviewType",
      "selectedLevels",
      "selectedOrderer",
      "selectedLevelOfReviewMultiple",
      "selectedSiteCategoryMultiple",
      "selectedScopeOfReview",
      "notification",
      "delayNotification",
      "notificationDelayDate",
      "orderStatus",
      "preselectedScopeOfReview",
      "initialComments",
    ]),
    ...mapState("orders", [
      "tertials",
      "reviewModes",
      "levels",
      "reviewTypes",
      "reviewEndDate",
      "reviewScope",
    ]),
    ...mapState("organisations", ["auditYears", "selectedAuditYear"]),
    ...mapState("auth", ["user"]),
    scopeOfReviewDropdownOptions() {
      return {
        disabled: this.isScopeOfReviewDisabled,
      };
    },
    levelsOfReviewDropdownOptions() {
      return {
        disabled: this.isLevelsOfReviewDisabled,
      };
    },
    isScopeOfReviewDisabled() {
      return (
        !this.$can("update", this.$subjects.order.subject, "scopeOfReview") ||
        (this.orderStatus && this.orderStatus !== "Ordered") ||
        this.isDisabled
      );
    },
    isLevelsOfReviewDisabled() {
      return (
        !this.$can("update", this.$subjects.order.subject, "levelOfReview") ||
        (this.orderStatus && this.orderStatus !== "Ordered") ||
        this.isDisabled
      );
    },
    levelsOfReviewData() {
      if (this.isScopeOfReviewDisabled) {
        return this.levels;
      }
      let scopeOfReviewLevels = [];
      if (
        this.selectedScopeOfReview &&
        this.selectedScopeOfReview.length > 0 &&
        this.reviewScope.length > 0
      ) {
        let selectedScopeOfReviews = this.reviewScope.filter((x) =>
          this.selectedScopeOfReview.includes(x.id),
        );
        scopeOfReviewLevels = scopeOfReviewLevels.concat.apply(
          scopeOfReviewLevels,
          selectedScopeOfReviews.map((y) => y.levels),
        );
        scopeOfReviewLevels = this.levels.filter((z) =>
          scopeOfReviewLevels.includes(z.name),
        );
      }
      return scopeOfReviewLevels;
    },
    siteUserscolDefs() {
      return this.reviewParticipantBusinessOwnersColumns.map((x) => {
        x.caption = this.$t(x.caption);
        return x;
      });
    },
  },
  beforeDestroy() {
    this.resetOrderState();
  },
  watch: {},
};
</script>

<style></style>
