import {getField, updateField} from "vuex-map-fields";

const getDefaultState = () => {
  return {
    locations: [],
    locationData: [],
    assessmentStructures: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
};
