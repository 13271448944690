import $axios from "../../utils/axios-instance.js";
import {getField, updateField} from "vuex-map-fields";

export default {
  namespaced: true,
  state: {
    selectedFinding: null,
    title: "",
    description: "",
    selectedRootCause: null,
    selectedBrands: [],
    rootCauses: [],
    brandTypes: [],
    findingStatus: "",
    findingsStatus: "",
    attachmentStatus: "",
    brandTypesStatus: "",
    rootCausesStatus: "",
    findingFilter: 1,
  },
  actions: {
    getSelectedFinding({commit}, findingId) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        $axios
          .get(`/assessments/findings/${findingId}`)
          .then((response) => {
            commit("setFindingStatus", "success");
            commit("setSelectedFinding", response.data);
            resolve(response.data);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
    updateSelectedFinding({commit}, finding) {
      commit("setSelectedFinding", finding);
    },
    getBrandTypes({commit}, assessmentId) {
      return new Promise((resolve, reject) => {
        commit("setBrandTypesStatus", "loading");
        $axios
          .get(`/assessments/${assessmentId}/brands`)
          .then((response) => {
            commit("setBrandTypesStatus", "success");
            commit("setBrandTypes", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setBrandTypesStatus", "error");
            reject(err);
          });
      });
    },
    getRootCauses({commit}) {
      return new Promise((resolve, reject) => {
        commit("setRootCausesStatus", "loading");
        $axios
          .get(`/assessments/findings/GetRootCauses`)
          .then((response) => {
            commit("setRootCausesStatus", "success");
            commit("setRootCauses", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("setRootCausesStatus", "error");
            reject(err);
          });
      });
    },
    addFinding({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        let formData = new FormData();
        formData.append("AssessmentId", payload.assessmentId);
        formData.append("QuestionId", payload.questionId);
        formData.append("Title", payload.finding.title);
        formData.append("Brands", JSON.stringify(payload.finding.brands));
        formData.append("RootCause", payload.finding.rootCause);
        formData.append("Description", payload.finding.description);
        formData.append("CapDate", payload.finding.capDate);
        formData.append(
          "DeadlineForApproval",
          payload.finding.deadlineForApproval,
        );
        formData.append(
          "ShowToReviewParticipant",
          payload.finding.showToReviewParticipant,
        );
        if (payload.attachments) {
          payload.attachments.forEach((file) =>
            formData.append("Files", file, file.name),
          );
        }
        $axios
          .post(
            `/assessments/${payload.assessmentId}/questions/${payload.questionId}/findings`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            commit("setFindingStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
    updateFinding({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("setFindingStatus", "loading");
        let formData = new FormData();
        formData.append("Title", payload.finding.title);
        formData.append("Brands", JSON.stringify(payload.finding.brands));
        formData.append("RootCause", payload.finding.rootCause);
        formData.append("Description", payload.finding.description);
        formData.append("AssessmentId", payload.assessmentId);
        formData.append("QuestionId", payload.questionId);
        formData.append("CapDate", payload.finding.capDate);
        formData.append(
          "DeadlineForApproval",
          payload.finding.deadlineForApproval,
        );
        formData.append(
          "ShowToReviewParticipant",
          payload.finding.showToReviewParticipant,
        );
        if (payload.attachments) {
          payload.attachments.forEach((file) =>
            formData.append("Files", file, file.name),
          );
        }
        $axios
          .post(`/assessments/findings/${payload.findingId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            commit("setFindingStatus", "success");
            resolve(response);
          })
          .catch((err) => {
            commit("setFindingStatus", "error");
            reject(err);
          });
      });
    },
    updateFindingFilter({commit}, filter) {
      commit("setFindingFilter", filter);
    },
  },
  mutations: {
    updateField,
    setQuestion(state, question) {
      state.question = question;
    },
    setSelectedFinding(state, finding) {
      state.selectedFinding = finding;
    },
    setAssessmentDetails(state, assessmentDetails) {
      state.assessmentDetails = assessmentDetails;
    },
    setMenuData(state, menuData) {
      state.menuData = menuData;
    },
    setAttachmentStatus(state, attachmentStatus) {
      state.attachmentStatus = attachmentStatus;
    },
    setAttachments(state, attachments) {
      state.attachments = attachments;
    },
    setFindingStatus(state, findingStatus) {
      state.findingStatus = findingStatus;
    },
    setFindingsStatus(state, findingsStatus) {
      state.findingsStatus = findingsStatus;
    },
    setBrandTypesStatus(state, brandTypesStatus) {
      state.brandTypesStatus = brandTypesStatus;
    },
    setBrandTypes(state, brandTypes) {
      state.brandTypes = brandTypes;
    },
    setRootCauses(state, rootCauses) {
      state.rootCauses = rootCauses;
    },
    setRootCausesStatus(state, rootCausesStatus) {
      state.rootCausesStatus = rootCausesStatus;
    },
    setSelectedBrands(state, selectedBrands) {
      state.selectedBrands = selectedBrands;
    },
    setFindingFilter(state, filterValue) {
      state.findingFilter = filterValue;
    },
  },
  getters: {
    getField,
    isLoading: (state) => {
      return (
        state.findingStatus === "loading" ||
        state.findingsStatus === "loading" ||
        state.attachmentStatus === "loading" ||
        state.brandTypesStatus === "loading" ||
        state.rootCausesStatus === "loading"
      );
    },
  },
};
