<template>
  <div>
    <div class="content-block" v-if="!isSelfContained">
      <h2 class="content-title">
        <i class="far fa-comment"></i>
        {{ $t("assessments.assessment.comments") }}
      </h2>
    </div>
    <div class="element-margin" v-if="!readOnly">
      <DxTextArea
        id="activeCommentTextArea"
        :height="!this.isSelfContained ? '350' : '150'"
        :value.sync="value"
        stylingMode="outlined"
        value-change-event="keyup"
      ></DxTextArea>
    </div>

    <dx-button
      v-if="!readOnly"
      :text="$t('assessments.assessment.addComment')"
      name="addComment"
      :type="isSelfContained ? 'normal' : 'default'"
      icon="plus"
      :stylingMode="isSelfContained ? 'outlined' : 'contained'"
      :class="isSelfContained ? 'btn btn-medium' : 'btn'"
      @click="onSubmitButtonClick($event)"
    />
  </div>
</template>

<script>
import {DxTextArea} from "devextreme-vue";
import DxButton from "devextreme-vue/button";
import {isNotEmpty} from "../../common/helperFunctions";

export default {
  components: {
    DxTextArea,
    DxButton,
  },
  props: {
    isSelfContained: {
      Type: Boolean,
      default: false,
      required: false,
    },
    readOnly: {
      Type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onSubmitButtonClick() {
      if (isNotEmpty(this.value)) {
        this.$emit("submit-comment-click", this.value);
        this.value = "";
      }
    },
  },
  computed: {},
  watch: {
    //TODO:
    // watch active comment
  },
};
</script>

<style></style>
